import { createApp } from 'vue'
import App from './App.vue'
import AOS from 'aos';
import {createRouter, createWebHistory} from 'vue-router'
import Home from "@/pages/Home";
import Product from "@/pages/Product";
import ProductDetail from "@/pages/ProductDetail";
import ProductDetail2 from "@/pages/ProductDetail2";
import ProductDetail3 from "@/pages/ProductDetail3";
import DIHApp from "@/pages/DIHApp";
import aboutDih from "@/pages/aboutDih";
import searchPill from "@/pages/searchPill";
import qna from "@/pages/qna";
import notice from "@/pages/notice";
import noticeDetail from "@/pages/noticeDetail";
import question from "@/pages/question";
import questionDetail from "@/pages/questionDetail";
import health from "@/pages/health";
import Innovation from "@/pages/Innovation";
import Exhibition from "@/pages/Exhibition";

const routes = [
    {path: '/', component: Home},
    {path: '/Product', component: Product},
    {path: '/Product/Detail', component: ProductDetail},
    { path: '/Product/Detail2', component: ProductDetail2 },
    { path: '/Product/Detail3', component: ProductDetail3 },
    {path: '/App', component: DIHApp},
    {path: '/about', component: aboutDih},
    {path: '/searchPill', component: searchPill},
    {path: '/qna', component: qna},
    {path: '/notice', component: notice},
    {path: '/notice/noticeDetail/:noticeNum', component: noticeDetail},
    { path: '/question', component: question },
    { path: '/question/questionDetail/:qnaNum', component: questionDetail },
    { path: '/health', component: health },
    { path: '/Innovation', component: Innovation },
    { path: '/Exhibition', component: Exhibition },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

createApp(App).use(router).mount('#app')

AOS.init();

