<template>
  <div class="productDetail">
    <div id="wrap">
      <div class="container" style="margin: auto;">
        <div id="contents" class="site-wrap">
          <div class="xans-element- xans-product xans-product-detail">
            <div class="detailArea ">
              <div class="xans-element- xans-product xans-product-image imgArea ">
                <div class="keyImg">
                  <div class="thumbnail">
                    <!-- 사진 클릭해서 보기<a href="/product/image_zoom2.html?product_no=1190&cate_no=140&display_group=1" alt="P0000BTU" onclick="window.open(this.href, 'image_zoom2', 'toolbar=no,scrollbars=auto,resizable=yes,width=450,height=693,left=0,top=0', this);return false;"></a> -->
                    <img src="@/assets/productImg/detail1.png" alt="스마트체온계 상세 사진" class="BigImage" width="400"
                      height="400" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                  </div>
                </div>
                <div class="xans-element- xans-product xans-product-addimage">
                  <!-- 추가 이미지 -->
                  <!-- <ul id="bxKeyImg" style="list-style: none; padding: 0; margin: 0;">
                    <li class="xans-record-" style="display: inline-block; margin-right: 10px;">
                      <img src="@/assets/productImg/detail-2.jpg" alt="스마트체온계 상세 사진" class="ThumbImage">
                    </li>
                    <li class="xans-record-" style="display: inline-block; margin-right: 10px;">
                      <img src="@/assets/productImg/detail-3.jpg" alt="스마트체온계 상세 사진" class="ThumbImage">
                    </li>
                    <li class="xans-record-" style="display: inline-block; margin-right: 10px;">
                      <img src="@/assets/productImg/detail-4.jpg" alt="스마트체온계 상세 사진" class="ThumbImage">
                    </li>
                    <li class="xans-record-" style="display: inline-block; margin-right: 10px;">
                      <img src="@/assets/productImg/detail-5.jpg" alt="스마트체온계 상세 사진" class="ThumbImage">
                    </li>
                    <li class="xans-record-" style="display: inline-block; margin-right: 10px;">
                      <img src="@/assets/productImg/detail-6.jpg" alt="스마트체온계 상세 사진" class="ThumbImage">
                    </li>
                  </ul> -->
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide"><img src="@/assets/productImg/detail2.png" class="img-fluid" alt=""></div>
                        <div class="swiper-slide"><img src="@/assets/productImg/detail3.png" class="img-fluid" alt=""></div>
                        <div class="swiper-slide"><img src="@/assets/productImg/detail4.png" class="img-fluid" alt=""></div>
                    </div>
                    <div class="swiper-pagination"></div>
                    </div>
                </div>
              </div>
              <div class="infoArea">
                <div class="headingArea">
                  <h2>扁桃炎カメラ</h2>
                </div>
                <div class="wp-prod-info">
                  <div class="price-wrap">
                    <div class="xans-element- xans-product xans-product-detaildesign grp_product_price">
                      <ul class=" product_price_css xans-record-">
                        <li class="item_cont"><span style="font-size:12px;color:#555555;"><span id="span_product_price_custom">5,000円</span></span></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="xans-element- xans-product xans-product-detaildesign grp_product_detail">
                  <table border="1" summary="">
                    <tbody>
                      <tr class=" delivery_price_css xans-record-">
                        <th scope="row">
                          <span>送料 </span>
                        </th>
                        <td>
                          <span><span class="delv_price_B">無料</span></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div id="optionG">
                  <div id="optionBox" class="normal">
                    <div class="option_layer">
                      <div class="block">
                        <div class="option-wrap">
                          <table border="1" summary=""
                            class="xans-element- xans-product xans-product-option xans-record-">
                            <tbody class="xans-element- xans-product xans-product-option xans-record-">
                              <tr>
                                <th scope="row">扁桃炎カメラ</th>
                                <td>
                                  <select option_product_no="1190" option_select_element="ec-option-select-finder"
                                    option_sort_no="1" option_type="T" item_listing_type="S" option_title="스마트 체온계 색상"
                                    product_type="product_option" product_option_area="product_option_1190_0"
                                    name="option1" id="product_option_id1" class="ProductOption0" option_style="select"
                                    required="true" v-model="selectedColor" @change="updatePriceAndQuantity">
                                    <option value="*" selected link_image="">- [必須]オプションを選択してください -</option>
                                    <option value="**" disabled link_image="">-------------------</option>
                                    <option v-for="color in colors" :key="color.value" :value="color.value">{{
                                      color.label }}</option>
                                  </select>
                                  <p class="value"></p>
                                </td>
                              </tr>
                            </tbody>
                            <tbody>
                              <tr class="displaynone" id="">
                                <td colspan="2" class="selectButton">
                                  <a href="#none" class="btnSubmit sizeS" onclick="">옵션선택</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div id="totalProducts" class="">
                            <table border="0" summary="" width="100%" v-if="selectedColor">
                              <thead>
                                <tr>
                                  <th scope="col" style="width:300px;">{{ productName }}</th>
                                  <input type="number" v-model="productQuantity" @change="updatePriceAndQuantity"
                                    min="1">
                                  <button @click="increaseQuantity">+</button>
                                  <button @click="decreaseQuantity">-</button>
                                  <!-- <th scope="col" style="width:170px;">{{ productQuantity }}</th> -->
                                  <th scope="col">{{ lib.getNumberFormatted(totalPrice) }}円</th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                        <div class="total-price-wrap">
                          <div id="totalPrice" class="totalPrice">
                            <strong>総商品金額</strong>
                            <span class="total"><strong>{{ lib.getNumberFormatted(totalPrice) }}円</strong> ({{ productQuantity }}개)</span>
                          </div>
                          <div class="xans-element- xans-product xans-product-action ">
                            <div class="ec-base-button gColumn">
                              <a href="#none" class="btnSubmit sizeL " onclick="">
                                <span id="btnBuy">すぐに購入</span>
                              </a>
                              <a href="#none" class="btnNormal sizeL "
                                onclick="product_submit(2, '/exec/front/order/basket/', this)">カート</a>
                              <!-- <span class="btnEm sizeL gFlex2" style="display: none;">상품이 품절되었습니다.</span> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="card"> -->
            <div class="card-body">

                <!-- Default Tabs -->
                <ul class="nav nav-tabs d-flex" id="myTabjustified" role="tablist">
                    <li class="nav-item flex-fill" role="presentation">
                        <button class="nav-link w-100 active" id="detail-tab" data-bs-toggle="tab" data-bs-target="#detail-justified" type="button" role="tab" aria-controls="detail" aria-selected="true">製品詳細</button>
                    </li>
                    <li class="nav-item flex-fill" role="presentation">
                        <button class="nav-link w-100" id="buy-tab" data-bs-toggle="tab" data-bs-target="#buy-justified" type="button" role="tab" aria-controls="buy" aria-selected="false">商品購入のご案内</button> 
                    </li>
                    <li class="nav-item flex-fill" role="presentation">
                        <button class="nav-link w-100" id="review-tab" data-bs-toggle="tab" data-bs-target="#review-justified" type="button" role="tab" aria-controls="review" aria-selected="false">レビュー.</button>
                    </li>
                    <li class="nav-item flex-fill" role="presentation">
                        <button class="nav-link w-100" id="question-tab" data-bs-toggle="tab" data-bs-target="#question-justified" type="button" role="tab" aria-controls="question" aria-selected="false">お問い合わせ</button>
                    </li>
                </ul>
                <div class="tab-content pt-2" id="myTabjustifiedContent">
                    <div class="tab-pane fade show active" id="detail-justified" role="tabpanel" aria-labelledby="detail-tab">
                        製品 詳細 写真及び内容
                    </div>
                    <div class="tab-pane fade" id="buy-justified" role="tabpanel" aria-labelledby="buy-tab">
                        商品配送のご案内及び交換/返品のご案内
                    </div>
                    <div class="tab-pane fade" id="review-justified" role="tabpanel" aria-labelledby="review-tab">
                        レビュー.
                    </div>
                    <div class="tab-pane fade" id="question-justified" role="tabpanel" aria-labelledby="question-tab">
                        お問い合わせ
                    </div>
                </div><!-- End Default Tabs -->

            </div>
                <!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import lib from '@/scripts/lib';
import "@/assets/css/productDetail.css";
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';



export default {
  name: 'ProductDetail',
  setup() {
    return { lib }
  },
  data() {
    return {
      selectedColor: null,
      productQuantity: 1,
      colors: [
        { label: 'ブラック', value: 'ブラック' },
        { label: 'グレー', value: 'グレー' },
        { label: 'ブルー', value: 'ブルー' },
        { label: 'ピンク', value: 'ピンク' },
        { label: 'グリーン', value: 'グリーン' }
      ],
      productData: {
        'ブラック': { quantity: 1, price: 5000 },
        'グレー': { quantity: 1, price: 5000 },
        'ブルー': { quantity: 1, price: 5000 },
        'ピンク': { quantity: 1, price: 5000 },
        'グリーン': { quantity: 1, price: 5000 }
      }
    }
  },
  computed: {
    productName() {
      return this.selectedColor ? `扁桃炎カメラ(${this.selectedColor})` : '商品名無し';
    },
    // productQuantity() {
    //   return this.selectedColor ? this.productData[this.selectedColor].quantity : 0;
    // },
    totalPrice() {
      return this.selectedColor ? this.productData[this.selectedColor].price * this.productQuantity : 0;
    }
  },
  methods: {
    updatePriceAndQuantity() {
      // 선택된 색상에 따른 수량과 가격을 업데이트합니다.
      this.productQuantity = parseInt(this.productQuantity);
    },
    increaseQuantity() {
      // 수량을 증가시킵니다.
      this.productQuantity++;
      this.updatePriceAndQuantity();
    },
    decreaseQuantity() {
      // 수량을 감소시킵니다. 최소값은 1로 설정합니다.
      if (this.productQuantity > 1) {
        this.productQuantity--;
        this.updatePriceAndQuantity();
      }
    }
  },
  mounted() {
    // Swiper 초기화
    new Swiper('.swiper-container', {
      // Swiper 옵션 설정
      slidesPerView: 4,

      spaceBetween: 0,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  },
};
</script>

<style scoped>
ul {
    list-style: none;
}

.img-fluid {
    max-width: 50%;
}

.swiper-slide {
    width: calc(100% - 20px);
    /* 슬라이드의 너비를 전체 너비에서 20px 뺀 값으로 설정 */
    margin: 0;
    /* 오른쪽 마진 설정 */
}

/* 마지막 슬라이드에는 마진을 적용하지 않음 */
.swiper-slide:last-child {
    margin-right: 0;
}

@font-face {
    font-family: 'NanumSquareNeo-Variable';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/NanumSquareNeo-Variable.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

h2,
span,
th {
    font-family: NanumSquareNeo-Variable;
}

#bxKeyImg>li {
    /* width: 0; */
}

.xans-product-detail {
    position: relative;
    margin: 0 auto;
    padding: 50px 0;
}

.xans-product-detail .detailArea {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 1100px) {
    .xans-product-detail .detailArea {
        flex-direction: column;
        /* 수직 방향으로 배치 */
    }

    .xans-product-detail .imgArea {
        width: 100% !important;
        /* 화면이 작아졌을 때 너비를 100%로 설정 */
    }

    .xans-product-detail .imgArea img {
        width: 100% !important;
    }

    .xans-product-detail .infoArea {
        width: 100% !important;
        padding: 10px;
    }
}


.xans-product-detail .detailArea:after {
    content: "";
    display: block;
    clear: both;
}

.xans-product-detail .imgArea {
    float: left;
    width: 650px;
}

.xans-product-detail .imgArea .keyImg {
    margin: 0 auto;
}

.xans-product-detail .imgArea .keyImg .thumbnail {
    display: inline-block;
    position: relative;
    width: 100%;
}

.xans-product-detail .imgArea .keyImg .thumbnail a img {
    width: 100%;
    border: 1px solid #eee;
}

.xans-product-detail .imgArea .keyImg .prdIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 50px;
}

.xans-product-detail .imgArea .keyImg img {
    cursor: pointer;
}

.xans-product-addimage {
    margin-top: 15px;
}

#bxKeyImg .ThumbImage {
    width: 150px;
    border: 1px solid #eee;
    box-sizing: border-box;
}

.xans-product-addimage .bx-wrapper {
    margin: 0 0 0
}

.xans-product-addimage .bx-has-controls-direction .bx-default-pager {
    display: none;
}

.xans-product-addimage .bx-has-controls-direction .bx-controls-direction .bx-prev,
.xans-product-addimage .bx-has-controls-direction .bx-controls-direction .bx-next {
    background-size: 15px;
    width: 30px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: -15px;
    background-color: #fff;
    opacity: 0;
    transition: opacity .25s ease;
    border: 1px solid #eee;
}

.xans-product-addimage:hover .bx-has-controls-direction .bx-controls-direction .bx-prev,
.xans-product-addimage:hover .bx-has-controls-direction .bx-controls-direction .bx-next {
    opacity: 1
}

.xans-product-addimage .bx-has-controls-direction .bx-controls-direction .bx-prev {
    /* background-image: url(/web/upload/wp/167417/bt_prev_grey.svg); */
    left: 0;
}

.xans-product-addimage .bx-has-controls-direction .bx-controls-direction .bx-next {
    /* background-image: url(/web/upload/wp/167417/bt_next_grey.svg); */
    right: 0
}

.xans-product-detail .imgArea .listImg {
    overflow: hidden;
    width: 346px;
    margin: 30px auto 0;
}

.xans-product-detail .imgArea .listImg ul {
    min-width: 400px;
    height: 77px;
    margin: 0 0 0 2px;
    font-size: 0;
    line-height: 0;
}

.xans-product-detail .imgArea .listImg li {
    display: inline-block;
    margin: 0 1px;
    font-size: 12px;
    line-height: 14px;
    vertical-align: top;
}

.xans-product-detail .imgArea .listImg li img {
    width: 65px;
    height: 75px;
    border: 1px solid #ececec;
    cursor: pointer;
}

.xans-product-detail .imgArea .listImg button {
    position: absolute;
    top: 0;
    width: 21px;
    height: 77px;
    font-size: 0;
    line-height: 0;
    color: transparent;
    /* background: url("//img.echosting.cafe24.com/skin/base/product/btn_product_detail_ctrl.png") no-repeat; */
}

.xans-product-detail .imgArea .listImg .prev {
    left: 0;
    background-position: 0 0;
}

.xans-product-detail .imgArea .listImg .next {
    right: 0;
    background-position: -31px 0;
}

.xans-product-detail .imgArea .control {
    overflow: hidden;
    width: 346px;
    margin: 30px auto 0;
    border: 1px solid #d7d5d5;
    text-align: center;
    background: #fbfafa;
}

.xans-product-detail .imgArea .control .btnZoom {
    display: block;
    padding: 11px 0;
    color: #787878;
    font-size: 12px;
    letter-spacing: -0.5px;
    text-decoration: none;
}

.xans-product-detail .imgArea .control .prev {
    float: left;
    border-right: 1px solid #d7d5d5;
}

.xans-product-detail .imgArea .control .next {
    float: right;
    border-left: 1px solid #d7d5d5;
}

.xans-product-detail .imgArea .color {
    overflow: hidden;
    width: 346px;
    margin: 5px auto;
}

.xans-product-detail .imgArea .chips {
    float: left;
    width: 28px;
    height: 18px;
    margin: 0 3px 3px 0;
    border: 1px solid #e3e3e3;
    font-size: 0;
    line-height: 0;
}

.xans-product-detail .imgArea .xans-product-action {
    width: 350px;
    margin: 20px auto 0;
    padding-bottom: 23px;
}

.xans-product-detail .likeButton {
    display: table;
    margin: 20px auto 10px;
    text-align: center;
    font-size: 14px;
}

.xans-product-detail .likeButton button {
    display: inline-block;
    padding: 11px 20px;
    border: 1px solid #b4b4b4;
    border-bottom-color: #969696;
    border-radius: 2px;
    color: #737373;
    line-height: 1em;
    text-align: right;
    background-color: #fff;
}

.xans-product-detail .likeButton button span {
    display: inline-block;
    height: 16px;
    line-height: 18px;
    vertical-align: middle;
    /* font-family: Gulim, sans-serif; */
}

.xans-product-detail .likeButton button img {
    margin: 0 2px 0 0;
    vertical-align: middle;
}

.xans-product-detail .likeButton .title {
    padding: 0 9px 0 0;
    margin: 0 8px 0 0;
    min-width: 0;
    text-align: left;
    /* background: url("//img.echosting.cafe24.com/skin/base_ko_KR/product/bg_bar.gif") no-repeat 100% 3px; */
}

.xans-product-detail .likeButton.selected .title {
    color: #ef514c;
}

.xans-product-detail .likeButton.selected .count {
    color: #353535;
}

.xans-product-detail .infoArea {
    float: right;
    width: 510px;
}

.xans-product-detail .headingArea {
    padding: 0 0 10px;
}

.xans-product-detail .headingArea h2 {
    font-size: 24px;
    color: #222;
    line-height: 1.2;
}

.xans-product-detail .headingArea .simple_desc_css,
.xans-product-detail .headingArea .summary_desc_css {
    font-size: 16px;
    padding: 5px 0;
    color: #999;
}

.xans-product-detail .wp-prod-info .price-wrap {
    position: relative;
    padding: 0 0 10px;
}

.grp_product_price ul {
    display: none;
}

.grp_product_price ul.product_custom_css,
.grp_product_price ul.product_price_css {
    display: inline-block;
}

.grp_product_price .item_tlt {
    display: none;
}

.grp_product_price ul.product_custom_css .item_cont {
    padding-right: 10px;
}

.grp_product_price ul.product_custom_css .item_cont * {
    color: #adadad;
    font-size: 18px;
    text-decoration: line-through;
    font-weight: normal;
}

.grp_product_price ul.product_price_css .item_cont * {
    font-size: 23px;
    font-weight: bold;
    color: #111;
}

.xans-product-detail .wp-prod-info .bt {
    position: absolute;
    top: 5px;
    right: 0;
}

.xans-product-detail .wp-prod-info .bt .wish {
    /* background: url(/web/upload/wp/167417/p__wish.svg) no-repeat; */
    background-size: 100%;
    margin-right: 10px;
    position: initial;
    display: inline-block;
    width: 26px;
    height: 26px;
    vertical-align: top;
    border: 0;
    padding: 0;
}

.xans-product-detail .wp-prod-info .bt .share {
    /* background: url(/web/upload/wp/167417/p__share.svg) no-repeat; */
    background-size: 100%;
    display: inline-block;
    width: 26px;
    height: 26px;
    vertical-align: top;
}

.xans-product-detail .wp-prod-info .bt a {
    font-size: 0;
    display: block;
    width: 100%;
    height: 100%;
}

.xans-product-detail .wp-prod-info .bt .social_lnk {
    display: none;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
}

.xans-product-detail .wp-prod-info .bt .sns_icon {
    width: 400px;
    display: block;
    text-align: left;
    margin: 40px auto 80px;
    background: #fff;
    min-height: 210px;
    top: 50%;
    left: 50%;
    position: absolute;
    margin-left: -200px;
    margin-top: -125px;
}

.xans-product-detail .wp-prod-info .bt .sns_icon ul {
    overflow: hidden;
    padding: 40px 40px 20px;
}

.xans-product-detail .wp-prod-info .bt .sns_icon li {
    float: left;
    padding: 0 0 12px;
}

.xans-product-detail .wp-prod-info .bt .sns_icon a {
    text-align: center;
    display: block;
    font-size: 12px;
    width: 80px;
    height: 17px;
    padding-top: 63px;
    /* background: url(/web/upload/wp/167417/p__social.png) no-repeat; */
    letter-spacing: -0.5px;
}

.xans-product-detail .wp-prod-info .bt .sns_icon .kakao a {
    background-size: 55px;
    background-position: 12px 0;
}

.xans-product-detail .wp-prod-info .bt .sns_icon .story a {
    background-size: 55px;
    background-position: 12px -318px;
}

.xans-product-detail .wp-prod-info .bt .sns_icon .line a {
    background-size: 55px;
    background-position: 12px -79px;
}

.xans-product-detail .wp-prod-info .bt .sns_icon .band a {
    background-size: 55px;
    background-position: 12px -398px;
}

.xans-product-detail .wp-prod-info .bt .sns_icon .naver a {
    background-size: 55px;
    background-position: 12px -637px;
}

.xans-product-detail .wp-prod-info .bt .sns_icon .facebook a {
    background-size: 55px;
    background-position: 12px -477px;
}

.xans-product-detail .wp-prod-info .bt .sns_icon .twitter a {
    background-size: 55px;
    background-position: 12px -238px;
}

.xans-product-detail .wp-prod-info .bt .url_copy {
    padding: 0 40px 20px;
}

.xans-product-detail .wp-prod-info .bt .url_copy input {
    width: calc(100% - 58px);
    height: 39px;
    line-height: 39px;
    padding: 0 0 0 10px;
    box-sizing: border-box;
}

.xans-product-detail .wp-prod-info .bt .url_copy_txt {
    font-size: 12px;
    color: #555;
    padding: 0 0 10px;
    text-align: center;
    display: none;
}

.xans-product-detail .wp-prod-info .bt .url_copy button {
    width: 48px;
    border: 1px solid #ddd;
    height: 39px;
    line-height: 39px;
    border-radius: 0 2px 2px 0;
    vertical-align: top;
    border-left: 0;
}

.xans-product-detail .wp-prod-info .bt .social_lnk .close {
    position: absolute;
    top: 50%;
    right: 50%;
    z-index: 1;
    width: 34px;
    height: 34px;
    margin-right: -188px;
    margin-top: -120px;
    cursor: pointer;
}

.xans-product-detail .wp-prod-info .bt .social_lnk .close>span {
    position: absolute;
    top: 50%;
    right: 0;
    width: 23px;
    height: 1px;
    background: rgba(0, 0, 0, 1);
}

.xans-product-detail .wp-prod-info .bt .social_lnk .close>.line1 {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
}

.xans-product-detail .wp-prod-info .bt .social_lnk .close>.line2 {
    transform: rotate(-315deg);
    -webkit-transform: rotate(-315deg);
    -moz-transform: rotate(-315deg);
    -ms-transform: rotate(-315deg);
    -o-transform: rotate(-315deg);
}

#dCouponDetail {
    overflow: hidden;
    position: relative;
    width: 398px;
    border: 1px solid #757575;
    background: #fff;
    box-shadow: 5px 5px 5px #d3d3d3;
}

#dCouponDetail h3 {
    height: 35px;
    padding: 0 35px 0 19px;
    color: #fff;
    font-size: 14px;
    line-height: 35px;
    background: #495164;
}

#dCouponDetail h3+a {
    position: absolute;
    right: 20px;
    top: 10px;
}

#dCouponDetail ul {
    padding: 16px 10px 17px 20px;
    margin: 25px 19px;
    background: #f5f5f5;
}

#dCouponDetail ul li {
    margin: 7px 0 0;
    color: #2e2e2e;
    line-height: 18px;
}

#dCouponDetail ul li:first-child {
    margin-top: 0;
}

#dCouponDetail ul+a {
    display: block;
    padding: 12px 0;
    text-align: center;
    border-top: 1px solid #d7d5d5;
    background: #fbfafa;
}

.xans-product-detail .infoArea p.delivery {
    padding: 10px 0 20px;
}

.xans-product-detail .infoArea .guideArea {
    overflow: hidden;
    margin: 5px 0 0;
    padding: 8px 11px 0;
    border-top: 1px solid #e8e8e8;
}

.xans-product-detail .infoArea .guideArea .info {
    float: left;
    color: #757575;
}

.xans-product-detail .infoArea .guideArea .sizeGuide {
    float: right;
    /* background: url("//img.echosting.cafe24.com/skin/base_ko_KR/product/ico_sizeGuide.gif") no-repeat 0 3px; */
}

.xans-product-detail .infoArea .sizeGuide a {
    display: inline-block;
    padding: 2px 15px 2px 24px;
    color: #353535;
    vertical-align: top;
    /* background: url("//img.echosting.cafe24.com/skin/base_ko_KR/product/ico_sizeGuide_go.gif") no-repeat 100% 6px; */
}

.xans-product-detail .infoArea .sizeGuide a:hover {
    text-decoration: none;
}

.xans-product-detail .infoArea table {
    border: 0;
    margin: 10px 0 0;
    color: #222;
}

.xans-product-detail .infoArea table.xans-product-option {
    border-top: 1px solid #eee;
}

.xans-product-detail .infoArea th {
    font-weight: normal;
    width: 135px;
    padding: 7px 0 8px 0;
    text-align: left;
    vertical-align: top
}

.xans-product-detail .infoArea td {
    padding: 7px 6px 8px 0;
    vertical-align: top;
}

.xans-product-detail .infoArea img {
    vertical-align: middle;
}

.xans-product-detail .infoArea td input[type=text] {
    width: 80%;
}

.xans-product-detail .infoArea select {
    width: 100%;
    height: 35px;
    font-size: 15px;
}

.xans-product-detail .infoArea td #delivery_cost_prepaid {
    margin: 0 0 5px;
}

.xans-product-detail .infoArea td .period {
    display: block;
    margin: 7px 0 0;
    font-size: 11px;
}

.xans-product-detail .infoArea td .mileage li {
    line-height: 1.7em;
}

.xans-product-detail .infoArea td .delivery label {
    margin: 0 30px 0 0;
}

.xans-product-detail .infoArea td img.qrcode {
    border: 1px solid #e1e1e1;
}

.xans-product-detail .infoArea td #span_product_coupon_dc_price {
    color: #070705;
}

.xans-product-detail .infoArea td.fileInfo {
    color: #757575;
}

.xans-product-detail .infoArea td.fileInfo input {
    width: 288px;
    border: 1px solid #c5c5c5;
    border-right: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
}

.xans-product-detail .infoArea td.fileInfo .infoDesc {
    margin: 0 0 10px 0;
}

.xans-product-detail .infoArea td.fileInfo li {
    margin: 4px 0 0;
    color: #757575;
    font-size: 11px;
}

.xans-product-detail .infoArea td.fileInfo li img {
    vertical-align: bottom;
}

.xans-product-detail .delv_price_B strong {
    font-weight: normal;
}

.xans-product-detail .btnTooltip {
    display: inline-block;
    position: relative;
    z-index: 10;
    vertical-align: middle;
}

.grp_product_detail.xans-product-detaildesign .product_name_css,
.grp_product_detail.xans-product-detaildesign .product_custom_css,
.grp_product_detail.xans-product-detaildesign .product_price_css,
.grp_product_detail.xans-product-detaildesign .summary_desc_css,
.grp_product_detail.xans-product-detaildesign .simple_desc_css,
.grp_product_detail.xans-product-detaildesign .quantity_css {
    display: none;
}

.xans-product-detail .infoArea .xans-product-option tr th {
    vertical-align: top;
}

.xans-product-detail .infoArea .xans-product-option tr td {
    padding: 0;
}

.xans-product-detail .infoArea .xans-product-option tr:first-child th,
.xans-product-detail .infoArea .xans-product-option .xans-product-fileoption th {
    padding-top: 14px;
    padding-left: 0;
}

.xans-product-detail .infoArea .xans-product-option tr:first-child td,
.xans-product-detail .infoArea .xans-product-option .xans-product-fileoption td {
    padding-top: 10px;
}

.xans-product-detail .infoArea .xans-product-option td.selectButton {
    text-align: right;
}

.xans-product-detail .infoArea .xans-product-option select::-ms-value {
    background: none;
    color: #000;
}

.xans-product-detail .infoArea .xans-product-option .selectbox {
    /* background: #fff url("/web/upload/wp/167417/p__option_select.svg") no-repeat 95% 50% / 12px; */
    overflow: hidden;
    background-position: -webkit-calc(100% - 10px) center;
    background-position: right 10px center;
    display: block;
    vertical-align: middle;
    border: 1px solid #ddd;
}

.xans-product-detail .infoArea .xans-product-option .selectbox select::-ms-expand {
    display: none;
}

.xans-product-detail .infoArea .xans-product-option .selectbox select {
    border: 0;
    width: 100%;
    width: 106% \0/IE8;
    border: 0 \0/IE8;
    box-shadow: none;
    background: transparent;
    background-image: none;
    visibility: visible;
    padding: 0px 30px 0 5px;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
}

.xans-product-detail .infoArea .xans-product-option .selectbox select::focus {
    outline: none;
}

.xans-product-detail .infoArea .xans-product-addoption .txtByte {
    font-size: 11px;
    vertical-align: middle;
    letter-spacing: -1px;
}

.xans-product-detail .infoArea .xans-product-addoption .txtByte strong {
    color: #000000;
}

.xans-product-detail .infoArea .xans-product-option .value {
    color: #767479;
    word-break: break-all;
}

.xans-product-detail .infoArea .xans-product-option .value .ec-product-value {
    color: #363437;
}

.xans-product-detail .infoArea .xans-product-option .value+.selectbox {
    border: 0
}

.xans-product-detail .infoArea .xans-product-option .ec-product-button li {
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin: 0 5px 3px 0;
    vertical-align: top;
    border: 1px solid #d7d7d7;
    background: #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.xans-product-detail .infoArea .xans-product-option .ec-product-button li:last-child {
    margin-right: 0;
}

.xans-product-detail .infoArea .xans-product-option .ec-product-button li a {
    display: block;
    min-width: 28px;
    text-align: center;
    text-decoration: none;
    word-break: break-all;
}

.xans-product-detail .infoArea .xans-product-option .ec-product-button li a span {
    display: inline-block;
    padding: 6px 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.xans-product-detail .infoArea .xans-product-option .ec-product-button.ec-product-preview li {}

.xans-product-detail .infoArea .xans-product-option .ec-product-button.ec-product-preview li a {
    min-width: 22px;
    width: 22px;
    height: 22px;
}

.xans-product-detail .infoArea .xans-product-option .ec-product-button.ec-product-preview li a img {
    max-width: 100%;
    max-height: 100%;
    width: 50px;
    height: 50px;
}

.xans-product-detail .infoArea .xans-product-option .ec-product-button.ec-product-preview li a span {
    overflow: hidden;
    line-height: 0;
    font-size: 0;
    padding: 0;
    text-indent: 120%;
    white-space: nowrap;
}

.xans-product-detail .infoArea .xans-product-option .ec-product-button.ec-product-preview .ec-product-soldout span,
.xans-product-detail .infoArea .xans-product-option .ec-product-button.ec-product-preview .ec-product-disabled span {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.xans-product-detail .infoArea .xans-product-option .ec-product-button.ec-product-preview .ec-product-soldout span {
    /* background: url("//img.echosting.cafe24.com/skin/base/product/soldout_bg.png") repeat; */
}

.xans-product-detail .infoArea .xans-product-option .ec-product-button .ec-product-soldout {
    border-style: dashed;
}

.xans-product-detail .infoArea .xans-product-option .ec-product-button .ec-product-soldout a span {
    display: inline-block;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    color: #d7d7d7;
}

.xans-product-detail .infoArea .xans-product-option .ec-product-button .ec-product-soldout.ec-product-selected {
    border-style: dashed;
}

.xans-product-detail .infoArea .xans-product-option .ec-product-button .ec-product-disabled a {
    cursor: default;
}

.xans-product-detail .infoArea .xans-product-option .ec-product-button .ec-product-disabled a span {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: #000;
    background: #555;
    filter: alpha(opacity=30);
    opacity: 0.3;
}

.xans-product-detail .infoArea .xans-product-option .ec-product-button .ec-product-selected {
    border: 2px solid #495164;
}

.xans-product-detail .infoArea .xans-product-option .ec-product-button .ec-product-selected a {
    margin: -1px;
}

.xans-product-detail .infoArea .xans-product-option .ec-product-radio>li {
    display: inline-block;
    margin: 0 0 3px;
}

.xans-product-detail .infoArea .xans-product-option .ec-product-radio label {
    margin: 0 7px 0 0;
}

.xans-product-detail .infoArea .xans-product-option .ec-product-radio label input {
    margin: 0 2px 0 0;
}

.xans-product-detail .infoArea .xans-product-option .ec-product-radio .ec-product-disabled,
.xans-product-detail .infoArea .xans-product-option .ec-product-radio .ec-product-soldout {
    color: #d7d7d7;
}

.xans-product-detail .infoArea .xans-product-action {
    padding: 30px 0 10px;
}

.xans-product-detail .infoArea .xans-product-action .btnSubmit,
.xans-product-detail .infoArea .xans-product-action .btnNormal,
.xans-product-detail .infoArea .xans-product-action .btnEm {
    border-radius: 0;
    height: 65px;
    line-height: 65px;
    font-size: 20px;
    font-weight: normal;
}

.xans-product-detail .infoArea .xans-product-action .btnNormal,
.xans-product-detail .infoArea .xans-product-action .btnNormal:hover {}

.xans-product-detail .infoArea .xans-product-action .btnEm,
.xans-product-detail .infoArea .xans-product-action .btnEm:hover {
    background: #bcbcbc;
}

.xans-product-detail .eventArea {
    color: #353535;
    margin-top: 50px;
}

.xans-product-detail .eventArea>.title {
    margin: 0 0 30px;
    text-align: center;
}

.xans-product-detail .eventArea>.title h2 {
    font-size: 20px;
    color: #222;
}

.xans-product-detail .eventArea .event {
    color: #353535;
    line-height: 18px;
}

.xans-product-detail .eventArea .event img {
    max-width: 100%;
}

.xans-myshop-asyncbenefit {
    margin: 30px 0;
}

.xans-myshop-asyncbenefit .member {
    margin: 2px 0 19px;
}

.xans-myshop-asyncbenefit .member em {
    font-style: normal;
}

#image_zoom_small {
    display: block;
    position: absolute;
    z-index: 999;
    background: #fff;
    outline: 2px solid #aaa;
    filter: alpha(opacity=50);
    opacity: 0.5;
}

#zoom_wrap {
    position: absolute;
    top: 0;
    left: 103%;
    z-index: 100;
    display: block;
}

.image_zoom_large {
    position: absolute;
    z-index: 99;
    width: 500px;
    height: 500px;
    overflow: hidden;
    background: #fff;
    outline: 1px solid #ececec;
}

.image_zoom_large .image_zoom_large_relative {
    position: relative;
    display: block;
}

.image_zoom_large .image_zoom_large_relative img#zoom_image {
    position: absolute;
    z-index: 99;
    max-width: none;
}

#NaverChk_Button {
    margin: 0 0 10px;
}

#divNvPointInfo {
    display: none;
    position: absolute;
    z-index: 99;
    width: 245px;
    padding: 10px;
    color: #777;
    background: #fff;
    border: 1px solid #5B9761;
}

#divNvPointInfo a {
    display: block;
    padding: 5px 0 0;
    color: #1ec228;
}

#divNvPointInfo a img {
    vertical-align: middle;
}

#imgNaverMileageHelp {
    cursor: pointer;
}

.xans-product-detail .productSet {
    margin: 10px 0 0;
    border: 1px solid #d7d5d5;
}

.xans-product-detail .productSet>.title {
    position: relative;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    background-color: #fbfafa;
}

.xans-product-detail .productSet>.title h3 {
    margin: 0 0 0 9px;
    font-size: 13px;
    color: #353535;
}

.xans-product-detail .productSet .product>li {
    position: relative;
    border-bottom: 1px solid #d7d5d5;
}

.xans-product-detail .productSet .product>li:last-child {
    border-bottom: 0;
}

.xans-product-detail .productSet .thumbnail {
    position: absolute;
    top: 19px;
    left: 19px;
    z-index: 1;
    width: 100px;
}

.xans-product-detail .productSet .thumbnail img {
    max-width: 100%;
    border: 1px solid #ededed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.xans-product-detail .productSet .information {
    position: relative;
    padding: 19px 19px 0 135px;
}

.xans-product-detail .productSet .information>p {
    margin: 0 0 10px;
}

.xans-product-detail .productSet .information .name {
    padding-right: 60px;
}

.xans-product-detail .productSet .information .price .del {
    font-weight: normal;
}

.xans-product-detail .productSet .information .salePrice {
    color: #ff8a56;
    font-weight: bold;
}

.xans-product-detail .productSet .information .btnInfo {
    position: absolute;
    top: 19px;
    right: 19px;
    z-index: 1;
    display: block;
    width: 57px;
    height: 20px;
    vertical-align: middle;
    /* background: url("//img.echosting.cafe24.com/skin/base_ko_KR/product/btn_info.gif") no-repeat 0 0; */
}

.xans-product-detail .productSet .information .btnInfo.selected {
    /* background: url("//img.echosting.cafe24.com/skin/base_ko_KR/product/btn_info_on.gif") no-repeat 0 0; */
}

.xans-product-detail .productSet .information .btnInfo span {
    overflow: hidden;
    font-size: 0;
    width: 0;
    height: 0;
    line-height: 0;
    text-indent: 120%;
}

.xans-product-detail .productSet .option {
    padding: 10px 19px 9px 135px;
    font-size: 13px;
}

.xans-product-detail .productSet .option select {
    font-size: 13px;
}

.xans-product-detail .productSet .option>li {
    margin: 0 0 12px;
}

.xans-product-detail .productSet .option .name {
    display: block;
    margin: 0 0 5px;
    font-weight: normal;
}

.xans-product-detail .productSet .option input[type=text] {
    width: 80%;
}

.xans-product-detail .productSet .option .ec-base-qty {
    margin: 7px 0 0;
}

.xans-product-detail .productSet .option .selectButton {
    margin: 0 0 6px;
    text-align: right;
}

.xans-product-detail .productSet .guide {
    overflow: hidden;
    position: relative;
    margin: 0 19px;
}

.xans-product-detail .productSet .guide:after {
    content: "";
    display: block;
    clear: both;
}

.xans-product-detail .productSet .guide .qty {
    float: left;
    display: inline-block;
    padding: 12px 0;
    color: #757575;
}

.xans-product-detail .productSet .guide .qty:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    border-top: 1px solid #e8e8e8;
}

.xans-product-detail .productSet .guide .sizeGuide {
    float: right;
    height: 15px;
    margin: 12px 0;
    /* background: url("//img.echosting.cafe24.com/skin/base/product/ico_sizeGuide.gif") no-repeat 0 1px; */
}

.xans-product-detail .productSet .guide .sizeGuide:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    border-top: 1px solid #e8e8e8;
}

.xans-product-detail .productSet .guide .sizeGuide a {
    display: inline-block;
    padding: 0 15px 0 24px;
    /* background: url("//img.echosting.cafe24.com/skin/base/product/ico_sizeGuide_go.gif") no-repeat 100% center; */
}

.xans-product-detail .productSet.additional .title span {
    color: #757575;
    font-weight: normal;
}

.xans-product-detail .productSet.additional .title .toggle {
    position: absolute;
    top: 8px;
    right: 8px;
}

.xans-product-detail .productSet.hide .product {
    display: none;
}

.xans-product-detail .productSet .ec-base-desc {
    padding: 0 0 10px;
    border-top: 0;
    border-bottom: 1px solid #ddd;
}

.xans-product-detail .productSet .ec-base-desc dt {
    width: 135px;
    padding: 0 0 0 20px;
}

.ec-base-desc.quantity {
    margin: 10px 0 0;
    padding: 10px 0 0;
    border-top: 1px solid #ddd;
}

.ec-base-desc.quantity dt {
    width: 125px;
    padding: 0 0 0 8px;
}

#optionBox.normal {
    border: 0px solid blue
}

#optionBox.normal .option_layer .option_btn {
    font-size: 0;
}

#optionBox.normal #totalProducts {
    margin: 20px 0 0;
}

#optionBox.normal #totalProducts table {
    margin: 0;
}

#optionBox.normal #totalProducts table .left {
    text-align: left;
}

#optionBox.normal #totalProducts table .center {
    text-align: center;
    background: red
}

#optionBox.normal #totalProducts table .right {
    padding: 0;
    text-align: right;
    width: 100px;
}

#optionBox.normal #totalProducts thead th {
    height: 0;
    /* padding: 0; */
    /* font-size: 0; */
    /* line-height: 0; */
    color: #999999;
}

#optionBox.normal #totalProducts tbody th {
    vertical-align: middle;
}

#optionBox.normal #totalProducts tbody td {
    padding: 9px 0;
    border-top: 1px solid #dedede;
    border-bottom: 0px solid #dedede;
    color: #353535;
    line-height: 18px;
    vertical-align: middle;
    word-wrap: break-word;
    word-break: break-all;
}

#optionBox.normal #totalProducts tbody td .xans-product-addoption>td {
    border: 0 !important;
}

#optionBox.normal #totalProducts tbody td p {
    padding: 15px 10px 15px 0;
    color: #222;
    font-size: 0;
    line-height: 0;
}

#optionBox.normal #totalProducts .option_product td p.product {
    padding-top: 0;
}

#optionBox.normal #totalProducts tbody td p.product:nth-child(2) {
    padding-top: 15px
}

#optionBox.normal #totalProducts tbody td p.product span {
    font-weight: normal;
    color: #757575;
    line-height: 18px;
    font-size: 14px;
}

#optionBox.normal #totalProducts tbody td p.product span.soldOut {
    color: #000000;
}

#optionBox.normal #totalProducts tbody td img {
    vertical-align: middle;
}

#optionBox.normal #totalProducts tbody td .quantity {
    float: right;
    display: inline-block;
    position: relative;
    width: 65px;
    vertical-align: top;
}

#optionBox.normal #totalProducts tbody td .quantity input {
    width: 40px;
    height: 28px;
    line-height: 28px;
    font-size: 15px;
    border: 1px solid #ddd;
    border-radius: 0;
    text-align: center;
    padding: 0
}

#optionBox.normal #totalProducts tbody td .quantity .up,
#optionBox.normal #totalProducts tbody td .quantity .down {
    width: 30px;
    height: 30px;
}

#optionBox.normal #totalProducts tbody td .quantity .up {
    position: absolute;
    left: 37px;
    top: 0;
}

#optionBox.normal #totalProducts tbody td .quantity .down {
    position: absolute;
    left: -25px;
    top: 0;
}

#optionBox.normal #totalProducts tbody.option_products tr td .quantity input,
#optionBox.normal #totalProducts tbody.add_products tr td .quantity input {
    width: 40px;
    height: 28px;
    line-height: 28px;
    font-size: 13px;
    border: 1px solid #ddd;
    border-radius: 0;
    text-align: center;
    padding: 0
}

#optionBox.normal #totalProducts tbody td .quantity .up {
    /* background: url(/web/upload/wp/167417/p__count_up.jpg) no-repeat 0 0; */
}

#optionBox.normal #totalProducts tbody td .quantity .down {
    /* background: url(/web/upload/wp/167417/p__count_down.jpg) no-repeat 0 0; */
}

#optionBox.normal #totalProducts tbody td .quantity .up img,
#optionBox.normal #totalProducts tbody td .quantity .down img {
    opacity: 0;
}

#optionBox.normal #totalProducts tbody td .quantity+a.delete {
    float: left;
    /* background: url(/web/upload/wp/167417/p__price_delete.png) no-repeat center; */
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

#optionBox.normal #totalProducts tbody td .quantity+a.delete img {
    opacity: 0;
}

#optionBox.normal #totalProducts tbody td .mileage {
    display: block;
    white-space: nowrap;
    font-size: 12px;
}

#optionBox.normal #totalProducts tbody tr.option td {
    border-top: 1px solid #dedede;
}

#optionBox.normal #totalProducts tbody tr.option th {
    border-top: 0;
}

#optionBox.normal #totalProducts tbody tr.option table {
    color: #777;
    font-size: 13px;
    background: #f9f9f9;
}

#optionBox.normal #totalProducts tbody tr.option table th,
#optionBox.normal #totalProducts tbody tr.option table td {
    padding: 5px 10px;
}

#optionBox.normal #totalProducts tbody tr.option table tr.xans-product-addoption {}

#optionBox.normal #totalProducts tbody tr.option table tr.xans-product-addoption th {}

#optionBox.normal #totalProducts tbody tr.option table tr.xans-product-addoption td {
    padding: 5px 0;
}

#optionBox.normal #totalProducts>table>tbody>tr>td:first-child,
#optionBox.normal #totalProducts>table>tbody>tr>td>table>tr>td:first-child {
    padding-left: 0px;
    padding-right: 0px;
    line-height: 1.3;
    font-size: 14px;
    color: #222;
}

#optionBox.normal #totalProducts>table>tbody>tr>td>table>tr>td:first-child {}

#optionBox.normal #totalProducts table tr.option_product>td,
#optionBox.normal #totalProducts table tr.option_product>td table td {
    padding: 0px;
    border-bottom: 0;
}

#optionBox.normal #totalProducts table tr.option_product>td>table tr:first-child td {
    border-top: 0;
}

#optionBox.normal #totalProducts table tr.option_product>td>table tr.option>td {
    border-top: 1px solid #ebebeb;
    padding: 9px 0;
}

#optionBox.normal #totalProducts table tr.option_product>td>table tr.option>td:first-child {
    padding: 0
}

#optionBox.normal #totalProducts table tr.option_product+tr.option_product>td {
    border-top: 1px solid #dedede;
}

#optionBox.normal #totalProducts>table>tbody>tr#totalProductsOption>td:first-child {
    border-top: 0;
    padding: 0;
    border-bottom: 0;
}

#optionBox.normal #totalProducts>table>tbody>tr#totalProductsOption>td>table>tbody>tr th,
#optionBox.normal #totalProducts>table>tbody>tr#totalProductsOption>td>table>tbody>tr td {
    border-top: 1px solid #ddd !important;
}

#optionBox.normal #totalProducts table tr.add_product>td {
    padding: 0;
}

#optionBox.normal #totalProducts table tr.add_product>td>table tr.option>td:first-child {
    padding: 0;
}

#optionBox.normal #totalProducts table tr.add_product>td>table tr:first-child td {
    border-top: 0;
}

#optionBox.normal #totalProducts table tr.option_product>td>table tr.option>td .xans-product-fileoption .fileInfo {
    padding: 5px 10px;
}

#optionBox.normal .totalPrice {
    padding: 20px 0 0;
    border-top: 1px solid #dedede;
    margin-top: -1px;
    font-size: 0
}

#optionBox.normal .totalPrice>strong {
    display: inline-block;
    width: 50%;
    text-align: left;
    color: #222;
    font-size: 20px;
    vertical-align: middle;
}

#optionBox.normal .totalPrice .total {
    display: inline-block;
    width: 50%;
    text-align: right;
    vertical-align: middle;
    word-break: keep-all;
    font-size: 15px;
}

#optionBox.normal .totalPrice .total strong {
    font-size: 20px;
}

#optionBox.normal .totalPrice .total em {
    font-style: normal;
}

#prdDetail .wp-opt {
    background: #fff;
    width: 290px;
    border-left: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    box-sizing: border-box;
    padding: 20px 10px 20px;
    color: #222;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 2px;
    margin: 0;
}

#optionBox.active {
    border: 0px solid red;
}

#optionBox.active .option_layer .option_btn {
    font-size: 0;
}

#prdDetail.fixed #optionBox {
    position: fixed;
    top: 100px;
    width: inherit;
    z-index: 10;
    width: 270px;
    background: #fff;
}

#prdDetail .wp-opt.fixed #optionBox {
    position: absolute;
    bottom: 0;
    top: auto;
}

#optionBox.active .option-wrap {
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 350px;
    max-height: 500px;
}

#optionBox.active .xans-product-option th,
#optionBox.active .xans-product-option td {
    display: block;
    text-align: left;
    font-size: 12px;
    padding: 0 0 5px;
}

#optionBox.active .xans-product-option select {
    width: 100%;
}

#optionBox.active .xans-product-option .value {
    color: #767479;
    word-break: break-all;
}

#optionBox.active .xans-product-option .value+.selectbox {
    border: 0
}

#optionBox.active .xans-product-option .value .ec-product-value {
    color: #363437;
}

#optionBox.active .xans-product-option .ec-product-button li {
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin: 0 5px 3px 0;
    vertical-align: top;
    border: 1px solid #d7d7d7;
    background: #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

}

#optionBox.active .xans-product-option .ec-product-button li:last-child {
    margin-right: 0;
}

#optionBox.active .xans-product-option .ec-product-button li a {
    display: block;
    min-width: 28px;
    text-align: center;
    text-decoration: none;
    word-break: break-all;
}

#optionBox.active .xans-product-option .ec-product-button li a span {
    display: inline-block;
    padding: 6px 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#optionBox.active .xans-product-option .ec-product-button.ec-product-preview li a {
    min-width: 22px;
    width: 22px;
    height: 22px;
}

#optionBox.active .xans-product-option .ec-product-button.ec-product-preview li a img {
    max-width: 100%;
    max-height: 100%;
}

#optionBox.active .xans-product-option .ec-product-button.ec-product-preview li a span {
    overflow: hidden;
    line-height: 0;
    font-size: 0;
    padding: 0;
    text-indent: 120%;
    white-space: nowrap;
}

#optionBox.active .xans-product-option .ec-product-button.ec-product-preview .ec-product-soldout span,
#optionBox.active .xans-product-option .ec-product-button.ec-product-preview .ec-product-disabled span {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

#optionBox.active .xans-product-option .ec-product-button.ec-product-preview .ec-product-soldout span {
    /* background: url("//img.echosting.cafe24.com/skin/base/product/soldout_bg.png") repeat; */
}

#optionBox.active .xans-product-option .ec-product-button .ec-product-soldout {
    border-style: dashed;
}

#optionBox.active .xans-product-option .ec-product-button .ec-product-soldout a span {
    display: inline-block;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    color: #d7d7d7;
}

#optionBox.active .xans-product-option .ec-product-button .ec-product-soldout.ec-product-selected {
    border-style: dashed;
}

#optionBox.active .xans-product-option .ec-product-button .ec-product-disabled a {
    cursor: default;
}

#optionBox.active .xans-product-option .ec-product-button .ec-product-disabled a span {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: #000;
    background: #555;
    filter: alpha(opacity=30);
    opacity: 0.3;
}

#optionBox.active .xans-product-option .ec-product-button .ec-product-selected {
    border: 2px solid #495164;
}

#optionBox.active .xans-product-option .ec-product-button .ec-product-selected a {
    margin: -1px;
}

#optionBox.active .xans-product-option .ec-product-radio>li {
    display: inline-block;
    margin: 0 0 3px;
}

#optionBox.active .xans-product-option .ec-product-radio label {
    margin: 0 7px 0 0;
}

#optionBox.active .xans-product-option .ec-product-radio label input {
    margin: 0 2px 0 0;
}

#optionBox.active .xans-product-option .ec-product-radio .ec-product-disabled,
#optionBox.active .xans-product-option .ec-product-radio .ec-product-soldout {
    color: #d7d7d7;
}

#optionBox.active #totalProducts {
    margin: 0;
}

#optionBox.active #totalProducts table {
    margin: 0;
}

#optionBox.active #totalProducts table .left {
    text-align: left;
}

#optionBox.active #totalProducts table .center {
    text-align: center;
    background: red
}

#optionBox.active #totalProducts table .right {
    padding: 0;
    text-align: right;
    width: 100px;
}

#optionBox.active #totalProducts thead th {
    height: 0;
    padding: 0;
    font-size: 0;
    line-height: 0;
}

#optionBox.active #totalProducts tbody th {
    vertical-align: middle;
}

#optionBox.active #totalProducts>table>tbody>tr {
    display: block;
    margin: 0 0 6px 0;
    background: #f7f7f7;
    position: relative;
}

#optionBox.active #totalProducts>table>tbody>tr>td {
    border: 0;
    display: block;
    width: 270px;
    padding: 0;
    box-sizing: border-box;
}

#optionBox.active #totalProducts>table>tbody>tr>td:first-child {
    padding: 9px 8px 0;
}

#optionBox.active #totalProducts>table>tbody>tr>td:nth-child(2),
#optionBox.active #totalProducts>table>tbody>tr>td:nth-child(3) {
    width: 49%;
    padding: 10px 0 10px 10px;
    display: inline-block;
    vertical-align: middle;
}

#optionBox.active #totalProducts>table>tbody>tr>td.right {
    padding: 0px 10px 0px 0;
    display: inline-block;
    width: 49%;
    vertical-align: middle;
    text-align: right;
    font-weight: bold;
    font-size: 13px;
    line-height: 1.1;
}

#optionBox.active #totalProducts>table>tbody>tr.xans-product-option>td {
    padding: 0;
}

#optionBox.active #totalProducts tbody td {
    padding: 9px 0;
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
    color: #353535;
    line-height: 18px;
    vertical-align: middle;
    word-wrap: break-word;
    word-break: break-all;
}

#optionBox.active #totalProducts tbody td .quantity {
    margin-left: 30px;
    float: none;
    display: inline-block;
    position: relative;
    width: 65px;
    vertical-align: top;
}

#optionBox.active #totalProducts tbody td .quantity input {
    width: 40px;
    height: 28px;
    line-height: 28px;
    font-size: 15px;
    border: 1px solid #ddd;
    border-radius: 0;
    text-align: center;
    padding: 0
}

#optionBox.active #totalProducts tbody td .quantity .up,
#optionBox.active #totalProducts tbody td .quantity .down {
    width: 30px;
    height: 30px;
}

#optionBox.active #totalProducts tbody td .quantity .up {
    position: absolute;
    left: 37px;
    top: 0;
}

#optionBox.active #totalProducts tbody td .quantity .down {
    position: absolute;
    left: -25px;
    top: 0;
}

#optionBox.active #totalProducts tbody.option_products tr td .quantity input,
#optionBox.active #totalProducts tbody.add_products tr td .quantity input {
    width: 40px;
    height: 28px;
    line-height: 28px;
    font-size: 13px;
    border: 1px solid #ddd;
    border-radius: 0;
    text-align: center;
    padding: 0
}

#optionBox.active #totalProducts tbody td .quantity .up {
    /* background: url(/web/upload/wp/167417/p__count_up.jpg) no-repeat 0 0; */
}

#optionBox.active #totalProducts tbody td .quantity .down {
    /* background: url(/web/upload/wp/167417/p__count_down.jpg) no-repeat 0 0; */
}

#optionBox.active #totalProducts tbody td .quantity .up img,
#optionBox.active #totalProducts tbody td .quantity .down img {
    opacity: 0;
}

#optionBox.active #totalProducts tbody td .quantity+a.delete {
    float: left;
    /* background: url(/web/upload/wp/167417/p__price_delete.png) no-repeat center; */
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    position: absolute;
    top: 5px;
    right: 5px;
}

#optionBox.active #totalProducts tbody td .quantity+a.delete img {
    opacity: 0;
}

#optionBox.active #totalProducts tbody td .xans-product-addoption>td {
    border: 0 !important;
}

#optionBox.active #totalProducts tbody td p {
    padding: 0 25px 0 0;
    font-size: 0;
    line-height: 0;
}

#optionBox.active #totalProducts .option_product td p.product {
    padding-top: 0;
}

#optionBox.active #totalProducts tbody td p.product span {
    font-weight: normal;
    color: #757575;
    line-height: 18px;
    font-size: 14px;
}

#optionBox.active #totalProducts tbody td p.product span.soldOut {
    color: #000000;
}

#optionBox.active #totalProducts tbody td img {
    vertical-align: middle;
}

#optionBox.active #totalProducts tbody td .mileage {
    display: block;
    white-space: nowrap;
    font-size: 12px;
    font-weight: normal;
}

#optionBox.active #totalProducts tbody tr.option td {
    border-top: 1px solid #dedede;
}

#optionBox.active #totalProducts tbody tr.option th {
    border-top: 0;
}

#optionBox.active #totalProducts>table>tbody.add_products>tr.add_product>td>table td {
    display: block;
    border: 0;
    width: 245px;
    color: #555;
}

#optionBox.active #totalProducts>table>tbody.add_products>tr.add_product>td>table td:first-child {
    padding-bottom: 0;
}

#optionBox.active #totalProducts>table>tbody.add_products>tr.add_product>td>table td:nth-child(2),
#optionBox.active #totalProducts>table>tbody.add_products>tr.add_product>td>table td:nth-child(3) {
    width: 49%;
    display: inline-block;
}

#optionBox.active #totalProducts>table>tbody.add_products>tr.add_product>td>table td.right {
    width: 49%;
    display: inline-block;
    font-weight: bold;
    font-size: 13px;
}

#optionBox.active #totalProducts>table>tbody.add_products>tr.add_product>td>table tr.option>td {
    border-top: 1px solid #eee;
    padding-top: 10px;
}

#optionBox.active #totalProducts>table>tbody.add_products>tr.add_product>td>table tr.option>td>table th,
#optionBox.active #totalProducts>table>tbody.add_products>tr.add_product>td>table tr.option>td>table td {
    display: block;
    text-align: left;
    font-weight: normal;
    font-size: 12px;
    width: 100%;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td>table>tbody>tr {
    position: relative;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td>table>tbody>tr>td {
    padding: 10px 0;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td>table>tbody>tr>td:first-child {
    padding: 0px;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td>table>tbody>tr>td .quantity+a.delete {
    margin-top: -10px;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td>table td {
    display: block !important;
    padding: 10px 0 10px;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td>table td.right {
    font-weight: bold;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
    font-size: 12px;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td>table>tbody>tr.option>td:first-child {
    width: 240px !important;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td>table>tbody>tr.option>td>table {
    margin: 5px 5px;
    color: #777;
    font-size: 12px;
    text-align: left;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td>table>tbody>tr.option .xans-product-addoption th {
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    padding: 5px 0 0 0px;
    vertical-align: top;
    display: block;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td>table>tbody>tr.option .xans-product-addoption td {
    font-size: 11px;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td>table>tbody>tr.option .xans-product-addoption td input {
    height: 23px;
    width: 180px;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td>table>tbody>tr.option .xans-product-fileoption>th {
    display: block;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td>table>tbody>tr.option .xans-product-fileoption .fileInfo .infoDesc {
    margin-top: 5px;
    color: #777;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td {
    padding: 10px;
    border-bottom: 0;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td:first-child {
    padding-bottom: 0;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td table td {
    padding: 5px 0 4px;
    border-bottom: 0;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td>table tr:first-child td {
    border-top: 0;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td>table tr.option>td {
    border-top: 1px solid #ebebeb;
    padding: 9px 0;
}

#optionBox.active #totalProducts>table>tbody>tr.option_product>td>table tr.option>td:first-child {
    padding: 0;
}

#optionBox.active #totalProducts table tr.add_product>td {
    padding: 0;
}

#optionBox.active #totalProducts table tr.add_product>td>table tr.option>td:first-child {
    padding: 0;
}

#optionBox.active #totalProducts table tr.add_product>td>table tr:first-child td {
    border-top: 0;
}

#optionBox.active #totalProducts>table>tbody>tr#totalProductsOption>td:first-child {
    border-top: 0;
    padding: 0;
    border-bottom: 0;
}

#optionBox.active #totalProducts>table>tbody>tr#totalProductsOption>td table th {
    padding: 10px 10px 10px;
    font-weight: normal;
}

#optionBox.active #totalProducts>table>tbody>tr#totalProductsOption>td table td {
    padding: 0 10px 10px 10px;
    border: 0;
}

#optionBox.active #totalProducts>table>tbody>tr#totalProductsOption>td table .xans-product-fileoption {
    border-top: 1px solid #eee;
}

#optionBox.active .total-price-wrap {
    margin-top: 10px;
}

#optionBox.active .totalPrice {
    padding: 0;
    border: 0;
    font-size: 0;
}

#optionBox.active .totalPrice>strong {
    font-weight: normal;
    width: 35%;
    text-align: left;
    color: #222;
    font-size: 15px;
    vertical-align: middle;
}

#optionBox.active .totalPrice .total {
    font-size: 15px;
    width: 70%;
    display: inline-block;
    text-align: right;
    vertical-align: middle;
    word-break: keep-all;
}

#optionBox.active .totalPrice .total strong {
    font-size: 20px;
}

#optionBox.active .totalPrice .total em {
    font-style: normal;
}

#optionBox.active .xans-product-action .btnSubmit,
#optionBox.active .xans-product-action .btnNormal,
#optionBox.active .xans-product-action .btnEm {
    border-radius: 0;
    height: 65px;
    line-height: 65px;
    font-size: 20px;
    font-weight: normal;
    margin: 10px 0;
}

#optionBox.active .xans-product-action .ec-base-button {
    display: block;
    padding: 10px 0 0;
}

#optionBox.active .xans-product-action .btnNormal,
#optionBox.active .xans-product-action .btnNormal:hover {
    background: #fff;
}

#optionBox.active .xans-product-action .btnEm,
#optionBox.active .xans-product-action .btnEm:hover {
    background: #bcbcbc;
}

#optionBox.active .productSet {
    margin: 10px 0;
}

#optionBox.active .productSet .information {
    padding: 10px 10px 10px 70px;
}

#optionBox.active .productSet .thumbnail {
    width: 50px;
    top: 10px;
    left: 10px;
}

#optionBox.active .productSet .information .name {
    padding: 0;
    margin: 0;
}

#optionBox.active .productSet .information .name strong {
    font-size: 12px;
}

#optionBox.active .productSet .information .name strong+span {
    font-size: 12px;
    color: #777;
}

#optionBox.active .productSet .option {
    padding: 0 10px 0;
}

#optionBox.active .productSet .option select {
    width: 100%;
    font-size: 12px;
}

#prdDetail .wp-opt ::-webkit-scrollbar {
    width: 8px;
}

#prdDetail .wp-opt ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(226, 226, 226, 1);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

#prdDetail .wp-opt ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(190, 190, 190, 0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(226, 226, 226, 0.5);
}

#prdDetail .wp-opt ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(200, 200, 200, 0.4);
}

#optionBox.active #NaverChk_Button .npay_storebtn_bx {
    width: 100% !important;
}

.differentialShipping {
    display: none;
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 17px;
    z-index: 100;
    width: 350px;
    margin: 0 0 0 -176px;
}

.differentialShipping h3.title {
    height: 35px;
    padding: 0 35px 0 19px;
    border: 0;
    color: #fff;
    font-size: 14px;
    line-height: 35px;
    background: #495164;
}

.differentialShipping .content {
    padding: 12px 18px 14px 18px;
    border: 1px solid #757575;
    border-top: 0;
    text-align: center;
    background: #fff;
}

.differentialShipping .close {
    position: absolute;
    right: 14px;
    top: 12px;
}

.differentialShipping .close img {
    cursor: pointer;
}

.differentialShipping .content ul {
    color: #757575;
    line-height: 25px;
}

.differentialShipping .content li {
    overflow: hidden;
}

.differentialShipping .content li strong {
    float: left;
    font-weight: normal;
}

.differentialShipping .content li span {
    float: right;
    color: #2e2e2e;
}

.differentialShipping .content .info {
    margin: 7px 0 0;
    padding: 7px 0 0;
    border-top: 1px dotted #b0b1b3;
    color: #80aeef;
    text-align: right;
}

.xans-product-detail .shippingFee {
    position: relative;
    display: inline-block;
}

.xans-product-detail .shippingFee .ec-base-tooltip {
    margin: 0 0 0 -180px;
    width: 360px;
}

.xans-product-detail .shippingFee .ec-base-tooltip table th {
    width: 40px;
}

.xans-product-detail .shippingFee .ec-base-tooltip table th,
.xans-product-detail .shippingFee .ec-base-tooltip table td {
    padding: 7px 10px 8px 10px;
}

.xans-product-detail .infoArea td>span>ul.discountMember>li {
    position: relative;
    float: left;
}

.xans-product-detail .infoArea td ul.discountMember li a {
    display: block;
    margin: 0 4px 4px 0;
}

.xans-product-detail .infoArea td ul li .discount_layer h3 {
    margin: 0 0 8px;
    padding: 0 10px;
    font-size: 12px;
    border-bottom: 0;
}

.xans-product-detail .infoArea td ul li .discount_layer {
    display: block;
    left: -140px;
    min-width: 300px;
}

.xans-product-detail .infoArea td ul li .discount_layer .content {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 400px;
}

.xans-product-detail .infoArea td ul li .discount_layer thead th {
    padding: 0;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    border-width: 0 1px 0 1px;
}

.xans-product-detail .infoArea td ul li .discount_layer tbody th {
    width: auto;
}

.xans-product-detail .productSet .product {
    position: relative;
    overflow: hidden auto;
    max-height: 442px;
    border-top: 1px solid #d7d5d5;
}

#optionBox.active .productSet .product {
    max-height: 100%;
}

.xans-product-detail .productSet .product::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(190, 190, 190, 0.7);
    -webkit-box-shadow: inset 0 0 6px rgb(226 226 226 / 50%);
}

.xans-product-detail .productSet .product::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(226 226 226);
    border-radius: 10px;
}

.xans-product-detail .productSet .product::-webkit-scrollbar {
    width: 5px;
}

.xans-product-detail .productSet .description {
    z-index: 10;
    position: absolute;
    top: 38px;
    right: 0;
    padding: 7px 0 0;
}

.xans-product-detail .productSet .description .xans-product-detail {
    position: relative;
    padding: 15px;
    border: 1px solid #565960;
    background: #fff;
}

.xans-product-detail .productSet .description>h3 {
    margin: 0 0 8px;
    padding: 0 10px;
    font-size: 12px;
    border-bottom: 0;
    /* background: url("//img.echosting.cafe24.com/skin/base/common/ico_layer_title.gif") no-repeat 0 50%; */
}

.xans-product-detail .productSet .description p strong {
    color: #0088d4;
}

.xans-product-detail .productSet .description thead th {
    padding: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
}

.xans-product-detail .productSet .description tbody {
    border: 1px solid #d7d5d6;
}

.xans-product-detail .productSet .description th,
.xans-product-detail .productSet .description tr:first-child th,
.xans-product-detail .productSet .description tr:first-child td {
    background: #fbf9fa;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    color: #363636;
}

.xans-product-detail .productSet .description th,
.xans-product-detail .productSet .description td {
    height: 15px;
    border-bottom: 1px solid #e8e8e8;
}

.xans-product-detail .productSet .description td,
.xans-product-detail .productSet .description tr:first-child td {
    padding: 5px 8px;
    text-align: left;
    background: #fff;
}

.xans-product-detail .productSet .description tr:last-of-type th,
.xans-product-detail .productSet .description tr:last-of-type td {
    border-bottom: 0;
}

.xans-product-detail .productSet .description .color td {
    font-size: 0;
    line-height: 0;
}

.xans-product-detail .productSet .description .chips {
    display: inline-block;
    width: 28px;
    height: 18px;
    margin: 0 2px 3px 0;
    border: 1px solid #e3e3e3;
}

.xans-product-detail .productSet .description .btnClose {
    background: transparent;
}

.xans-product-detail .productSet .description>.ec-base-tooltip {
    display: block;
    top: 0;
}

.xans-product-detail .productSet .description>.ec-base-tooltip>.edge {
    z-index: 20;
    position: absolute;
    left: auto;
    right: 40px;
    display: block;
    margin: 0 0 0 -5px;
    width: 9px;
    height: 6px;
    /* background: url("//img.echosting.cafe24.com/skin/base/common/ico_option_arrow.gif") no-repeat 0 0; */
}

.xans-product-detail .infoArea .regularDelivery {
    margin: 20px 0 0;
    border-top: 1px solid #e8e8e8;
}

.xans-product-detail .infoArea .regularDelivery th {
    vertical-align: top;
}

.xans-product-detail .infoArea .regularDelivery .gLabel {
    position: relative;
    margin: 0 35px 0 0;
}

.xans-product-detail .infoArea .regularDelivery .badge {
    display: inline-block;
    position: relative;
    top: -1px;
    height: 18px;
    margin: 0 0 0 5px;
    padding: 0 8px;
    border-radius: 10px;
    line-height: 18px;
    font-size: 12px;
    /* font-family: "돋움", "Dotum"; */
    color: #fff;
    background-color: #009ffa;
}

.xans-product-detail .infoArea .regularDelivery .badge .icoDown {
    display: inline-block;
    position: relative;
    top: 1px;
    overflow: hidden;
    width: 8px;
    height: 12px;
    border-radius: 50%;
    line-height: 10px;
    text-indent: -9999px;
}

.xans-product-detail .infoArea .regularDelivery .badge .icoDown:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 3px;
    left: 2px;
    width: 4px;
    height: 4px;
    background-color: #fff;
}

.xans-product-detail .infoArea .regularDelivery .badge .icoDown:after {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.xans-product-detail .infoArea .regularDelivery .info {
    margin: 14px 0 0;
}

.xans-product-detail .infoArea .regularDelivery .info ul {
    margin: 6px 0 0;
}

.xans-product-detail .infoArea .regularDelivery .info ul>li {
    margin: 4px 0;
    letter-spacing: -.8px;
}

.xans-product-detail .infoArea .regularDelivery .info .icoSave {
    display: inline-block;
    height: 12px;
    padding: 0 2px;
    border: 1px solid #ff5454;
    border-radius: 3px;
    line-height: 12px;
    color: #ff5454;
}

.xans-product-detail .infoArea .regularDelivery .info .icoDown {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 5px;
    height: 10px;
    margin: 0 0 0 2px;
    line-height: 10px;
    text-indent: -9999px;
}

.xans-product-detail .infoArea .regularDelivery .info .icoDown:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 2px;
    width: 1px;
    height: 10px;
    background-color: #ff5454;
}

.xans-product-detail .infoArea .regularDelivery .info .icoDown:after {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 2px;
    left: -2px;
    padding: 4px;
    border: solid #ff5454;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

#prodAdd.productSet {
    margin: 100px 0;
}

#prodAdd.productSet>.title {
    margin: 0 0 30px;
    text-align: center;
}

#prodAdd.productSet>.title h2 {
    font-size: 20px;
    color: #222;
}

#prodAdd.productSet .product {
    margin: 0;
    font-size: 0;
}

#prodAdd.productSet .product>li {}

#prodAdd.productSet .thumbnail {
    display: block;
}

#prodAdd.productSet .thumbnail img {
    width: 100%;
    border: 1px solid #ededed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 0 20px;
}

#prodAdd.productSet .information {
    position: relative;
}

#prodAdd.productSet .information .name {
    color: #222;
    font-size: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

#prodAdd.productSet .information .price {
    font-size: 17px;
    font-weight: bold;
}

#prodAdd.productSet .information .salePrice {
    color: #ff8a56;
    font-weight: bold;
}

#prodAdd.productSet .information .btnInfo {
    position: absolute;
    top: 19px;
    right: 19px;
    z-index: 1;
    display: block;
    width: 57px;
    height: 20px;
    vertical-align: middle;
    /* background: url("//img.echosting.cafe24.com/skin/base_ko_KR/product/btn_info.gif") no-repeat 0 0; */
}

#prodAdd.productSet .information .btnInfo.selected {
    /* background: url("//img.echosting.cafe24.com/skin/base_ko_KR/product/btn_info_on.gif") no-repeat 0 0; */
}

#prodAdd.productSet .information .btnInfo span {
    overflow: hidden;
    font-size: 0;
    width: 0;
    height: 0;
    line-height: 0;
    text-indent: 120%;
}

#prodAdd.productSet .option {
    font-size: 12px;
    color: #888;
    margin-top: 10px;
}

#prodAdd.productSet .option>li {
    margin: 0 0 12px;
}

#prodAdd.productSet .option>li select {
    width: 100%;
    height: 34px;
    font-size: 12px;
}

#prodAdd.productSet .option .name {
    display: block;
    margin: 0 0 5px;
    font-weight: normal;
}

#prodAdd.productSet .option input[type=text] {
    width: 80%;
}

#prodAdd.productSet .option .ec-base-qty {
    margin: 7px 0 0;
}

#prodAdd.productSet .option .selectButton {
    margin: 0 0 6px;
    text-align: right;
}

#prodAdd.productSet .guide {
    overflow: hidden;
    position: relative;
    margin: 0 19px;
}

#prodAdd.productSet .guide:after {
    content: "";
    display: block;
    clear: both;
}

#prodAdd.productSet .guide .qty {
    float: left;
    display: inline-block;
    padding: 12px 0;
    color: #757575;
}

#prodAdd.productSet .guide .qty:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    border-top: 1px solid #e8e8e8;
}

#prodAdd.productSet .guide .sizeGuide {
    float: right;
    height: 15px;
    margin: 12px 0;
    /* background: url("//img.echosting.cafe24.com/skin/base/product/ico_sizeGuide.gif") no-repeat 0 1px; */
}

#prodAdd.productSet .guide .sizeGuide:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    border-top: 1px solid #e8e8e8;
}

#prodAdd.productSet .guide .sizeGuide a {
    display: inline-block;
    padding: 0 15px 0 24px;
    /* background: url("//img.echosting.cafe24.com/skin/base/product/ico_sizeGuide_go.gif") no-repeat 100% center; */
}

#prodAdd.productSet.additional .title span {
    color: #757575;
    font-weight: normal;
}

#prodAdd.productSet.additional .title .toggle {
    position: absolute;
    top: 8px;
    right: 8px;
}

#prodAdd.productSet.hide .product {
    display: none;
}

#prodAdd.productSet .ec-base-desc {
    padding: 0 0 10px;
    border-top: 0;
    border-bottom: 1px solid #ddd;
}

#prodAdd.productSet .ec-base-desc dt {
    width: 135px;
    padding: 0 0 0 20px;
}

#prodAdd.productSet .bx-wrapper .bx-controls.bx-has-pager.bx-has-controls-direction .bx-pager.bx-default-pager {
    display: none;
}

#prodAdd.productSet .bx-wrapper .bx-prev {
    left: 50%;
    margin-left: -740px;
    /* background: url(/web/upload/wp/167417/bt_prev_grey.svg) no-repeat center; */
    /* background-image: url(/web/upload/wp/167417/bt_prev_grey.svg)\0/IE8; */
    background-position: center\0/IE8;
    background-size: 20px;
}

#prodAdd.productSet .bx-wrapper .bx-next {
    right: 50%;
    margin-right: -740px;
    /* background: url(/web/upload/wp/167417/bt_next_grey.svg) no-repeat center; */
    /* background-image: url(/web/upload/wp/167417/bt_next_grey.svg)\0/IE8; */
    background-position: center\0/IE8;
    background-size: 20px;
}

#prodAdd.productSet .bx-wrapper .bx-controls-direction a {
    margin-top: -30px;
    width: 60px;
    height: 60px;
}

@media (max-width:1400px) {
    #prodAdd.productSet .bx-wrapper .bx-prev {
        margin-left: 0;
        left: 0;
    }

    #prodAdd.productSet .bx-wrapper .bx-next {
        margin-right: 0;
        right: 0;
    }
}

#prdRelated.xans-product-relation {
    margin: 50px 0 130px;
}

#prdRelated.xans-product-relation .relation>.title {
    margin: 0 0 30px;
    text-align: center;
}

#prdRelated.xans-product-relation .relation>.title h2 {
    font-size: 20px;
    color: #222;
}

#prdRelated.xans-product-relation .relation ul.prdList {
    margin: 0;
    font-size: 0;
}

#prdRelated.xans-product-relation .relation ul.prdList>li .thumbnail {
    display: block;
}

#prdRelated.xans-product-relation .relation ul.prdList>li .thumbnail .thumb {
    width: 100%;
    border: 1px solid #ededed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 0 20px;
}

#prdRelated.xans-product-relation .relation ul.prdList>li .name {
    color: #222;
    font-size: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 0 10px;
}

#prdRelated.xans-product-relation .relation ul.prdList>li .price {
    font-size: 17px;
    font-weight: bold;
}

#prdRelated.xans-product-relation .relation ul.prdList>li .salePrice {
    font-size: 17px;
    font-weight: bold;
}

#prdRelated.xans-product-relation .bx-wrapper .bx-controls.bx-has-pager.bx-has-controls-direction .bx-pager.bx-default-pager {
    display: none;
}

#prdRelated.xans-product-relation .bx-wrapper .bx-prev {
    left: 50%;
    margin-left: -740px;
    /* background: url(/web/upload/wp/167417/bt_prev_grey.svg) no-repeat center; */
    /* background-image: url(/web/upload/wp/167417/bt_prev_grey.svg)\0/IE8; */
    background-position: center\0/IE8;
    background-size: 20px;
}

#prdRelated.xans-product-relation .bx-wrapper .bx-next {
    right: 50%;
    margin-right: -740px;
    /* background: url(/web/upload/wp/167417/bt_next_grey.svg) no-repeat center; */
    /* background-image: url(/web/upload/wp/167417/bt_next_grey.svg)\0/IE8; */
    background-position: center\0/IE8;
    background-size: 20px;
}

#prdRelated.xans-product-relation .bx-wrapper .bx-controls-direction a {
    margin-top: -30px;
    width: 60px;
    height: 60px;
}

@media (max-width:1400px) {
    #prdRelated.xans-product-relation .bx-wrapper .bx-prev {
        margin-left: 0;
        left: 0;
    }

    #prdRelated.xans-product-relation .bx-wrapper .bx-next {
        margin-right: 0;
        right: 0;
    }
}

.link-prev,
.link-next {
    display: none;
}

.xans-product-detail .mileage_tooltip {
    position: relative;
    display: inline-block;
}

.xans-product-detail .mileage_tooltip .ec-base-tooltip table thead th {
    font-weight: bold;
}

.xans-product-detail .mileage_tooltip .button {
    border: 1px solid #658ECB;
    color: #658ECB;
    font-size: 11px;
    display: inline-block;
    padding: 3px 8px;
    border-radius: 2px;
}

.xans-product-detail .mileage_tooltip .mileage_layer {
    display: block;
    left: -150px;
    top: 29px;
    min-width: 300px;
}

.xans-product-detail .mileage_tooltip .mileage_layer p strong {
    font-weight: bold;
    color: #008BCC;
}

.xans-product-detail .mileage_tooltip .ec-base-tooltip table th {
    padding: 7px 6px 8px 9px;
}

.xans-product-additional .ec-base-tab.gFlex .menu {
    border: 0;
    border-top: 0px solid #eee;
    border-bottom: 2px solid #222;
    margin: 0;
}

.xans-product-additional .ec-base-tab.gFlex .menu li {
    background: #fff;
    color: #222;
    border: 0;
}

.xans-product-additional .ec-base-tab.gFlex .menu li.selected {
    border: 0;
}

.xans-product-additional .ec-base-tab.gFlex .menu li.selected a {
    font-weight: normal;
    color: #fff;
    height: 73px;
    background: #222;
}

.xans-product-additional .ec-base-tab.gFlex .menu li:first-child a {
    margin-left: 0;
}

.xans-product-additional .ec-base-tab.gFlex .menu li:last-child {
    border-right: 1px solid #dbdbdb;
}

.xans-product-additional .ec-base-tab.gFlex .menu li a {
    font-size: 17px;
    height: 70px;
    padding: 0;
    line-height: 70px;
    color: #222;
}

.xans-product-additional .ec-base-tab.gFlex .menu li a .count_review,
.xans-product-additional .ec-base-tab.gFlex .menu li a .count_qna {
    font-size: 17px;
    text-align: center;
    margin-left: 7px;
}

.xans-product-additional .cont {
    padding: 0 0 20px;
}

.xans-product-additional .cont h3 {
    margin: 0 0 10px;
    color: #444;
    font-size: 15px;
}

#prdDetail .menu-inner {
    position: relative;
    height: 73px;
    margin-top: 80px;
}

#prdDetail .menu-inner.fixed ul.menu {
    position: fixed;
    top: 0;
    margin: 0;
    width: 1280px;
    left: 50%;
    margin-left: -640px;
    z-index: 200;
}

#prdDetail .wp-cont img {
    max-width: 100% !important;
    height: auto !important;
}

#prdDetail .cont {
    overflow: hidden;
    padding: 0;
    padding-top: 2px;
    position: relative;
}

#prdDetail .wp-cont {
    width: 990px;
    min-height: 600px;
    float: left;
    padding: 0 0;
    box-sizing: border-box;
    border-bottom: 1px solid #dbdbdb;
}

#prdInfo {
    padding: 120px 0 0;
}

#prdInfo>.title {
    text-align: center;
    padding: 0 0 20px;
}

#prdInfo>.title h2 {
    font-size: 22px;
    color: #222;
}

#prdInfo .cont .sb-tlt {
    color: #222;
    font-size: 15px;
    margin: 45px 0 15px;
}

#prdInfo .cont .sb-tlt:first-child {
    margin-top: 0
}

#prdInfo .cont p {
    color: #222;
    font-size: 13px;
}

#prdInfo .cont b {}

#prdInfo table {
    border-top: 1px solid #eee;
}

#prdInfo table th,
#prdInfo table td {
    padding: 15px;
    text-align: left;
    border: 1px solid #eee;
    font-weight: normal;
    font-size: 13px;
}

#prdInfo table th {
    background: #f9f9f9;
    width: 21%;
    color: #555;
    font-size: 15px;
}

#prdInfo table td {
    width: 79%;
    color: #999;
}

#prdInfo table td * {
    color: #999 !important;
}

#prdReview {
    padding: 120px 0 0;
}

#prdReview>.board .title {
    text-align: center;
    padding: 0 0 20px;
    border-bottom: 1px solid #888;
    margin-bottom: 30px;
}

#prdReview>.board .title h2 {
    font-size: 22px;
    color: #222;
}

#prdReview .ec-base-button {
    float: right;
    padding: 0;
}

#prdReview [class^='btnNormal'],
#prdReview a[class^='btnNormal'] {
    font-size: 14px;
    width: 100px;
    height: 35px;
    line-height: 33px;
    text-align: center;
    border-radius: 0;
    padding: 0;
    color: #fff;
}

#prdReview .no-permission {
    font-size: 0;
}

#prdQnA {
    padding: 120px 0 0;
}

#prdQnA>.board .title {
    text-align: center;
    padding: 0 0 20px;
}

#prdQnA>.board .title h2 {
    font-size: 22px;
    color: #222;
}

#prdQnA>.board .title+.ec-base-button {
    float: right;
    padding: 0;
}

#prdQnA>.board .title+.ec-base-button [class^='btnSubmit'] {
    font-size: 14px;
    width: 100px;
    height: 35px;
    line-height: 33px;
    text-align: center;
    border-radius: 0;
    padding: 0;
    color: #fff;
}

#prdQnA>.board .title+.ec-base-button [class^='btnNormal'] {
    font-size: 14px;
    width: 100px;
    height: 35px;
    line-height: 33px;
    text-align: center;
    border-radius: 0;
    padding: 0;
    color: #222;
}

#prdQnA .subject img {
    margin: 0 3px;
}

.xans-product-additional div.board {
    color: #353535;
}

.xans-product-additional div.board h3 {
    padding: 0 10px 0 9px;
    font-size: 12px;
}

.xans-product-additional div.board p.desc {
    padding: 0 10px 0 9px;
}

.xans-product-additional div.board p.nodata {
    margin: 10px 0 0;
    line-height: 39px;
    padding: 20px 0 0;
    text-align: center;
    font-size: 15px;
    border-top: 1px solid #888;
}

.xans-product-additional div.board td .view {
    padding: 16px 19px 0;
    line-height: 18px;
    text-align: left;
}

#commentWriteForm_4,
#commentWriteForm_6 {
    padding: 0 0;
}

#SecretForm_4,
#SecretForm_6 {
    background: #fbf9fa;
}

#SecretForm_4 .view,
#SecretForm_6 .view {
    padding: 8px 0 7px;
}

#SecretForm_4 input,
#SecretForm_6 input {
    width: 41px;
    height: 24px;
    margin: 0 0 0 6px;
    border: 0;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    /* background: url("//img.echosting.cafe24.com/skin/base_ko_KR/board/btn_ico_submit.gif") no-repeat 0 0; */
}

#SecretForm_4 input#secure_password,
#SecretForm_6 input#secure_password {
    width: 130px;
    height: 18px;
    margin-left: 11px;
    padding: 2px 2px 2px 4px;
    border: 1px solid #d9d9d9;
    font-size: 12px;
    line-height: 14px;
    cursor: text;
    background: #fff;
}

#product-review-read td,
#product-qna-read td {
    padding: 0;
}

#product-review-read td #SecretForm_4 .view {
    padding-left: 79px;
}

#product-qna-read td #SecretForm_6 .view {
    padding-left: 213px;
}

.xans-product-additional .boardComment {
    padding: 0 19px;
}

.xans-product-additional .boardComment li {
    position: relative;
    overflow: hidden;
    margin: 10px 0 0 0;
    padding: 12px 0 0;
    border-top: 1px solid #d7d5d6;
    color: #353535;
    text-align: left;
    line-height: 14px;
}

.xans-product-additional .boardComment li:first-child {
    margin-top: 0;
}

.xans-product-additional .boardComment li .comment {
    margin: 9px 0 0;
    padding: 16px 0 15px 9px;
    border-top: 1px solid #e9e9e9;
    line-height: 18px;
    word-break: break-all;
}

.xans-product-additional .boardComment li .name {
    margin: 0 21px 0 0;
}

.xans-product-additional .boardComment li .name input {
    width: 13px;
    height: 13px;
    margin: 0 2px 0 0;
    vertical-align: -2px;
}

.xans-product-additional .boardComment li .date {
    margin: 0 10px 0 0;
    font-size: 11px;
}

.xans-product-additional .boardComment li .grade {
    margin: 0 10px 0 0;
}

.xans-product-additional .boardComment li .button {
    position: absolute;
    right: 0;
    top: 6px;
}

.xans-product-additional .memoCont {
    margin: 20px 15px;
    padding: 10px 20px 15px 20px;
    border: 1px solid #e9e9e9;
    color: #353535;
    text-align: left;
    background: #fbfafa;
}

.xans-product-additional .memoCont .user input[type=text],
.xans-product-additional .memoCont .user input[type=password] {
    width: 135px;
    margin: 0 10px 0 9px;
}

.xans-product-additional .memoCont .user .secret {
    margin: 0 20px 0 10px;
}

.xans-product-additional .memoCont .user .secret label {
    margin: 0;
}

.xans-product-additional .memoCont .user textarea {
    width: calc(100% - 90px);
    padding: 2px 0;
    height: 44px;
    text-indent: 4px;
}

.xans-product-additional .memoCont .user a {
    float: right;
}

.xans-product-additional .memoCont .nameArea {
    display: block;
    margin: 0 0 10px;
}

.xans-product-additional .memoCont .nameArea .ec-base-help {
    margin-top: 10px;
}

.xans-product-additional .memoCont .rating {
    margin: 12px 0 0;
}

.xans-product-additional .memoCont .rating label {
    padding: 0 10px 0 0;
}

.xans-product-additional .memoCont .rating em {
    font-style: normal;
}

.xans-product-additional .memoCont .text {
    margin: 5px 0 0;
    font-size: 11px;
}

.xans-product-additional .memoCont .text span {
    color: #000000;
}

.xans-product-additional .memoCont .captcha {
    margin: 8px 0 0;
    padding: 10px 0 0;
    border-top: 1px solid #e9e9e9;
    color: #757575;
}

.xans-product-additional .memoCont .captcha img {
    vertical-align: middle;
}

.xans-product-additional .memoCont .captcha input {
    margin: 0 10px 0 20px;
    font-size: 12px;
}

.xans-product-additional table.board td .view+form .memoCont {
    margin-top: 0;
}

.xans-product-additional .boardComment li.replyArea {
    margin: -1px 0 0;
    padding: 10px 0 7px 33px;
    border: 1px solid #e9e9e9;
    /* background: url("//img.echosting.cafe24.com/skin/admin_ko_KR/board/ico_comment.gif") no-repeat 20px 15px; */
}

.xans-product-additional .boardComment li.replyArea .commentSecret {
    margin-right: 30px;
}

.xans-product-additional .boardComment li.replyArea .comment {
    margin: 7px 0 0;
    padding: 0;
    border-top: 0;
}

.xans-product-additional .noAccess {
    margin: 15px 0 10px;
    border: 1px solid #d7d5d5;
    font-weight: bold;
    color: #000000;
    line-height: 39px;
    text-align: center;
}

.xans-product-additional .minor {
    margin: 15px 0 0;
    padding: 35px 0 24px;
    border: 1px solid #d7d5d5;
    font-weight: bold;
    color: #353535;
    line-height: 14px;
    text-align: center;
}

.xans-product-additional .minor p:first-child img {
    margin: -11px 8px 0 0;
    vertical-align: top;
}

.xans-product-additional .minor strong {
    color: #000000;
}

.xans-product-additional .minor .button {
    margin: -7px 0 0;
}

.xans-product-additional .commentSecret {
    margin: 10px 10px 5px 0;
    padding: 10px 15px;
    border: 1px solid #e9e9e9;
    background-color: #fbfafa;
    color: #353535;
}

.imgArea {
    text-align: center;
}

#use_review .no-permission {
    text-align: center;
    padding: 10px 0 30px;
}

.border-notice-list {
    padding: 0 10px 5px;
    border: 1px solid #dedede;
    margin: 0 0 20px;
}

.border-notice-list .ec-base-table.typeList table {
    border: 0;
}

.border-notice-list .ec-base-table.typeList table tr:first-child th,
.border-notice-list .ec-base-table.typeList table tr:first-child td {
    border-top: 0
}

.review-list {
    font-size: 0;
    text-align: left;
    margin: 20px 0px 0;
}

.review-list li.item {
    display: none;
    vertical-align: top;
    cursor: pointer;
    font-size: 13px;
    width: 25%;
}

.review-list li.item .box {
    margin: 0 10px 20px;
    border: 1px solid #eee;
    text-align: left;
    position: relative;
    background: #fff;
}

.review-list li.item .img {
    cursor: pointer;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: 50%;
    /* background: url(/board/review/img/no_img.png) no-repeat 50% 50%; */
}

.review-list li.item .img a:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    transition: background .25s ease;
}

.review-list li.item:hover .img a:before {
    background: rgba(0, 0, 0, 0.2);
}

.review-list li.item .img>p {
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    -webkit-transition: transform .35s ease;
    -moz-transition: transform .35s ease;
    -ms-transition: transform .35s ease;
    transition: transform .35s ease;
}

.review-list li.item:hover .img>p {
    -webkit-transform: scale(1.07);
    -moz-transform: scale(1.07);
    -ms-transform: scale(1.07);
    transform: scale(1.07);
}

.review-list li.item .img>p>a {
    display: block;
    width: 100%;
    height: 100%;
}

.review-list li.item .img .thumb {
    opacity: 0;
    display: none;
}

.review-list li.item .cont-left {
    padding: 10px 14px 0;
}

.review-list li.item .cont-right {
    padding: 10px 14px 0;
}

.review-list li.item .cont p.subject {
    height: 0 !important;
}

.review-list li.item .cont p.desc {
    color: #000;
}

.review-list li.item .cont p.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    line-height: 20px;
    height: 40px;
    font-size: 13px;
}

.review-list li.item .cont p.writer {
    color: #aaa;
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    line-height: 13px;
}

.review-list li.item .cont p.writer:before {
    content: "";
    display: inline-block;
    /* background: url(/board/review/img/ico_user.png) no-repeat 0 0 / 10px; */
    width: 10px;
    height: 10px;
    vertical-align: middle;
    margin-right: 2px;
    margin-top: -2px;
}

.review-list li.item .cont p.comment {
    color: #aaa;
    display: inline-block;
    vertical-align: middle;
    line-height: 13px;
    border-left: 1px solid #ddd;
    margin-left: 10px;
    padding-left: 10px;
}

.review-list li.item .cont p.comment:before {
    content: "";
    width: 15px;
    height: 15px;
    /* background: url(/board/review/img/ico_comment.gif) no-repeat 0 1px; */
    display: inline-block;
    vertical-align: middle;
}

.review-list li.item .cont p .btnModify {
    float: right;
    margin-top: 15px;
    padding: 1px 7px;
}

.review-list li.item .cont-left .prod-wrap {
    display: none;
}

.review-list li.item .cont-left .vote-wrap {
    display: none;
}

.review-list li.item .cont-right .date {
    color: #aaa;
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    line-height: 13px;
    border-left: 1px solid #ddd;
    margin-left: 10px;
    padding-left: 10px;
}

.review-list li.item .cont-right .prod-wrap {
    font-size: 0;
    border: 1px solid #eee;
    margin: 8px 0;
    border-radius: 5px;
    display: block;
    padding: 5px;
    line-height: 1.5;
}

.review-list li.item .cont-right .prod-wrap li {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
}

.review-list li.item .cont-right .prod-wrap li:first-child {
    width: 40px;
    height: 40px;
    overflow: hidden;
}

.review-list li.item .cont-right .prod-wrap li:first-child a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 5px;
    box-sizing: border-box;
}

.review-list li.item .cont-right .prod-wrap li:first-child a img {
    width: 100%;
    height: 100%;
}

.review-list li.item .cont-right .prod-wrap li:last-child {
    width: calc(100% - 50px);
    padding-left: 10px;
    box-sizing: border-box;
}

.review-list li.item .cont-right .prod-wrap li span {
    display: block;
}

.review-list li.item .cont-right .prod-wrap li span,
.review-list li.item .cont-right .prod-wrap li .name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.review-list li.item .cont-right .prod-wrap li .name a {
    color: #757474;
    line-height: 1.5;
}

.review-list li.item .cont-right .prod-wrap li .rate1:before {
    content: "★☆☆☆☆";
    color: #f94830;
}

.review-list li.item .cont-right .prod-wrap li .rate2:before {
    content: "★★☆☆☆";
    color: #f94830;
}

.review-list li.item .cont-right .prod-wrap li .rate3:before {
    content: "★★★☆☆";
    color: #f94830;
}

.review-list li.item .cont-right .prod-wrap li .rate4:before {
    content: "★★★★☆";
    color: #f94830;
}

.review-list li.item .cont-right .prod-wrap li .rate5:before {
    content: "★★★★★";
    color: #f94830;
}

.review-list li.item .cont-right .vote-wrap {
    padding: 0 0 10px;
    font-size: 12px;
}

.review-list li.item .cont-right .vote-wrap li {
    padding: 0 5px 6px 0;
    color: #888;
    font-size: 12px;
    display: inline-block;
    vertical-align: middle;
}

.review-list.grid5 li.item .cont-right .vote-wrap li {
    display: block;
}

.review-list li.item img {
    width: 100%;
}

.border-fixed-list {
    padding: 19px;
    margin: 0 0 50px;
    background: #f9f9f9;
    counter-reset: counter;
}

.border-fixed-list .section-title {
    text-align: center;
}

.border-fixed-list .section-title h2 {
    padding: 10px 0 0;
    font-weight: normal;
    font-size: 23px;
}

.border-fixed-list .review-list {
    text-align: center;
    font-size: 0;
    text-align: left;
    margin: 20px 0px 0;
}

.border-fixed-list .review-list li.item .checkbox {
    display: none;
}

.border-fixed-list .review-list li.item .best {
    position: absolute;
    top: -6px;
    left: 0;
    box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
    z-index: 10;
    background: #4e4e4e;
    color: #fff;
    padding: 6px;
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 1.4;
}

.border-fixed-list .review-list li.item .best:after {
    content: "";
    background: rgba(0, 0, 0, 0);
    width: 0px;
    height: 0px;
    position: absolute;
    right: -6px;
    top: 0;
    border-right: 6px solid rgba(0, 0, 0, 0);
    border-bottom: 6px solid #000;
}

.border-fixed-list .review-list li.item .best>span {
    display: block;
}

.border-fixed-list .review-list li.item .best>span:before {
    content: "BEST";
    display: block;
    font-weight: bold;
    font-size: 11px;
}

.border-fixed-list .review-list li.item .best>span:after {
    display: block;
    counter-increment: counter;
    content: counter(counter);
    font-size: 16px;
    font-weight: bold;
}

.review-list.gall li.item:first-child {
    display: block;
    visibility: hidden;
}

.review-list.gall li.item .checkbox {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 2;
}

#main .review-list .checkbox {
    display: none !important;
}

.review-list.gall.grid0 {
    margin: 20px 0 0;
}

.review-list.gall.grid0 li.item:first-child .box {
    border-top: 1px solid #eee;
}

.review-list.gall.grid0 li.item .box {
    margin: 0;
    padding: 25px 0;
    overflow: hidden;
    border: 0;
    border-bottom: 1px solid #eee;
    border-top: 0;
}

.review-list.gall.grid0 li.item .checkbox {
    top: 5px;
}

.review-list.gall.grid0 li.item .img {
    height: auto !important;
    float: right;
    position: absolute;
    right: 15%;
    width: 300px;
    height: 60px !important;
    text-align: right;
    top: 25px;
    padding: 0;
    background: none;
}

.review-list.gall.grid0 li.item .img p {
    background: none !important;
}

.review-list.gall.grid0 li.item:hover .box .img a:before {
    content: none;
}

.review-list.gall.grid0 li.item .img .open-review {
    display: none;
}

.review-list.gall.grid0 li.item .img .open-review img {
    width: 100% !important;
    margin: 0 !important;
    left: 0 !important;
    height: auto !important;
    top: 0 !important;
    position: initial !important;
}

.review-list.gall.grid0 li.item .cont {
    padding: 0;
    float: left;
}

.review-list.gall.grid0 li.item .cont-left {
    display: inline-block;
    vertical-align: top;
    width: 58%;
    text-align: left;
    padding-left: 0px;
    box-sizing: border-box;
    float: left;
    position: relative;
    z-index: 2;
    height: 100px;
}

.review-list.gall.grid0 li.item .cont-right {
    text-align: right;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    height: 100px;
    left: 115px;
}

.review-list.gall.grid0 li.item .cont p.ellipsis {}

.review-list.gall.grid0 li.item .cont-left .prod-wrap {
    float: left;
    display: block;
}

.review-list.gall.grid0 li.item .cont-left .prod-wrap li:first-child {
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin-right: 15px;
    margin-bottom: 10px;
}

.review-list.gall.grid0 li.item .cont-left .prod-wrap li:last-child {
    box-sizing: border-box;
    display: none;
    vertical-align: middle;
}

.review-list.gall.grid0 li.item .cont-left .prod-wrap li span,
.review-list.gall.grid0 li.item .cont-left .prod-wrap li .name,
.review-list.gall.grid0 li.item .cont-left .prod-wrap li .name a {
    font-size: 12px;
    color: #999;
}

.review-list.gall.grid0 li.item .cont-left .prod-wrap li .rate {
    display: none;
}

.review-list.gall.grid0 li.item .cont p.date {
    display: block;
    border: 0;
    margin: 0;
    padding: 0;
    margin-top: 5px;
}

.review-list.gall.grid0 li.item .cont p.date:before {
    content: none;
}

.review-list.gall.grid0 li.item .cont p.comment {
    padding-top: 0;
    padding-bottom: 2px
}

.review-list.gall.grid0 li.item .cont p .btnModify {
    margin: 10px 0 5px;
    display: inline-block;
    float: none;
}

.review-list.gall.grid0 li.item .cont-right .prod-wrap {
    border: 0;
    padding: 0;
    margin: 5px 0;
    border-radius: 0;
    height: 0;
}

.review-list.gall.grid0 li.item .cont-right .prod-wrap li:first-child {
    display: none;
}

.review-list.gall.grid0 li.item .cont-right .prod-wrap li .name {
    display: none;
}

.review-list.gall.grid0 li.item .cont-right .prod-wrap li .rate {
    padding-top: 5px;
    font-size: 12px;
}

.review-list.gall.grid0 li.item .cont-right .vote-wrap {
    padding: 0;
    text-align: left;
    position: absolute;
    margin-top: 10px;
    left: 0;
    z-index: 3;
}

.review-list.gall.grid0 li.item .cont-right .vote-wrap li {
    display: inline-block;
    vertical-align: middle;
}

.wpReview.xans-product-review .review-list.gall li.item .checkbox {
    display: none;
}

.wpReview.xans-product-review .review-list.gall li.item .cont-left .prod-wrap {
    display: none;
}

.wpReview.xans-product-review .review-list.gall.grid0 li.item .cont-right {
    width: 100%;
    left: 0;
}

.wpReview.xans-product-review .review-list.gall li.item .cont-right .prod-wrap {
    border: 0;
    border-radius: 0;
    padding: 0;
}

.wpReview.xans-product-review .review-list.gall li.item .cont-right .prod-wrap li:first-child {
    display: none;
}

.wpReview.xans-product-review .review-list.gall li.item .cont-right .prod-wrap li:last-child {
    width: 100%;
    padding: 0;
}

.wpReview.xans-product-review .review-list.gall li.item .cont-right .prod-wrap li .name {
    display: none;
}

.review-list .thumb-list {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 0;
    z-index: 12;
}

.review-list .thumb-list li {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0 3px;
    transition: opacity .3s ease;
    border: 1px solid #eee;
    border-radius: 3px;
    -webkit-box-shadow: 1px 3px 6px -4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 3px 6px -4px rgba(0, 0, 0, 0.5);
    box-shadow: 1px 3px 6px -4px rgba(0, 0, 0, 0.5);
}

.review-list .thumb-list li img {
    width: 100%;
    height: 100%;
}

.review-list .thumb-list li:hover {
    cursor: zoom-in;
}

.review-list a,
.review-list a:hover {
    text-decoration: none;
}

.review-list.gall.grid0 .thumb-list {
    top: 0;
    bottom: auto;
}

.review-list.gall.grid0 .thumb-list li {
    width: 60px;
    height: 60px;
    box-sizing: border-box;
}

.review-list.grid6 li.item {
    width: 16.66%;
    display: inline-block;
    vertical-align: top;
}

.review-list.grid5 li.item {
    width: 20%;
    display: inline-block;
    vertical-align: top;
}

.review-list.grid4 li.item {
    width: 25%;
    display: inline-block;
    vertical-align: top;
}

.review-list.grid3 li.item {
    width: 33.33%;
    display: inline-block;
    vertical-align: top;
}

.review-list.grid2 li.item {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

.review-list.grid1 li.item,
.review-list.grid0 li.item {
    width: 100%
}

#search_date,
#search_key {
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;
    margin: 0;
}

.xans-board-search {
    padding: 30px 0 30px 0px;
    color: #2e2e2e;
    background: #f9f9f9;
    font-size: 13px;
}

.xans-board-search .boardSearch {
    display: block;
    max-width: 1100px;
    margin: 0 auto;
    text-align: center;
}

.xans-board-search p.category {
    display: inline-block;
    box-sizing: border-box;
    padding: 0px 25px 0 0;
    width: calc(100% - 440px);
    border-right: 1px solid #ddd;
    margin-right: 20px;
}

.xans-board-search p.category .first {
    color: #777;
    width: 100px;
    display: inline-block;
    vertical-align: middle;
}

.xans-board-search p.formSearch {
    display: inline-block;
    width: 400px;
    box-sizing: border-box;
}

.xans-board-search p.formSearch .first {
    color: #777;
    width: 80px;
    display: inline-block;
    vertical-align: middle;
}

.xans-board-search .category select {
    margin: 0 4px 0 0;
    width: 490px;
    height: 36px;
}

.xans-board-search .category select#product_category_depth1 {
    margin: 0 5px 0 10px;
}

.xans-board-search .category select#product_category_depth2,
.xans-board-search .category select#product_category_depth3,
.xans-board-search .category select#product_category_depth4 {
    display: none;
}

.xans-board-search select {
    margin: 0 2px 0 0;
    width: 72px;
}

.xans-board-search select#search_date {
    margin: 0 2px 0 22px;
}

.xans-board-search input[type=text] {
    width: 180px;
    height: 36px;
    line-height: 34px;
    padding: 0;
    box-sizing: border-box;
}

.xans-board-search .btnNormal {
    padding: 0;
    width: 60px;
    height: 36px;
    line-height: 34px;
}

.xans-board-search .boardSearch img {
    vertical-align: middle;
    margin-top: -1px;
    margin-right: 3px;
}

.wpReview .gridType {
    font-size: 0;
    text-align: right;
    margin: 30px 0 10px;
}

.wpReview .gridType dd {
    margin: 3px;
    display: inline-block;
    width: 30px;
    height: 30px;
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #e0dcdc;
    border-radius: 3px;
}

.wpReview .gridType dd.selected {
    border-color: #444;
}

.wpReview .gridType dd a {
    display: block;
    height: 100%;
    width: 100%;
}

.wpReview .gridType dd.g0 {
    /* background-image: url("/board/review/img/list_g0.png"); */
}

.wpReview .gridType dd.g1 {
    /* background-image: url("/board/review/img/list_g1.png"); */
}

.wpReview .gridType dd.g2 {
    /* background-image: url("/board/review/img/list_g2.png"); */
}

.wpReview .gridType dd.g3 {
    /* background-image: url("/board/review/img/list_g3.png"); */
}

.wpReview .gridType dd.g4 {
    /* background-image: url("/board/review/img/list_g4.png"); */
}

.review-list [class^='btn'].btnVote,
.review-list a[class^='btn'].btnVote {
    border-radius: 3px;
    padding: 0;
    width: 40px;
    height: 26px;
    line-height: 26px;
    font-size: 12px;
}

.review-list [class^='btn'].btnVote:hover,
.review-list a[class^='btn'].btnVote:hover {
    background: #de3636;
    color: #fff;
    border-color: #de3636
}

.review-list .voteCount {
    border-radius: 2px;
    position: relative;
    background: #444;
    color: #fff;
    display: inline-block;
    margin-left: 8px;
    height: 26px;
    line-height: 26px;
    font-size: 12px;
    width: 20px;
    text-align: center;
    vertical-align: middle;
}

.review-list .voteCount:before {
    border-radius: 2px;
    content: "";
    position: absolute;
    font-weight: bold;
    transform: rotate(45deg);
    background: #444;
    width: 6px;
    height: 6px;
    left: -3px;
    top: 8px;
}

.movement {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin-top: -30px;
    z-index: 999;
}

.movement .link-prev,
.movement .link-next {
    position: absolute;
    height: 60px;
    width: 60px;
    line-height: 80px;
    color: #fff;
    text-align: center;
}

.movement .link-prev {
    left: -70px;
    /* background: url(/board/review/img/arrow_t5_prev.png) no-repeat center 10px; */
}

.movement .link-next {
    right: -70px;
    /* background: url(/board/review/img/arrow_t5_next.png) no-repeat center 10px; */
}

.btn-review-more {
    text-align: center;
    margin-top: 20px;
}

.pop-review-bg {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 200;
    left: 0;
    top: 0;
    cursor: pointer;
    display: none;
}

.pop-review-bg .read.close {
    display: none;
    position: absolute;
    width: 35px;
    height: 35px;
    top: -20px;
    right: -20px;
    z-index: 99;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    /* background: #FFF url(/board/review/img/close.png) no-repeat center / 30px; */
    box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.1);
}

#readReviewFrameWrap {
    position: absolute;
}

#readReviewFrame {
    position: absolute;
    border: 0;
    display: none;
}

#readReviewFrame:before {
    content: ""
}

#readReviewFrameWrap.noImg,
#readReviewFrame.noImg {
    top: 50%;
    left: 50%;
    margin-left: -430px;
    margin-top: -325px;
    width: 862px;
    height: 700px;
}

.clone-slide-bg {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 201;
    left: 0;
    top: 0;
    display: none;
}

.clone-slide-bg img {
    cursor: pointer;
}

.clone-slide-bg .center-box {
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
}

.clone-slide-bg .center-box .close {
    cursor: pointer;
    display: block;
    position: absolute;
    width: 35px;
    height: 35px;
    top: -20px;
    right: -20px;
    z-index: 99;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    /* background: #FFF url(/board/review/img/close.png) no-repeat center / 30px; */
    box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.1);
}

.clone-slide-bg .bx-pager,
.bx-wrapper .bx-controls-auto {
    bottom: 10px;
}

.clone-slide-bg .bx-controls-direction a {
    margin-top: -40px;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    outline: 0;
    text-indent: -9999px;
    z-index: 79;
}

.clone-slide-bg .bx-prev {
    left: 50%;
    margin-left: -600px;
    /* background: url(/board/review/img/slide_prev_w.png) no-repeat 0 0 / 80px; */
    /* background-image: url(/board/review/img/slide_prev_w.png)\0/IE8; */
    background-position: center\0/IE8;
}

.clone-slide-bg .bx-next {
    right: 50%;
    margin-right: -600px;
    /* background: url(/board/review/img/slide_next_w.png) no-repeat 0 0 / 80px; */
    /* background-image: url(/board/review/img/slide_next_w.png)\0/IE8; */
    background-position: center\0/IE8;
}

.clone-slide-bg .disabled {
    display: none;
}

@keyframes review-loading {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes review-loading {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.review-loading {
    position: relative;
    width: 100%;
    height: 100%;
}

.review-loading:before {
    content: '';
    display: block;
    position: absolute;
    width: 160px;
    height: 160px;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px;
    border-radius: 50%;
    border: 8px solid #000;
    border-color: #fff transparent #fff transparent;
    -webkit-animation: review-loading 0.6s linear infinite;
    animation: review-loading 0.6s linear infinite;
}

.review-loading {
    -webkit-transform: translate(-20px, -20px) scale(0.2) translate(20px, 20px);
    transform: translate(-20px, -20px) scale(0.2) translate(20px, 20px);
}

#frmReviewWrite,
#frmReviewList {
    border: 0;
    width: 100%;
}

.review-logoff-box {
    margin: 15px 0;
    box-sizing: border-box;
}

.nodata {
    margin: 0 0 35px;
    border-bottom: 1px solid #eee;
    color: #999;
    line-height: 39px;
    text-align: center;
}

#use_review .no-permission {
    text-align: center;
    padding: 30px 0;
}

html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
img {
    margin: 0;
    padding: 0;
}

html {
    width: 100%;
    height: 100%;
}

body,
code {
    /* font: 14px Verdana, Dotum, AppleGothic, sans-serif; */
    color: #222;
    background: #fff;
}

body {
    min-width: 1280px;
}

body#review {
    min-width: 100px;
}

body#popup {
    min-width: 0;
}

li {
    list-style: none;
}

img,
fieldset {
    border: none;
    vertical-align: top;
}

table {
    width: 100%;
    border: 0;
    border-spacing: 0;
    border-collapse: collapse;
}

caption {
    display: none;
}

th,
td {
    border: 0;
    vertical-align: top;
}

button {
    overflow: visible;
    padding: 0;
    margin: 0;
    border: 0;
    cursor: pointer;
}

hr.layout {
    display: none;
}

a {
    text-decoration: none;
    color: #000;
}

a:hover {
    text-decoration: underline;
}

a:active {
    text-decoration: none;
}

.objHidden {
    visibility: hidden;
    position: absolute;
    left: -1000px;
    top: -1000px;
    height: 0;
    width: 0;
}

#content_CONTAINER table,
#bi_inquire_content_CONTAINER table {
    border: 0;
    margin: 0 0 -1px;
}

#content_CONTAINER table:before,
#bi_inquire_content_CONTAINER table:before {
    display: none;
}

#content_CONTAINER td,
#bi_inquire_content_CONTAINER td {
    width: auto !important;
    padding: 0;
}

table.nneditor-table {
    width: auto !important;
}

#progressPaybarBackground {
    position: absolute;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

#progressPaybarView {
    position: absolute;
    z-index: 100;
    top: 160px;
    left: 20px;
    width: 471px;
    font-size: 12px;
}

#progressPaybarView .box {
    height: 180px;
    /* background: url(//img.cafe24.com/images/ec_hosting/popup/layer_guide/bg_layer_box1.gif) 0 0 no-repeat; */
}

#progressPaybarView .box .graph {
    padding: 15px 0 0 0;
    text-align: center;
}

#progressPaybarView .box .graph span {
    display: block;
    text-align: center;
    margin: 20px 0;
}

#progressPaybarView .box .txt {
    text-align: center;
    padding: 15px 0 0;
    color: #555555;
}

div.layerProgress {
    filter: alpha(opacity=40);
    opacity: 0.4;
    -moz-opacity: 0.4;
    background-color: #999;
}

#skipNavigation {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 0;
}

#skipNavigation p,
#skipNavigation ul,
#skipNavigation ul li {
    height: 0;
}

#skipNavigation p a {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin-left: -1px;
    margin-bottom: -1px;
    text-align: center;
    color: #000;
    white-space: nowrap;
}

#skipNavigation p a:focus,
#skipNavigation p a:hover,
#skipNavigation p a:active {
    width: 100%;
    height: auto;
    padding: 10px 0;
    background: #c4122f;
    color: #fff;
    z-index: 100;
}

.displaynone {
    display: none;
}

.dimmed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.8;
    filter: alpha(opacity=80);
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(226, 226, 226);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(190, 190, 190, 0.7);
    -webkit-box-shadow: inset 0 0 6px rgba(226, 226, 226, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(200, 200, 200, 0.4);
}

.txtInfo {
    color: #707070;
}

.txtWarn {
    color: #f76560;
}

.txtEm {
    color: #d61c1c;
}

.txtDel,
.strike,
.discount {
    text-decoration: line-through;
    font-weight: normal;
}

.strike strong,
.discount strong {
    font-weight: normal;
}

.txtNormal {
    font-weight: normal;
    font-style: normal;
}

.txtNum {
    display: inline-block;
    font-size: 13px;
    color: #939393;
    word-break: normal;
}

.txt11 {
    font-size: 11px;
}

.txt12 {
    font-size: 12px;
}

.txt14 {
    font-size: 14px;
}

.txt16 {
    font-size: 16px;
}

.txt18 {
    font-size: 18px;
    letter-spacing: -1px;
}

.txtIcon {
    font-size: 12px;
    font-style: normal;
}

.txtBreak {
    word-break: break-all;
    word-wrap: break-word;
}

html:lang(ja) .txt11,
html:lang(zh) .txt11,
html:lang(zh-tw) .txt11 {
    font-size: 12px;
}

.gBlank5 {
    display: block;
    margin-top: 5px;
}

.gBlank10 {
    display: block;
    margin-top: 10px;
}

.gBlank20 {
    display: block;
    margin-top: 20px;
}

.gBlank30 {
    display: block;
    margin-top: 30px;
}

.gIndent10 {
    margin-left: 10px;
}

.gIndent20 {
    margin-left: 20px;
}

.gSpace10 {
    margin-right: 10px;
}

.gSpace20 {
    margin-right: 20px;
}

.gMerge {
    position: relative;
    z-index: 1;
    margin-top: -1px;
}

.ec-base-qty {
    position: relative;
    display: inline-block;
    width: 50px;
    margin: 0 1px 0 0;
    text-align: left;
}

.ec-base-qty input[type="text"] {
    width: 22px;
    height: 23px;
    padding: 0 0 0 5px;
    line-height: 25px;
    border: 1px solid #d4d8d9;
    border-radius: 3px 0 0 3px;
}

.ec-base-qty .up {
    position: absolute;
    left: 27px;
    top: 0;
}

.ec-base-qty .down {
    position: absolute;
    left: 27px;
    bottom: 0;
}

.ec-base-qty .qtyUp {
    position: absolute;
    left: 27px;
    top: 0;
}

.ec-base-qty .qtyDown {
    position: absolute;
    left: 27px;
    bottom: 0;
}

.ec-base-qty .qtyUp .up {
    position: static;
    left: auto;
    top: auto;
}

.ec-base-qty .qtyDown .down {
    position: static;
    left: auto;
    top: auto;
}

input,
select,
textarea {
    font-size: 100%;
    font-family: 'Noto Sans KR', 'Sans-serif';
    color: #353535;
    vertical-align: middle;
}

input[type=radio],
input[type=checkbox] {
    width: 13px;
    height: 13px;
    border: 0;
}

input[type=text],
input[type=password] {
    height: 28px;
    line-height: 30px;
    padding: 2px 4px;
    border: 1px solid #dbdbdb;
    color: #353535;
    font-size: 14px;
}

input[type=radio]+label,
input[type=checkbox]+label {
    margin: 0 4px 0 2px;
}

select {
    height: 34px;
    border: 1px solid #dbdbdb;
}

textarea {
    padding: 5px 6px;
    border: 1px solid #dbdbdb;
    line-height: 1.5;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #c1c1c1;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #c1c1c1;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    color: #c1c1c1;
    opacity: 1;
}

legend {
    visibility: hidden;
    position: absolute;
    left: -9999px;
    top: -9999px;
    width: 0;
    height: 0;
    line-height: 0;
}

table tr.radioType input,
span.noBorder input {
    border: none !important;
    width: auto !important;
    height: auto !important;
    margin: 0 3px 0 0 !important;
    vertical-align: middle !important;
    background: none !important;
}

.gLabel {
    display: inline-block;
}

.gLabel label {
    margin-right: 20px;
    line-height: 22px;
}

.fWidthFull input[type=text] {
    width: 100%;
    height: 24px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.fWidthFull textarea {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.fWidthFull select {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.fList.typeHor .gLabel {
    margin-right: 20px;
}

.fList.typeVer .gLabel {
    display: block;
}

.ec-base-radio.typeDelivery .ec-base-label {
    display: block;
}

.ec-base-chk {
    display: inline-block;
    position: relative;
    margin: 0 5px 0 0;
    width: 26px;
    height: 26px;
    vertical-align: top;
    cursor: pointer;
}

.ec-base-chk input {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.ec-base-chk .checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: url("/web/upload/wp/167417/p__check.png") no-repeat 0 0; */
}

.ec-base-chk input:checked+.checkbox {
    background-position: -33px 0;
}

.ec-base-tooltip {
    display: none;
    z-index: 2;
    position: absolute;
    top: 24px;
    padding: 15px;
    border: 1px solid #565960;
    background: #fff;
    -webkit-box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.15);
}

.ec-base-tooltip h3 {
    margin: 0 0 8px;
    padding: 0 10px;
    font-size: 12px;
    border-bottom: 0;
    /* background: url("//img.echosting.cafe24.com/skin/base/common/ico_layer_title.gif") no-repeat 0 50%; */
}

.ec-base-tooltip h3+p {
    margin: 0 5px 10px;
}

.ec-base-tooltip h4 {
    margin: 15px 10px 8px 5px;
    font-size: 12px;
}

.ec-base-tooltip h4:before {
    display: inline-block;
    content: "";
    margin: 0 5px 0 0;
    width: 3px;
    height: 3px;
    background: #666;
    vertical-align: middle;
}

.ec-base-tooltip .btnClose {
    position: absolute;
    right: 14px;
    top: 14px;
}

.ec-base-tooltip .edge {
    z-index: 20;
    position: absolute;
    left: 50%;
    top: -6px;
    display: block;
    margin: 0 0 0 -5px;
    width: 10px;
    height: 6px;
}

.ec-base-tooltip .edge:before,
.ec-base-tooltip .edge:after {
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border: solid transparent;
}

.ec-base-tooltip .edge:before {
    margin: 1px 0 0;
    border-bottom-color: #565960;
    border-width: 0 5px 5px 5px;
}

.ec-base-tooltip .edge:after {
    left: 1px;
    margin: 2px 0 0;
    border-bottom-color: #fff;
    border-width: 0 4px 4px 4px;
}

.ec-base-tooltip table th,
.ec-base-tooltip table td {
    padding: 8px 10px 7px 10px;
    border: 1px solid #e8e8e8;
    line-height: 1.5em;
}

.ec-base-tooltip table th {
    padding-right: 0;
    background: #fbf9fa;
}

.ec-base-tooltip table thead th {
    text-align: center;
}

.ec-base-tooltip table tbody th {
    text-align: left;
}

.ec-base-tooltip table .left {
    text-align: left;
}

.ec-base-tooltip table .center {
    text-align: center;
}

.ec-base-tooltip table .right {
    text-align: right;
}

.ec-base-tooltip table .info {
    padding: 0;
    color: #757575;
}

.ec-base-tooltip table p.info:before,
.ec-base-tooltip table ul.info li:before {
    display: inline-block;
    content: "";
    margin: 0 3px 0 0;
    width: 4px;
    height: 1px;
    background: #757575;
    vertical-align: middle;
}

.ec-base-tooltip .info {
    padding: 10px 10px 0;
    margin: 5px 0 0;
}

.ec-base-tooltip p.bullet,
.ec-base-tooltip ul.bullet {
    margin: 8px 10px;
}

.ec-base-tooltip p.bullet:before,
.ec-base-tooltip ul.bullet li:before {
    display: inline-block;
    content: "";
    margin: -3px 3px 0 0;
    width: 0;
    height: 0;
    border: solid transparent;
    border-left-color: #333;
    border-width: 2px 0 2px 2px;
    vertical-align: middle;
}

.ec-base-tooltip ul.bullet li {
    line-height: 1.5em;
}

.ec-base-tooltip .txtEm {
    color: #000000;
}

.ec-base-tooltip .txtWarn {
    color: #f65b54;
}

.ec-base-tooltip.typeUpper {
    top: auto;
    bottom: 34px;
}

.ec-base-tooltip.typeUpper .edge {
    top: auto;
    bottom: -6px;
}

.ec-base-tooltip.typeUpper .edge:before {
    margin: 0 0 -5px;
    border-top-color: #565960;
    border-width: 5px 5px 0 5px;
}

.ec-base-tooltip.typeUpper .edge:after {
    left: 1px;
    margin: 0 0 -4px;
    border-top-color: #fff;
    border-width: 4px 4px 0 4px;
}

span.ec-base-help,
p.ec-base-help,
ul.ec-base-help li {
    margin: 2px 9px;
    padding: 1px 0 1px 20px;
    line-height: 1.2;
    /* background: url('//img.echosting.cafe24.com/skin/base/common/ico_info.gif') no-repeat 0 2px; */
}

div.ec-base-help {
    margin: 20px 0;
    border: 1px solid #d6d4d4;
    line-height: 18px;
    font-size: 13px;
}

div.ec-base-help>h2,
div.ec-base-help>h3 {
    padding: 9px 0 6px 10px;
    border-bottom: 1px solid #e8e7e7;
    color: #101010;
    font-size: 13px;
    background: #fbfbfb;
}

div.ec-base-help .inner {
    padding: 0 9px 12px;
}

div.ec-base-help h4 {
    margin: 22px 0 -4px;
    color: #404040;
    font-size: 12px;
    font-weight: normal;
}

div.ec-base-help h4:first-child {
    margin-top: 13px;
}

div.ec-base-help p {
    margin: 15px 0 0 10px;
    color: #707070;
}

div.ec-base-help ul,
div.ec-base-help ol {
    margin: 15px 0 0 11px;
}

div.ec-base-help li {
    color: #707070;
}

div.ec-base-help ol li {
    padding: 0 0 7px 25px;
    /* background: url('//img.echosting.cafe24.com/skin/base/common/ico_number.png') no-repeat; */
}

div.ec-base-help ol .item1 {
    background-position: -484px 0;
}

div.ec-base-help ol .item2 {
    background-position: -434px -100px;
}

div.ec-base-help ol .item3 {
    background-position: -384px -200px;
}

div.ec-base-help ol .item4 {
    background-position: -334px -300px;
}

div.ec-base-help ol .item5 {
    background-position: -284px -400px;
}

div.ec-base-help ol .item6 {
    background-position: -234px -500px;
}

div.ec-base-help ol .item7 {
    background-position: -184px -600px;
}

div.ec-base-help ol .item8 {
    background-position: -134px -700px;
}

div.ec-base-help ol .item9 {
    background-position: -84px -800px;
}

div.ec-base-help ol .item10 {
    background-position: -34px -900px;
}

div.ec-base-help ul li {
    padding: 0 0 0 11px;
    /* background: url('//img.echosting.cafe24.com/skin/base/common/ico_dash.gif') no-repeat 0 7px; */
}

.ec-base-help.typeDash li {
    padding: 0 0 0 11px;
    /* background: url("//img.echosting.cafe24.com/skin/base/common/ico_dash.gif") no-repeat 0 7px; */
}

.ec-base-tooltip .ec-base-help.typeDash li {
    margin: 2px 0;
}

div.ec-base-help.typeA {
    margin: 50px 0 0;
    border: 0;
    line-height: 20px;
}

div.ec-base-help.typeA>h2,
div.ec-base-help.typeA>h3 {
    padding: 0 0 20px;
    font-size: 20px;
    background: none;
    border: 0;
}

div.ec-base-help.typeA .inner {
    padding: 0x;
}

div.ec-base-help.typeA .inner {
    padding: 0;
}

div.ec-base-help.typeA .inner ol {
    margin: 0;
}

div.ec-base-help.typeA .inner ol li {
    background: none;
    padding: 2px 0 2px 10px;
    color: #adadad;
}

div.ec-base-help.typeA .inner ol li:before {
    content: "ㆍ";
    margin: 0 2px 0 -15px
}

div.ec-base-help.typeA .inner ol li,
div.ec-base-help.typeA .inner ol li strong {
    font-weight: normal;
}

div.ec-base-help.typeA h4 {
    margin: 22px 0 -4px;
    color: #404040;
    font-size: 12px;
    font-weight: normal;
}

div.ec-base-help.typeA h4:first-child {
    margin-top: 13px;
}

div.ec-base-help.typeA p {
    margin: 15px 0 0 10px;
    color: #707070;
}

.ec-base-product .title h2 {
    font-weight: normal;
    font-size: 20px;
    color: #2e2e2e;
    text-align: center;
}

.ec-base-product .title h3 {
    font-weight: normal;
    font-size: 20px;
    color: #2e2e2e;
    text-align: center;
}

.xans-product-listrecommend .title h3 {
    margin: 0 0 30px;
    font-size: 22px;
}

.xans-product-listnew .title h3 {
    margin: 0 0 30px;
    font-size: 22px;
}

.ec-base-product img {
    vertical-align: middle;
}

.ec-base-product a[href^='/product/detail.html']>img {
    border: 1px solid #ececec;
}

.ec-base-product .prdList {
    min-width: 756px;
    margin: 0 0;
    font-size: 0;
    line-height: 0;
}

.ec-base-product .prdList>li {
    position: relative;
    display: inline-block;
    margin: 0 0 40px;
    color: #757575;
    vertical-align: top;
    line-height: 1.3;
}

.ec-base-product .prdList .chk {
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    display: block;
    margin: 0 0 10px;
}

.ec-base-product .prdList .thumbnail {
    position: relative;
    margin: 0px 14px 10px;
    text-align: center;
    transition: box-shadow .25s ease;
}

.ec-base-product .prdList li:hover .thumbnail {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ec-base-product .prdList .thumbnail .prdImg {
    position: relative;
}

.ec-base-product .prdList .thumbnail a img {
    width: 100%;
    box-sizing: border-box;
}

.ec-base-product .prdList .thumbnail .wish {
    position: absolute;
    right: 3px;
    bottom: 4px;
    z-index: 1;
    cursor: pointer;
}

.ec-base-product .prdList .thumbnail .prdIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 50px;
}

.ec-base-product .prdList .thumbnail .promotion {
    float: left;
    margin: 0 0 4px;
    text-align: left;
}

.ec-base-product .prdList .thumbnail .promotion img {
    margin: 0 4px 0 0;
}

.ec-base-product .prdList .thumbnail .button {
    float: right;
    margin: 0 0 4px;
}

.ec-base-product .prdList .thumbnail .button img {
    margin: 0 2px 0 0;
    cursor: pointer;
}

.ec-base-product .prdList .thumbnail .button .option {
    display: inline;
    position: relative;
}

.ec-base-product .prdList .thumbnail .button .likeButton {
    display: inline-block;
    margin: 0 5px;
}

.ec-base-product .prdList .thumbnail .button .likeButton button {
    /* font-family: verdana; */
    color: #9a9a9a;
    letter-spacing: -1px;
    background: none;
}

.ec-base-product .prdList .thumbnail .button .likeButton button img {
    margin: 0;
    vertical-align: middle;
}

.ec-base-product .prdList .thumbnail .button .likeButton button strong {
    height: 16px;
    vertical-align: middle;
}

.ec-base-product .prdList .thumbnail .button .likeButton.selected button {
    color: #353535;
}

.ec-base-product .prdList .description {
    margin: 0 auto;
    padding: 14px 14px 0;
    font-size: 13px;
    text-align: left;
    position: relative;
}

.ec-base-product .prdList .description .name {
    display: block;
    text-align: left;
    font-weight: normal;
    padding: 0 0 5px;
}

.ec-base-product .prdList .description .name a {
    color: #2e2e2e;
    text-decoration: none;
}

.ec-base-product .prdList .description .mileage {
    display: block;
}

.ec-base-product .prdList .description span.grid {
    display: block;
}

.ec-base-product .prdList .description>.icon {
    margin: 15px 0 13px;
}

.ec-base-product .prdList .description>.icon:after {
    content: "";
    display: block;
    clear: both;
}

.ec-base-product .prdList .description>.icon img {
    max-width: 50px;
    margin-right: 5px;
}

.ec-base-product .prdList .description .discount_rate {
    font-size: 22px;
    display: none;
    z-index: 10;
    font-weight: bold;
    font-weight: 700;
    padding-left: 6px;
}

.ec-base-product .prdList .description .discount_rate:after {
    content: "%";
    font-size: 16px;
}

.ec-base-product ul.grid2>li {
    width: 50%;
}

.ec-base-product ul.grid3>li {
    width: 33.33%;
}

.ec-base-product ul.grid4>li {
    width: 25%;
}

.ec-base-product ul.grid5>li {
    width: 20%;
}

.ec-base-product ul.grid3>li .color {}

.ec-base-product ul.grid4>li .color {}

.ec-base-product ul.grid5>li .color {}

.ec-base-product ul.grid2 li:after {
    content: "";
    display: block;
    clear: both;
}

.ec-base-product ul.grid2 li .thumbnail {
    float: left;
    width: 220px;
    text-align: left;
    margin: 0;
}

.ec-base-product ul.grid2 li .description {
    float: left;
    width: 247px;
    padding: 0 20px;
}

.ec-base-product ul.grid2 li .description .chk {
    position: static;
    text-align: left;
}

.ec-base-product .spec {
    margin: 0;
}

.ec-base-product .spec li {
    text-align: left;
}

.ec-base-product .spec li.summary_desc {
    margin-bottom: 10px;
}

.ec-base-product .spec li.product_price * {
    vertical-align: middle !important;
}

.ec-base-product .spec li .title {
    font-weight: normal;
    vertical-align: top;
}

.ec-base-product .spec li .title span {
    vertical-align: top;
}

.ec-base-product .spec li .color {
    overflow: hidden;
    display: inline-block;
    margin: 10px 0 0 0;
}

.ec-base-product .spec li .chips {
    float: left;
    width: 11px;
    height: 11px;
    margin: 0 4px 4px 0;
    border-radius: 11px;
    border: 1px solid #e3e3e3;
    font-size: 0;
    line-height: 0;
}

.ec-base-product .discountPeriod {
    display: inline-block;
    z-index: 10;
    position: relative;
    width: 55px;
    height: 19px;
    vertical-align: middle;
}

.ec-base-product .layerDiscountPeriod {
    left: 50%;
    top: 26px;
    width: 247px;
    margin: 0 0 0 -124px;
}

.ec-base-product .layerDiscountPeriod strong.title {
    display: block;
    margin: 0 0 12px;
    padding: 0 35px 0 0;
    font-weight: bold;
    color: #2e2e2e;
}

.ec-base-product .layerDiscountPeriod .content p {
    margin: 2px 0 0;
    font-size: 11px;
    line-height: 16px;
    color: #757575;
    letter-spacing: -1px;
}

.ec-base-product .layerDiscountPeriod .content p strong {
    font-weight: normal;
    font-size: 12px;
    color: #2e2e2e;
}

.ec-base-product .layerDiscountPeriod .content p strong span {
    font-size: 11px;
}

.ec-base-product .prdList .shippingFee {
    position: relative;
    display: inline-block;
}

.ec-base-product .prdList .shippingFee .button {
    display: inline-block;
    float: none;
}

.ec-base-product .prdList .shippingFee .ec-base-tooltip {
    z-index: 11;
    display: block;
    margin: 0 0 0 -170px;
    width: 360px;
}

.ec-base-product .prdList .shippingFee .ec-base-tooltip table th {
    width: 40px;
}

.ec-base-product .prdList .shippingFee .ec-base-tooltip table th,
.ec-base-product .prdList .shippingFee .ec-base-tooltip table td {
    padding: 7px 10px 8px 10px;
}

.ec-base-product .btnTooltip {
    position: relative;
    display: inline-block;
}

.differentialShipping {
    display: none;
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 17px;
    z-index: 100;
    width: 350px;
    margin: 0 0 0 -176px;
}

.differentialShipping h3.title {
    margin: 0;
    height: 35px;
    padding: 0 35px 0 19px;
    border: 0;
    color: #fff;
    font-size: 14px;
    line-height: 35px;
    background: #495164;
}

.differentialShipping .content {
    padding: 12px 18px 14px 18px;
    border: 1px solid #757575;
    border-top: 0;
    text-align: center;
    background: #fff;
}

.differentialShipping .close {
    position: absolute;
    right: 14px;
    top: 12px;
}

.differentialShipping .close img {
    cursor: pointer;
}

.differentialShipping .content ul {
    color: #757575;
    line-height: 25px;
}

.differentialShipping .content li {
    overflow: hidden;
}

.differentialShipping .content li strong {
    float: left;
    font-weight: normal;
}

.differentialShipping .content li span {
    float: right;
    color: #2e2e2e;
}

.differentialShipping .content .info {
    margin: 7px 0 0;
    padding: 7px 0 0;
    border-top: 1px dotted #b0b1b3;
    color: #80aeef;
    text-align: right;
}

.ec-product-bgLT {
    background-position: left top;
}

.ec-product-bgLC {
    background-position: left center;
}

.ec-product-bgLB {
    background-position: left bottom;
}

.ec-product-bgRT {
    background-position: right top;
}

.ec-product-bgRC {
    background-position: right center;
}

.ec-product-bgRB {
    background-position: right bottom;
}

.ec-product-bgCT {
    background-position: center top;
}

.ec-product-bgCC {
    background-position: center center;
}

.ec-product-bgCB {
    background-position: center bottom;
}

.prdList .iconarea {
    position: absolute;
    bottom: 0px;
    left: 50%;
    height: 0;
    overflow: hidden;
    transition: height .25s ease;
    text-align: center;
    margin-bottom: 18px;
    transform: translateX(-50%);
    width: auto;
    min-width: 120px;
    max-width: 150px;
}

.prdList>li:hover .iconarea {
    height: 60px;
}

.prdList .iconarea>span {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    height: 60px;
    width: 60px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #eee;
    margin: 0 6px;
    background-color: #fff;
    box-sizing: border-box;
}

.prdList .iconarea span.ico1 {
    /* background: #fff url(/web/upload/wp/167417/p__ico_addcart.png) no-repeat center center; */
}

.prdList .iconarea span.ico1 img {
    width: 100%;
    height: 100%;
    opacity: 0;
}

.prdList .iconarea span.ico2 {
    background: #fff;
}

.prdList .iconarea span.ico2 img {
    margin-top: 16px;
}

ul.prdList .likeButton {
    position: absolute;
    bottom: 0px;
    left: 0;
    padding: .5rem;
    z-index: 3;
    transition: opacity .3s ease, bottom .3s ease;
    cursor: pointer;
}

ul.prdList .likeButton button {
    background: none;
    font-size: 10px;
    border: 0;
}

ul.prdList .likeButton button:before {
    content: "LIKE";
    margin-right: 5px;
}

ul.prdList .likeButton img {
    display: none;
}

.ec-base-paginate {
    margin: 20px 0;
    text-align: center;
    font-size: 0;
    line-height: 0;
}

.ec-base-paginate ol {
    display: inline-block;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;

    margin: 0px 10px;
}

.ec-base-paginate li {
    display: inline-block;
    font-size: 17px;
    color: #757575;
    vertical-align: top;

    border: 0
}

.ec-base-paginate li:first-child {
    margin-left: 0;
}

.ec-base-paginate img {
    vertical-align: top;
    height: 11px;
}

.ec-base-paginate li a {
    display: block;
    width: auto;
    padding: 9px 4px;
    margin: 0 7px;
    font-weight: normal;
    color: #939393;
    line-height: 14px;
    background: #fff;
    font-size: 14px;
}

.ec-base-paginate li a:hover {
    text-decoration: none;
    color: #222;
}

.ec-base-paginate li a.this {
    padding-bottom: 8px;
    border-bottom: 2px solid #333;
    color: #333;
}

.ec-base-paginate a.nolink {
    cursor: default;
}

.ec-base-paginate>a {
    /* background: url(/_wp/img/bt_page_prev.svg) no-repeat center / auto 11px; */
    display: inline-block;
    vertical-align: middle;
    padding: 15px 8px;
}

.ec-base-paginate>ol+a {
    /* background: url(/_wp/img/bt_page_next.svg) no-repeat center / auto 11px; */
}

.ec-base-paginate>a img {
    opacity: 0;
    width: 0;
    height: 0;
}

.ec-base-paginate>a.first {
    /* background: url(/_wp/img/bt_page_first.svg) no-repeat center / auto 11px; */
    padding: 15px 18px;
}

.ec-base-paginate>a.last {
    /* background: url(/_wp/img/bt_page_last.svg) no-repeat center / auto 11px; */
    padding: 15px 18px;
}

.ec-base-paginate.typeA a.first,
.ec-base-paginate.typeA a.last {
    display: none;
}

.ec-base-paginate.typeSub ol {
    margin: 0 15px;
    vertical-align: middle;
}

.ec-base-paginate.typeSub li {
    font-size: 12px;
    border: 0;
}

.ec-base-paginate.typeSub img {
    vertical-align: middle;
}

.ec-base-paginate.typeSub li a {
    width: auto;
    margin: 0 5px 0;
    padding: 2px;
    color: #353535;
    background: none;
}

.ec-base-paginate.typeSub li a.this {
    color: #000000;
    text-decoration: underline;
    border: 0;
}

.ec-base-paginate.typeSub .first {
    margin-right: 3px;
}

.ec-base-paginate.typeSub .last {
    margin-left: 3px;
}

.ec-base-tab {
    position: relative;
}

.ec-base-tab .menu {
    margin: 0 0 20px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.ec-base-tab .menu:after {
    content: "";
    display: block;
    clear: both;
}

.ec-base-tab .menu li {
    float: left;
}

.ec-base-tab .menu li a {
    display: block;
    min-width: 187px;
    margin: 0 0 0 -1px;
    padding: 15px 5px 13px;
    color: #707070;
    text-decoration: none;
    font-size: 14px;
    outline: 0;
    text-align: center;
    background: #fff;
    border: 1px solid #dbdbdb;
}

.ec-base-tab .menu li:first-child a {
    margin-left: 0;
}

.ec-base-tab .menu li.selected a {
    position: relative;
    top: 0;
    left: 0;
    border: 1px solid #2a2a2a;
    color: #fff;
    font-weight: bold;
    background: #2a2a2a;
}

.ec-base-tab .menu li.selected:first-child a {
    left: 0;
}

.ec-base-tab>.right {
    position: absolute;
    top: 8px;
    right: 10px;
    line-height: 24px;
    color: #909090;
}

.ec-base-tab.typeLight .menu li.selected a {
    margin: 0 0 -2px;
    border-bottom: 1px solid #fff;
    color: #404040;
    background: #fff;
}

.ec-base-tab[class*="grid"] .menu {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.ec-base-tab[class*="grid"] .menu li {
    float: none;
    display: table-cell;
    vertical-align: middle;
    border-bottom: 1px solid #ddd;
}

.ec-base-tab[class*="grid"] .menu li a {
    display: inline-block;
    width: 100%;
    min-width: 0;
    margin: 0;
    padding: 11px 0;
    border: 0;
}

.ec-base-tab[class*="grid"] .menu li.selected {
    border-bottom: 1px solid #000;
}

.ec-base-tab[class*="grid"] .menu li:first-child.selected {
    right: 0;
}

.ec-base-tab[class*="grid"] .menu li.selected a {
    position: static;
    background: none;
    color: #000;
}

.ec-base-tab.grid2 .menu li {
    width: 50.5%;
}

.ec-base-tab.grid3 .menu li {
    width: 34%;
}

.ec-base-tab.grid4 .menu li {
    width: 25.5%;
}

.ec-base-tab.grid5 .menu li {
    width: 20.5%;
}

.ec-base-tab.grid6 .menu li {
    width: 17%;
}

.ec-base-tab.grid7 .menu li {
    width: 14.8%;
}

.ec-base-tab.grid7 .menu li:first-child {
}

.ec-base-tab.gFlex .menu {
    display: flex;
    border-right: 0;
}

.ec-base-tab.gFlex .menu:after {
    display: none;
}

.ec-base-tab.gFlex .menu li {
    display: flex;
    align-items: center;
    float: none;
    flex: 1;
    position: relative;
}

.ec-base-tab.gFlex .menu li:last-child {}

.ec-base-tab.gFlex .menu li a {
    width: 100%;
    min-width: 0;
    border-right: 0;
    word-break: break-all;
}

.ec-base-tab.gFlex .menu li a:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.ec-base-tab.gFlex .menu li.selected {
    border: 1px solid #2a2a2a;
    border-bottom: 0;
    background: #2a2a2a;
}

.ec-base-tab.gFlex .menu li.selected a {
    left: 0;
    margin: 0 0 -1px -1px;
    background: #2a2a2a;
    border: 0;
    position: initial;
}

[class^='btnNormal'],
a[class^='btnNormal'] {
    display: inline-block;
    box-sizing: border-box;
    padding: 2px 8px;
    border: 1px solid #d1d1d1;
    font-size: 13px;
    line-height: 24px;
    font-weight: normal;
    text-decoration: none;
    vertical-align: middle;
    word-spacing: -0.5px;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    color: #222;
    background-color: #fff;
}

[class^='btnSubmit'],
a[class^='btnSubmit'] {
    display: inline-block;
    box-sizing: border-box;
    padding: 2px 8px;
    border: 1px solid transparent;
    font-size: 13px;
    line-height: 24px;
    font-weight: normal;
    text-decoration: none;
    vertical-align: middle;
    word-spacing: -0.5px;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    color: #fff;
    background-color: #2a2a2a;
}

[class^='btnEm'],
a[class^='btnEm'] {
    display: inline-block;
    box-sizing: border-box;
    padding: 2px 8px;
    border: 1px solid transparent;
    font-size: 13px;
    line-height: 24px;
    font-weight: normal;
    text-decoration: none;
    vertical-align: middle;
    word-spacing: -0.5px;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    color: #fff;
    background-color: #84868b;
}

[class^='btnBasic'],
a[class^='btnBasic'] {
    display: inline-block;
    box-sizing: border-box;
    padding: 2px 8px;
    border: 1px solid #d1d1d1;
    font-size: 13px;
    line-height: 24px;
    font-weight: normal;
    text-decoration: none;
    vertical-align: middle;
    word-spacing: -0.5px;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    color: #222;
    background-color: #f0f0f0;
}

[class^='btnNormal']:not(.disabled):hover {
    background-color: #f3f3f3;
}

[class^='btnSubmit']:not(.disabled):hover {
    background-color: #1a1a1a;
}

[class^='btnEm']:not(.disabled):hover {
    background-color: #77797d;
}

[class^='btnBasic']:not(.disabled):hover {
    background-color: #e5e5e5;
}

[class^='btnNormal'].disabled {
    border-color: #e3e3e3;
    color: #999;
}

[class^='btnSubmit'].disabled {
    background-color: #9297a2;
    color: #f0f0f0;
}

[class^='btnEm'].disabled {
    background-color: #b5b6b9;
    color: #f0f0f0;
}

[class^='btnBasic'].disabled {
    color: #999;
}

[class^='btn'].sizeS {
    padding: 6px 8px;
}

[class^='btn'].sizeM {
    padding: 14px 26px;
    font-size: 17px;
}

[class^='btn'].sizeL {
    padding: 14px 26px;
    min-width: 80px;
    font-weight: bold;
}

[class^='btn'][class*='Fix'] {
    width: 72px;
    word-break: keep-all;
    word-wrap: break-word;
}

[class^='btn'][class*='Fix'].sizeS {
    width: 96px;
}

[class^='btn'][class*='Fix'].sizeM {
    width: 160px;
    padding-left: 8px;
    padding-right: 8px;
}

[class^='btn'][class*='Fix'].sizeL {
    width: 190px;
    padding-left: 8px;
    padding-right: 8px;
}

a.btnLink {
    color: #222;
}

a.btnLink:hover {
    text-decoration: none;
    -webkit-box-shadow: 0 1px 0 0 #222;
    -moz-box-shadow: 0 1px 0 0 #222;
    box-shadow: 0 1px 0 0 #222;
}

a.btnLink:after {
    content: "";
    display: inline-block;
    margin: -2px -2px 0 3px;
    vertical-align: middle;
    border: 0 0 0 10px;
    border-style: solid;
    border-color: transparent transparent transparent #77797d;
}

[class^='btn'] i {
    display: none \0/IE8;
}

[class^='btn'] .icoArrow {
    display: inline-block;
    width: 3px;
    height: 3px;
    margin: -2px 1px 0 1px;
    border: 2px solid #77797d;
    border-width: 0 2px 2px 0;
    vertical-align: middle;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}

[class^='btnSubmit'] .icoArrow,
[class^='btnEm'] .icoArrow {
    border-color: #f0f0f0;
}

[class^='btn'] .icoDelete {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: -2px 2px 0 0;
    vertical-align: middle;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

[class^='btn'] .icoDelete:before {
    content: "";
    position: absolute;
    top: 0;
    right: 4px;
    width: 2px;
    height: 10px;
    background: #77797d;
}

[class^='btn'] .icoDelete:after {
    content: "";
    position: absolute;
    top: 4px;
    right: 0;
    width: 10px;
    height: 2px;
    background: #77797d;
}

[class^='btnSubmit'] .icoDelete:before,
[class^='btnSubmit'] .icoDelete:after,
[class^='btnEm'] .icoDelete:before,
[class^='btnEm'] .icoDelete:after {
    background: #f0f0f0;
}

[class^='btn'] .icoAdd,
[class^='btn'] .icoRemove {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: -2px 2px 0 0;
    vertical-align: middle;
}

[class^='btn'] .icoAdd:before,
[class^='btn'] .icoAdd:after,
[class^='btn'] .icoRemove:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background: #77797d;
}

[class^='btn'] .icoAdd:before,
[class^='btn'] .icoRemove:before {
    height: 2px;
    margin: -1px 0 0 -4px;
}

[class^='btn'] .icoAdd:after {
    width: 2px;
    margin: -4px 0 0 -1px;
}

[class^='btn'] img {
    margin: -2px 1px 0;
    vertical-align: middle;
}

.btnLogin,
a.btnLogin {
    display: inline-block;
    box-sizing: border-box;
    width: 100px;
    height: 70px;
    line-height: 70px;
    border-radius: 2px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    background-color: #2a2a2a;
}

.btnLogin:hover {
    background-color: #1a1a1a;
}

.btnAgree,
a.btnAgree {
    display: inline-block;
    box-sizing: border-box;
    width: 80px;
    height: 70px;
    line-height: 70px;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #222;
    text-decoration: none;
    background-color: #fff;
}

.btnAgree:hover {
    background-color: #f3f3f3;
}

.btnToggle {
    display: inline-block;
    font-size: 0;
    line-height: 0;
}

.btnToggle button {
    display: inline-block;
    box-sizing: border-box;
    border: 1px solid #d1d1d1;
    margin: 0 -1px 0 0;
    padding: 0 7px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 12px;
    outline: 0;
    text-decoration: none;
    color: #222;
    background: #fff;
}

.btnToggle button:first-child {
    border-radius: 2px 0 0 2px;
}

.btnToggle button:last-child {
    border-radius: 0 2px 2px 0;
}

.btnToggle button.selected {
    position: relative;
    border: 1px solid #84868b;
    color: #fff;
    background: #84868b;
}

.ec-base-button {
    padding: 10px 0;
    text-align: center;
}

.ec-base-button.justify {
    position: relative;
}

.ec-base-button:after {
    display: block;
    content: "";
    clear: both;
}

.ec-base-button .gLeft {
    float: left;
    text-align: left;
}

.ec-base-button .gRight {
    float: right;
    text-align: right;
}

.ec-base-button.justify .gLeft {
    position: absolute;
    left: 0;
}

.ec-base-button.justify .gRight {
    position: absolute;
    right: 0;
}

.ec-base-button .text {
    margin: 0 6px 0 10px;
    color: #353535;
    line-height: 24px;
}

.ec-base-button.typeBorder {
    margin-top: -1px;
    padding: 10px 20px;
    border: 1px solid #d7d5d5;
}

.ec-base-button.typeBG {
    padding: 10px 0;
}

.ec-base-button[class*="gColumn"] {
    margin: 0 auto;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
}

.ec-base-button[class*="gColumn"]:after {
    display: none;
}

.ec-base-button[class*="gColumn"] [class^='btn'] {
    margin: 0 2px;
    padding-left: 8px;
    padding-right: 8px;
    word-break: keep-all;
    word-wrap: break-word;
    white-space: normal;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
}

.ec-base-button[class*="gColumn"] [class^='btn'][class*='Fix'] {
    -webkit-flex: none;
    -moz-flex: none;
    -ms-flex: none;
    flex: none;
}

.ec-base-button[class*="gColumn"] .gFlex2 {
    -webkit-flex: 2;
    -moz-flex: 2;
    -ms-flex: 2;
    flex: 2;
}

.ec-base-button[class*="gColumn"] .gFlex3 {
    -webkit-flex: 3;
    -moz-flex: 3;
    -ms-flex: 3;
    flex: 3;
}

.ec-base-button[class*="gColumn"] .gFlex4 {
    -webkit-flex: 4;
    -moz-flex: 4;
    -ms-flex: 4;
    flex: 4;
}

[class^='btn'].bt_typeA {
    background: #222;
    border: 1px solid #222;
    color: #fff;
    font-size: 20px;
    font-weight: normal;
    text-decoration: none;
    transition: background .25s ease;
    text-align: center;
}

[class^='btn'].bt_typeA:hover {
    background: #222 !important;
}

[class^='btn'].bt_typeB {
    background: #fff;
    border: 1px solid #222;
    color: #222;
    font-size: 20px;
    font-weight: normal;
    text-decoration: none;
    transition: border .25s ease, color .25s ease, background .25s ease;
    text-align: center;
}

[class^='btn'].bt_typeB:hover {
    background: #fff !important;
    border: 1px solid #007dcc !important;
    color: #007dcc !important;
}

[class^='btn'].bt_typeC {
    background: #fff;
    border: 1px solid #dbdbdb;
    color: #222222;
    font-size: 20px;
    font-weight: normal;
    text-decoration: none;
    transition: border .25s ease, color .25s ease, background .25s ease;
    text-align: center;
}

[class^='btn'].bt_typeC:hover {
    background: #f9f9f9 !important;
    border: 1px solid #dbdbdb !important;
    color: #222222 !important;
}

.ec-base-box {
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #e8e8e8;
    color: #404040;
}

.ec-base-box.gHalf {
    position: relative;
    padding: 20px 0;
}

.ec-base-box.gHalf:before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    content: "";
    width: 1px;
    height: 100%;
    background-color: #e6e6e6;
}

.ec-base-box.typeBg {
    background-color: #fbfafa;
}

.ec-base-box.typeThin {
    border-width: 1px;
    border-color: #d7d5d5;
}

.ec-base-box.typeThin h3.boxTitle {
    margin: 0 0 20px;
    font-size: 20px;
    text-align: center;
}

.ec-base-box.typeThinBg {
    border-width: 1px;
    border-color: #d7d5d5;
    background-color: #fbfafa;
}

.ec-base-box.center {
    text-align: center;
}

.ec-base-box.typeThinBg>.agree {
    padding: 20px;
    border: 1px solid #e6e6e6;
    background: #fff;
}

.ec-base-box.typeThinBg>.agree p {
    padding: 0 0 17px;
}

.ec-base-box.typeMember {
    padding: 0;
}

.ec-base-box.typeMember .information {
    display: table;
    table-layout: fixed;
    padding: 15px 0;
    width: 100%;
    box-sizing: border-box;
}

.ec-base-box.typeMember .information>.title,
.ec-base-box.typeMember .information>.thumbnail {
    display: table-cell;
    padding: 0 15px;
    width: 70px;
    text-align: center;
    vertical-align: middle;
}

.ec-base-box.typeMember .information>.title {
    vertical-align: middle;
}

.ec-base-box.typeMember .information>.thumbnail img {
    max-width: 70px;
}

.ec-base-box.typeMember .information .description {
    display: table-cell;
    padding: 0 10px;
    width: auto;
    line-height: 1.5em;
    border-left: 1px solid #e8e8e8;
    vertical-align: middle;
}

.ec-base-box.typeMember.gMessage {
    border-width: 1px;
    border-color: #d7d5d5;
}

.ec-base-box .message {
    display: block;
    padding: 10px 0 10px 35px;
    border-bottom: 1px solid #e8e7e7;
    /* background: #fbfbfb url("//img.echosting.cafe24.com/skin/base/common/ico_info.gif") no-repeat 10px center; */
}

.ec-base-box.typeProduct {
    display: table;
    table-layout: fixed;
    padding: 15px 0;
    width: 100%;
    box-sizing: border-box;
}

.ec-base-box.typeProduct .thumbnail,
.ec-base-box.typeProduct .information {
    display: table-cell;
    padding: 0 20px;
    vertical-align: middle;
}

.ec-base-box.typeProduct .thumbnail {
    width: 100px;
}

.ec-base-box.typeProduct .thumbnail img {
    max-width: 90px;
    border: 1px solid #d7d5d5;
}

.ec-base-box.typeProduct .information {
    padding-left: 0;
}

.ec-base-table table {
    position: relative;
    margin: 10px 0 0;
    border: 1px solid #dbdbdb;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    color: #fff;
    line-height: 1.5;
}

.ec-base-table.gLayoutFixed table {
    table-layout: fixed;
}

.ec-base-table.gLayoutFixed .gLayoutAuto table {
    table-layout: auto;
}

.ec-base-table table:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 1px;
    background: #dbdbdb;
}

.ec-base-table thead th {
    padding: 13px 0 12px;
    border-bottom: 1px solid #f2f2f2;
    color: #353535;
    vertical-align: middle;
    font-weight: normal;
    font-size: 14px;
    border-right-width: 0;
    background: #f7f7f7;
}

.ec-base-table tbody th {
    padding: 13px 0 12px;
    border: 1px solid #f2f2f2;
    border-bottom-width: 0;
    color: #353535;
    text-align: left;
    font-weight: normal;
    border-right-width: 0;
}

.ec-base-table th {
    word-break: break-all;
    word-wrap: break-word;
}

.ec-base-table th:first-child {
    border-left: 0;
}

.ec-base-table td {
    padding: 11px 10px 10px;
    border-top: 1px solid #f2f2f2;
    color: #353535;
    vertical-align: middle;
    word-break: break-all;
    word-wrap: break-word;
    font-size: 14px;
}

.ec-base-table td.clear {
    padding: 0 !important;
    border: 0 !important;
}

.ec-base-table.typeWrite td {}

.ec-base-table.typeWrite img {
    max-width: 100% !important;
    height: auto !important;
}

.ec-base-table.typeList table {
    border-top: 1px solid #dbdbdb;
}

.ec-base-table.typeList table:before {
    display: none;
}

.ec-base-table.typeList td {
    padding: 13px 10px 12px;
}

.ec-base-table.typeList tfoot td {
    padding: 13px 10px 12px;
    background: #fbfafa;
}

.ec-base-table .message {
    border: 1px solid #dbdbdb;
    border-left: 0;
    border-right: 0;
    padding: 50px 0;
    text-align: center;
    font-size: 14px;
}

.ec-base-table table+.message {
    margin: -1px 0 0;
}

.ec-base-table table td.message {
    padding: 50px 0;
}

.ec-base-table .scroll .message {
    border: 0;
}

.ec-base-table.typeList .head td {
    padding: 11px 0 10px;
    border-bottom: 1px solid #f2f2f2;
    color: #353535;
    vertical-align: middle;
    text-align: center;
    background: #fbfafa;
}

.ec-base-table.typeList .head td:first-child {
    border-left: 0;
}

.ec-base-table.typeList .scroll {
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 185px;
    min-height: 100px;
    border: 1px solid #dbdbdb;
    border-left: 0;
    border-right: 0;
    border-top-width: 0;
    margin-top: -1px;
}

.ec-base-table.typeList .scroll table {
    border: 0;
    margin: 0;
}

.ec-base-table.typeList .scroll table:before {
    display: none;
}

.ec-base-table.typeList .scroll .message {
    border: 0;
}

.ec-base-table thead.blind,
.ec-base-table thead.blind th {
    display: none;
}

.ec-base-table thead.blind+tbody tr:first-child th,
.ec-base-table thead.blind+tbody tr:first-child td {
    border-top-width: 0;
}

.ec-base-table.typeList.gLine table {
    border-width: 0;
    border-bottom-width: 1px;
}

.ec-base-table.typeClear table:before {
    display: none;
}

.ec-base-table.typeClear table,
.ec-base-table.typeClear th,
.ec-base-table.typeClear td {
    border: 0 none;
    background: none;
}

.ec-base-table.typeClear th {
    font-weight: bold;
}

.ec-base-table.gBorder td {}

.ec-base-table.gBorder td:first-child {
    border-left: 0;
}

.ec-base-table.gBorder td.gClearLine {
    border-left: 0;
}

.ec-base-table.typeList.gBorder tbody td {
    border-color: #eee;
}

.ec-base-table .left {
    text-align: left;
}

.ec-base-table .center {
    text-align: center;
}

.ec-base-table .right {
    text-align: right;
}

.ec-base-table.typeList .center td,
.ec-base-table.typeList td.center {
    padding-left: 0;
    padding-right: 0;
}

.ec-base-table.typeList .center td.left {
    padding-left: 10px;
}

.ec-base-table.typeList .center td.right {
    padding-right: 10px;
}

.ec-base-table .top th,
.ec-base-table th.top,
.ec-base-table .top td,
.ec-base-table td.top {
    vertical-align: top;
}

.ec-base-table .middle th,
.ec-base-table th.middle,
.ec-base-table .middle td,
.ec-base-table td.middle {
    vertical-align: middle;
}

.ec-base-table img {
    vertical-align: middle;
}

.ec-base-table .ec-address {
    margin: -9px -10px -7px;
}

.ec-base-table .ec-address>li {
    padding: 8px 10px 7px;
    border-top: 0px solid #dfdfdf;
}

.ec-base-table .ec-address>li input[type="text"] {
    width: 373px;
}

.ec-base-table .ec-address>li select {
    width: 383px;
}

.ec-base-table .ec-address .ec-base-help {
    margin-left: 0;
}

.ec-base-table .ec-address .ec-address-area select {
    display: block;
}

.ec-base-table .ec-address .ec-address-area select:not(:first-child) {
    margin-top: 6px;
}

.ec-base-table .ec-address .ec-address-zipcode input[type="text"] {
    width: 80px;
    margin-right: 3px;
}

.ec-base-table .ec-address .ec-address-zipcode .btnBasic {
    margin-right: 4px;
}

@media print {
    .ec-base-table table:before {
        display: none;
    }
}

.ec-base-table.typeA table {
    border: 0;
    font-size: 14px;
}

.ec-base-table.typeA thead th {
    border: 0;
    border-top: 1px solid #888;
    border-bottom: 1px solid #888;
    background: #fff;
}

.ec-base-table.typeA td {
    border: 0;
    border-bottom: 1px solid #ddd;
    padding: 15px 10px;
}

.ec-base-desc {
    overflow: hidden;
    text-align: left;
}

.ec-base-desc:after {
    content: "";
    display: block;
    clear: both;
}

.ec-base-desc dt,
.ec-base-desc .term {
    float: left;
    width: 100px;
    margin: 0 0 2px;
    padding: 0 4px 0 0;
    line-height: 22px;
    -webkit-box-sizing: border-box;
    -moz-webkit-box: border-box;
    box-sizing: border-box;
    font-weight: normal;
}

.ec-base-desc dd,
.ec-base-desc .desc {
    display: block;
    margin: 0 0 2px;
    padding: 0 5px 0 100px;
    min-height: 22px;
    line-height: 22px;
    word-wrap: break-word;
    word-break: break-all;
}

.ec-base-desc dd:after,
.ec-base-desc .desc:after {
    content: "";
    display: block;
    clear: left;
}

.ec-base-desc.gSmall dt,
.ec-base-desc.gSmall .term {
    width: 30%;
}

.ec-base-desc.gSmall dd,
.ec-base-desc.gSmall .desc {
    padding-left: 30%;
}

.ec-base-desc.gMedium dt,
.ec-base-desc.gMedium .term {
    width: 40%;
}

.ec-base-desc.gMedium dd,
.ec-base-desc.gMedium .desc {
    padding-left: 40%;
}

.ec-base-desc.gLarge dt,
.ec-base-desc.gLarge .term {
    width: 50%;
}

.ec-base-desc.gLarge dd,
.ec-base-desc.gLarge .desc {
    padding: 0 0 0 50%;
}

.ec-base-desc.centerDT dt,
.ec-base-desc.centerDD dd,
.ec-base-desc.centerDT .term,
.ec-base-desc.centerDD .desc,
.ec-base-desc.center,
.ec-base-desc .center {
    text-align: center;
}

.ec-base-desc.rightDT dt,
.ec-base-desc.rightDD dd,
.ec-base-desc.rightDT .term,
.ec-base-desc.rightDD .desc,
.ec-base-desc.right,
.ec-base-desc .right {
    text-align: right;
}

.ec-base-desc.rightDD dd,
.ec-base-desc.rightDD .desc {
    padding-right: 0;
}

.ec-base-desc.typeBullet dt,
.ec-base-desc.typeBullet .term {
    padding: 0 4px 0 10px;
    /* background: url("//img.echosting.cafe24.com/skin/base/common/ico_arrow.png") no-repeat 0 7px; */
}

.ec-base-desc.typeDot dt,
.ec-base-desc.typeDot .term {
    position: relative;
    padding: 0 4px 0 10px;
}

.ec-base-desc.typeDot dt:before,
.ec-base-desc.typeDot .term:before {
    position: absolute;
    top: 50%;
    left: 0;
    content: "";
    display: block;
    width: 2px;
    height: 2px;
    margin: -2px 0 0 0;
    background: #383838;
}

li {
    list-style: none;
}

table {
    width: 100%;
    border: 0;
    border-spacing: 0;
    border-collapse: collapse;
}

caption {
    display: none;
}

h1,
h3 {
    margin: 0;
}

.ec-base-layer {
    position: absolute;
    z-index: 100;
    border: 1px solid #757575;
    background: #fff;
    box-sizing: border-box;
}

.ec-base-layer .header {
    padding: 7px 35px 7px 19px;
    color: #fff;
    background: #222222;
}

.ec-base-layer .header>h1,
.ec-base-layer .header>h3 {
    font-size: 14px;
    line-height: 1.5;
}

.ec-base-layer .content {
    padding: 20px;
    font-size: 12px;
}

.ec-base-layer div.ec-base-help {
    margin-top: 0;
}

.ec-base-layer .ec-base-button {
    padding: 9px;
    border-top: 1px solid #d7d5d5;
    text-align: center;
}

.ec-base-layer .close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 11px 20px;
    cursor: pointer;
}

#popup .ec-base-layer {
    position: relative;
}

#popup .ec-base-layer .header {
    padding: 12px 35px 12px 19px;
}

#popup .ec-base-layer .close {
    top: 5px;
}

.ec-base-layer .content>.ec-base-table table:first-child {
    margin-top: 0;
}

.ec-base-layer .ec-base-table table {
    line-height: 1.4;
}

.ec-base-layer .ec-base-table thead th {
    padding: 9px 0 8px;
}

.ec-base-layer .ec-base-table tbody th {
    padding: 9px 0 8px 10px;
}

.ec-base-layer .ec-base-table td {
    padding: 9px 10px 8px;
}

.ec-base-layer .ec-base-table.typeWrite td {
    padding: 5px 10px 4px;
}

.ec-base-layer .ec-base-table.typeList tfoot td {
    padding: 10px 10px 12px;
}

.ec-base-layer .ec-base-table.typeBorder tfoot td {
    padding: 10px 10px 12px;
}

.ec-base-layer .ec-base-table .ec-address {
    margin: -6px -10px -4px;
}

.ec-base-layer .ec-base-table .ec-address>li {
    padding: 5px 10px 4px;
}

.xans-layout-multishopshipping {
    display: none;
}

.xans-layout-multishopshipping .worldshipLayer {
    top: 303px;
    left: 50%;
    width: 448px;
    margin: 0 0 0 -225px;
}

.xans-layout-multishopshipping .worldshipLayer .content {
    padding: 153px 20px 30px;
    line-height: 1.8;
    /* background: url("//img.echosting.cafe24.com/skin/base/link/bg_worldship.gif") center 21px no-repeat; */
}

.xans-layout-multishopshipping .worldshipLayer .content .desc {
    display: block;
    margin: 4px 0 14px;
}

.xans-layout-multishopshipping .worldshipLayer .select {
    margin: 10px 0 0;
}

.xans-layout-multishopshipping .worldshipLayer .select select {
    width: 100%;
}

.xans-layout-conversionpc {
    background: #fff;
    padding: 45px;
    text-align: center;
}

.xans-layout-conversionpc a {
    margin: 20px;
    padding: 20px 40px 20px 110px;
    font-size: 68px;
    color: #63666e;
    text-decoration: none;
    text-align: center;
    border: 4px solid #9d9db0;
    border-radius: 10px;
    /* background: url("//img.echosting.cafe24.com/skin/base/layout/ico_mobile.png") no-repeat 40px 50%; */
}

.dp_sample {
    display: none !important;
}

.ec-base-product .prdList .description .discount_rate {
    color: #44bbb3;
}

#header .top-member .bb {
    background-color: #44bbb3;
}

#header .top-member .bb:after {
    border: 3px solid #44bbb3;
}

#header .xans-layout-searchheader .xans-search-hotkeyword a {
    color: #44bbb3;
}

.cate-wrap .bs>a:before,
#category>ul>li>a:before,
#header .comm>ul>li>a:before {
    background-color: #44bbb3
}

.mypage_leftmenu_wrap ul li.active a {
    color: #44bbb3;
}

.xans-product-detail .xans-product-action .btnSubmit {
    background-color: #44bbb3;
}

#footer {
    background-color: #44bbb3;
}

#contents {
    padding: 0 0 70px;
    min-height: 700px;
}

.clear {
    clear: both;
}

.site-wrap {
    position: relative;
    width: 100%;
    margin: 0 auto;
}

select::-ms-value {
    background: none;
    color: #000;
}

.selectbox {
    /* background: #fff url("/web/upload/wp/167417/p__option_select.svg") no-repeat 95% 50% / 12px; */
    overflow: hidden;
    background-position: -webkit-calc(100% - 10px) center;
    background-position: right 10px center;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #ddd;
}

.selectbox select::-ms-expand {
    display: none;
}

.selectbox select {
    border: 0;
    width: 100%;
    /* width: 106% \0/IE8; */
    border: 0 \0/IE8;
    box-shadow: none;
    background: transparent;
    background-image: none;
    visibility: visible;
    padding: 0px 30px 0 5px;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
}

.selectbox select::focus {
    outline: none;
}

.path {
    overflow: hidden;
    height: 0;
    line-height: 30px;
    font-size: 0;
    line-height: 0;
}

.path span {
    overflow: hidden;
    position: absolute;
    width: 0;
    height: 0;
    white-space: nowrap;
    text-indent: 100%;
}

.path ol {
    float: right;
}

.path li {
    float: left;
    padding: 0 0 0 12px;
    margin: 0 0 0 8px;
    color: #757575;
    /* background: url("//img.echosting.cafe24.com/skin/base/layout/ico_path.gif") no-repeat 0 10px; */
}

.path li:first-child {
    background: none;
}

.path li a {
    color: #757575;
}

.path li strong,
.path li strong a {
    color: #2e2e2e;
    font-weight: normal;
}

.titleArea {
    min-height: 30px;
    margin: 50px 0 20px;
    text-align: center;
}

.titleArea h2 {
    display: inline-block;
    color: #2e2e2e;
    font-size: 25px;
}

.titleArea ul {
    padding: 5px 0;
    color: #939393;
    line-height: 18px;
}

.titleArea ul li {
    position: relative;
    padding: 0 0 0 9px;
}

.titleArea ul li:before {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    content: "";
    width: 2px;
    height: 2px;
    margin: -2px 0 0 0;
    background: #939393;
}

.boardMain {
    overflow: hidden;
}

.boardMain td img {
    vertical-align: middle;
    margin: 1px;
}

.boardMain .board {
    position: relative;
    margin: 0 0 65px;
}

.boardMain .leftArea {
    float: left;
    width: 50%;
}

.boardMain .leftArea .board {
    margin: 0 23px 65px 0;
}

.boardMain .rightArea {
    float: right;
    width: 50%;
}

.boardMain .rightArea .board {
    margin: 0 0 65px 23px;
}

.boardMain .board h3 {
    margin: 0 0 13px;
    height: 28px;
    font-size: 16px;
    color: #2e2e2e;
    border-bottom: 2px solid #626672;
}

.boardMain .board .btnLink {
    position: absolute;
    top: 3px;
    right: 0;
}

.boardMain .board .ec-base-table.typeList td {
    color: #a4a4a4;
}

.boardMain .board .ec-base-table.typeList td.title a,
.boardMain .board .ec-base-table.typeList td.title a:hover {
    color: #4f4f4f;
}

.boardMain .board .galleryList {
    overflow: hidden;
}

.boardMain .board .galleryList ul {
    overflow: hidden;
    margin: 0 -10px;
}

.boardMain .board .galleryList li {
    float: left;
    position: relative;
    width: 20%;
    padding: 0 0 12px 0;
    word-break: break-all;
}

.boardMain .board .galleryList li span {
    display: block;
    margin: 0 10px;
    font-size: 11px;
    color: #a4a4a4;
}

.boardMain .board .galleryList li .imgLink,
.boardMain .board .galleryList li .imgLink:hover {
    display: block;
    margin: 0 10px 5px;
    font-size: 12px;
}

.boardMain .board .galleryList li .imgLink img {
    width: 100%;
    max-height: 110px;
    border: 1px solid #ededed;
}

.mypage_leftmenu_wrap {
    width: 207px;
    display: inline-block;
    vertical-align: top;
    margin-top: 50px;
}

.mypage_leftmenu_wrap h3.tlt {
    font-size: 19px;
    font-weight: bold;
    padding: 0 0 20px;
}

.mypage_leftmenu_wrap ul li a {
    display: inline-block;
    text-decoration: none;
    font-size: 15px;
    padding: 5px 0;
}

.mypage_leftmenu_wrap ul li.active a {
    font-weight: bold;
}

.mypage_leftmenu_wrap .menulist_0,
.mypage_leftmenu_wrap .menulist_1,
.mypage_leftmenu_wrap .menulist_2 {
    width: 145px;
}

.mypage_leftmenu_wrap .menulist_0 {
    margin: 0 0 40px;
}

.mypage_leftmenu_wrap .menulist_0 .xans-member-var-name {
    font-weight: bold;
}

.mypage_leftmenu_wrap .menulist_0 .m_name {
    font-size: px;
    display: block;
}

.mypage_leftmenu_wrap .menulist_0 .m_group {
    background: #f1f1f1;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 10px 0 0;
    font-size: 15px;
    color: #555;
}

.mypage_leftmenu_wrap .menulist_0 .xans-member-var-group_name {
    font-weight: bold;
}

.mypage_leftmenu_wrap .menulist_2 {
    border-top: 1px solid #dbdbdb;
    padding: 20px 0 0;
    margin: 20px 0 0;
}

.mypage_cont_wrap {
    width: 1060px;
    display: inline-block;
    vertical-align: top;
}

#modalBackpanel {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background: #000;
}

#modalContainer {
    display: none;
    position: absolute;
    top: 100px;
    left: 100px;
    z-index: 10001;
    width: 975px;
    height: 720px;
    border: 1px solid #333;
    background: #fff;
}

#modalContainer #modalContent {
    width: 100%;
    height: 100%;
}

.orderStep {
    display: none;
}

#main #wrap {
    margin-top: -188px;
}

#header .top-area {
    border-bottom: 1px solid #e9e9e9;
    position: relative;
    z-index: 99;
}

.main-container #header .top-area {
    border-bottom: 0 solid #e9e9e9;
}

#header.fixed .cate-wrap .inner {
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 999;
    border-bottom: 1px solid #e9e9e9;
    text-align: center;
    font-size: 0;
}

#header .logo-wrap {
    height: 88px;
    font-size: 0;
    text-align: left;
}

#header .logo-wrap .site-wrap {
    height: 100%;
}

#header .logo-wrap .vertical {
    display: table-cell;
    vertical-align: bottom;
}

#header .logo-wrap .logo-img {
    position: absolute;
    display: table;
    text-align: left;
    width: 50%;
    height: 100%;
    left: 0;
}

#header .xans-layout-logotop img {
    transition: all .25s ease;
}

#header .xans-layout-logotop a {
    display: block;
}

#header .top-member {
    position: absolute;
    display: table;
    text-align: right;
    width: 50%;
    height: 100%;
    left: 50%;
}

#header .log {
    display: inline-block;
    vertical-align: middle;
}

#header .log a {
    font-size: 14px;
    color: #999;
    display: inline-block;
    vertical-align: top;
    margin: 0 12px;
    text-decoration: none;
    position: relative;
}

#header .log a:last-child {
    margin-right: 0;
}

#header.fixed .log {
    display: none;
}

#header .log .bb {
    color: #fff;
    padding: 3px 6px 4px;
    font-size: 12px;
    position: absolute;
    top: -28px;
    right: -4px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    animation-duration: .8s;
    animation-name: point-move;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    min-width: 50px;
    text-align: center;
}

#header .log .bb:after {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    position: absolute;
    transform: rotate(45deg);
    border-top-color: rgba(35, 35, 35, 0);
    border-left-color: rgba(35, 35, 35, 0);
    left: 50%;
    bottom: -2px;
    margin-left: -2px;
}

@keyframes point-move {
    50% {
        top: -30px
    }

    100% {
        top: -28px;
    }
}

#header .multishop {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

.xans-layout-multishoplist.list {
    position: relative;
    z-index: 10;
    margin-left: 20px;
}

.xans-layout-multishoplist.list .toggle {
    display: block;
    padding: 3px 0 3px 10px;
    color: #757575;
    font-size: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.xans-layout-multishoplist.list .toggle span {
    padding: 0 25px 0 0;
    /* background: url("//img.echosting.cafe24.com/skin/base/layout/ico_multishop1.png") no-repeat 100% 4px; */
}

.xans-layout-multishoplist.list .toggle span>img {
    vertical-align: middle
}

.xans-layout-multishoplist.list>ul {
    display: none;
    position: absolute;
    right: 0;
    top: 34px;
    overflow: auto;
    width: 148px;
    height: 155px;
    border: 1px solid #d5d5d5;
    background: #fff;
    font-size: 12px;
}

.xans-layout-multishoplist.list>ul li {
    position: relative;
    height: 39px;
    line-height: 39px;
}

.xans-layout-multishoplist.list>ul li a {
    display: block;
    overflow: hidden;
    padding: 0 27px 0 18px;
    color: #353535;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
    /* background: url("//img.echosting.cafe24.com/skin/base/layout/ico_multishop2.png") no-repeat 100% -24px; */
}

.xans-layout-multishoplist.list>ul li.selected a {
    /* background: #f3f3f3 url("//img.echosting.cafe24.com/skin/base/layout/ico_multishop2.png") no-repeat 100% 16px; */
}

.xans-layout-multishoplist.list>ul li a img {
    position: absolute;
    left: 10px;
    top: 9px;
}

.xans-layout-multishoplist.list>ul li a .name strong {
    font-weight: normal;
}

.xans-layout-multishoplist.list>ul li a .unit {
    position: absolute;
    right: 38px;
    top: 0;
    font-size: 11px;
    color: #757575;
}

.xans-layout-multishoplist.list.show>ul {
    display: block;
}

.xans-layout-multishoplist.thumb {
    position: absolute;
    left: 0;
    top: 45px;
}

.xans-layout-multishoplist.thumb>ul {
    overflow: hidden;
}

.xans-layout-multishoplist.thumb>ul li {
    float: left;
    width: 32px;
    margin: 0 4px 0 0;
}

.xans-layout-multishoplist.thumb>ul li a {
    text-decoration: none;
}

.xans-layout-multishoplist .multiOption a {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    color: #666;
}

.xans-layout-multishoplist .multiOption a:hover {
    text-decoration: none;
}

.xans-layout-multishoplist .multiOption .ship,
.xans-layout-multishoplist .multiOption .language {
    margin: 0 15px 0 0;
}

.xans-layout-multishoplist .multiOption .language img {
    margin: 0 6px 0 1px;
}

#header .cate-wrap {
    margin-top: 20px;
    height: 70px;
    text-align: left;
}

#header .cate-wrap .float-left {
    position: absolute;
    left: 0;
    top: 0;
    width: 950px;
    white-space: nowrap;
    text-align: left;
}

#header .cate-wrap .float-right {
    position: absolute;
    right: 0;
    top: 0;
    width: 320px;
    margin-top: 17px;
}

#header .cate-wrap .logo-img {
    float: left;
    height: inherit;
    display: flex;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    overflow: hidden;
    width: 0;
    opacity: 0;
    visibility: hidden;
}

#header .cate-wrap .logo-img img {}

#header .cate-wrap .inner,
#header .cate-wrap .site-wrap,
#header .cate-wrap .float-left,
#header .cate-wrap .logo-img {
    height: inherit;
}

#category {
    display: inline-block;
}

.cate-wrap .bs,
#category>ul>li {
    margin-right: 35px;
    display: inline-block;
    position: relative;
}

#header.fixed .cate-wrap .bs,
#header.fixed #category>ul>lia {
    margin-right: 25px;
}

.cate-wrap .pt>a:after {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #ff5880;
    border-radius: 50%;
    vertical-align: top;
    top: -6px;
    right: -6px;
    position: absolute;
}

#header .sub-list {
    display: none;
}

#header .bs:hover .sub-list {
    display: block;
    visibility: visible;
    opacity: 1;
}

.cate-wrap .bs>a,
#category>ul>li>a {
    font-size: 18px;
    color: #222;
    padding: 0 0 10px;
    text-decoration: none;
    height: 70px;
    line-height: 70px;
    position: relative;
    transition: font .25s ease;
}

#header.fixed .cate-wrap .bs>a,
#header.fixed #category>ul>li>a {
    font-size: 16px;
}

.cate-wrap .bs a:before,
#category>ul>li>a:before {
    display: block;
    height: 2px;
    width: 0;
    transition: width .25s ease;
    margin: 0 auto 0;
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    content: "";
}

.cate-wrap .bs:hover>a:before,
#category>ul>li:hover>a:before {
    width: 100%;
}

.cate-wrap .bs:hover>a,
#category>ul>li:hover>a {
    color: #000;
}

#category>ul>li .sub-category,
#header .sub-list {
    position: absolute;
    top: 57px;
    left: 0;
    min-width: 150px;
    background: #fff;
    padding: 10px 0;
    box-sizing: border-box;
    word-break: keep-all;
    z-index: 99;
    border: 1px solid #eee;
    border-radius: 0 20px 0px 20px;
    visibility: hidden;
    opacity: 0;
    text-align: left;
}

#category>ul>li .sub-category a,
#header .sub-list a {
    color: #777;
    font-size: 14px;
    display: block;
}

#category>ul>li .sub-category a:hover,
#header .sub-list a:hover {
    color: #000;
}

#category .sub-category:before {
    content: "";
}

#category>ul>li:hover .sub-category {
    visibility: visible;
    opacity: 1;
}

#category .sub-category .sub-left {
    box-sizing: border-box;
}

#category .sub-category .sub-left li {
    text-align: left;
}

#category .sub-category .sub-left li a,
#header .sub-list a {
    color: #777777;
    font-size: 14px;
    text-decoration: none;
    padding: 5px 12px 5px;
}

#category .sub-category .sub-right {
    margin-top: 10px;
}

#category .sub-left .sub03,
#category .sub-left .sub04 {
    display: none;
}

#category .sub-left li.arrow>a:before {
    width: 6px;
    height: 6px;
    border-width: 1px 1px 0 0;
    border-color: #aaa;
    border-style: solid;
    position: absolute;
    display: inline-block;
    content: "";
    transform: rotate(45deg) translate(0%, -50%);
    right: 15px;
    top: 50%;
}

#category .sub-left .sub02>li,
#category .sub-left .sub03>li,
#category .sub-left .sub04>li {
    position: relative;
}

#category .sub-left .sub03>li,
#category .sub-left .sub04>li {
    min-width: 170px;
}

#category .sub-left .sub02>li .sub03,
#category .sub-left .sub02>li .sub04 {
    background: #fff;
    border-radius: 10px 0;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    padding: 5px 0;
    box-sizing: border-box;
}

#category .sub-left .sub02>li:hover .sub03 {
    display: block;
    position: absolute;
    left: 100%;
    top: -5px;
    border: 0px solid #ddd;
    z-index: 1;
    margin-left: -1px;
}

#category .sub-left .sub03>li:hover .sub04 {
    display: block;
    position: absolute;
    left: 100%;
    top: -5px;
    border: 0px solid #ddd;
    z-index: 2;
    margin-left: -1px;
}

#header .xans-layout-searchheader {
    float: right;
    margin: 0 10px 0 0;
    position: relative;
    padding-bottom: 10px;
}

#header .xans-layout-searchheader .inputTypeText {
    border: 0;
    background: transparent;
    height: 32px;
    line-height: 32px;
    width: 210px;
    outline: 0;
    color: #222;
    font-size: 15px;
    padding: 0;
    padding: 0 40px 0 0px;
    box-sizing: border-box;
    border-bottom: 2px solid #222;
    transition: width .25s ease;
}

#header .xans-layout-searchheader .bt-search {
    width: 22px;
    height: 22px;
    position: absolute;
    right: 0;
    top: 2px;
    outline: 0;
}

#header .xans-layout-searchheader .search-dropbox {
    opacity: 0;
    width: 0px;
    height: 0;
    background: rgba(255, 255, 255, 0.9);
    transition: opacity .25s ease;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 10;
    border-radius: 0 20px 0px 20px;
    visibility: hidden;
    padding: 20px;
    box-sizing: border-box;
    height: auto;
    min-height: 200px;
    max-height: 230px;
    overflow-y: auto;
    border: 0px solid #eee;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.05);
    text-align: left;
}

#header .xans-layout-searchheader:hover .search-dropbox {
    opacity: 1;
    width: 310px;
    visibility: visible;
}

#header .xans-layout-searchheader h5 {
    font-size: 18px;
    margin: 0 0 14px;
}

#header .xans-layout-searchheader .xans-search-hotkeyword a {
    font-size: 14px;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    margin: 4px 6px;
    transition: color .25s ease, background .25s ease;
}

#header .xans-layout-searchheader .xans-search-hotkeyword a:before {
    content: "#";
}

#header .xans-layout-searchheader .xans-search-hotkeyword a:hover {
    color: #555;
}

#header .bt-basket {
    margin: 0 5px 0 6px;
    background: none;
    float: right;
    width: 30px;
    height: 30px;
    overflow: visible;
}

#header .bt-basket a {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 0;
    white-space: nowrap;
    border: 0;
    /* background: url("/web/upload/wp/167417/ico_cart_svg.svg") no-repeat center; */
    background-size: 24px auto;
    position: relative;
}

#header .bt-basket .count {
    background: #2a2a2a;
    color: #fff;
    font-size: 10px;
    padding: 0px 3px 0px;
    min-width: 10px;
    text-align: center;
    position: absolute;
    bottom: -3px;
    right: -5px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    float: none;
    min-width: 16px;
    height: 16px;
    line-height: 16px;
    box-sizing: border-box;
}

#header .bt-member {
    margin: 0 6px 0 20px;
    background: none;
    float: right;
    width: 30px;
    height: 30px;
    overflow: visible;
}

#header .bt-member a {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 0;
    white-space: nowrap;
    border: 0;
    /* background: url("/web/upload/wp/167417/bt_top_member.png") no-repeat center; */
    background-size: 26px auto;
    position: relative;
}

#footer {
    padding: 60px 0 70px;
}

#footer,
#footer a {
    color: #fff;
    font-size: 14px;
}

#footer .info-wrap {
    float: left;
    width: 60%;
    padding: 0 40px 0 0;
    box-sizing: border-box;
}

#footer .info-wrap .logo {
    display: block;
    padding: 0 0 42px;
}

#footer .info-wrap .util {
    padding: 0 0 20px;
}

#footer .info-wrap .util li {
    display: inline-block;
    vertical-align: middle;
}

#footer .info-wrap .util li:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 14px;
    background: rgba(255, 255, 255, 0.5);
    margin: 0 10px 0 12px;
}

#footer .info-wrap .util li:last-child:after {
    content: none;
}

#footer .info-wrap .util li a {
    font-size: 15px;
    color: #fff;
    text-decoration: none;
}

#footer .info-wrap .footerInfo,
#footer .info-wrap .footerInfo a {
    color: #fff;
}

#footer .info-wrap .footerInfo {
    line-height: 1.6;
}

#footer .info-wrap .footerInfo span.line {
    color: rgba(255, 255, 255, 0.2);
    margin: 0 8px;
}

#footer .cs {
    color: #fff;
    float: left;
    width: 20%;
    min-width: 170px;
    padding: 0 30px;
    box-sizing: border-box;
    position: relative;
}

#footer .cs:before {
    width: 1px;
    height: 160px;
    background: rgba(255, 255, 255, 0.2);
    position: absolute;
    left: 0;
    top: 0;
}

#footer .cs .phone {
    font-size: 24px;
    margin-bottom: 5px;
    color: #fff;
    font-weight: bold;
}

#footer .cs [class^='btn'] {
    margin-top: 15px;
    color: #222;
    border: 0px solid #eee;
    border-radius: 0px;
    padding: 5px 15px;
    font-size: 14px;
    letter-spacing: -0.5px;
    width: 150px;
    transition: background .25s ease, color .25s ease;
    background: #fff;
    line-height: 24px;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    text-decoration: none;
}

#footer .cs [class^='btn']:hover {
    background: #111;
    color: #fff;
}

#footer .lnk,
#footer .lnk a {
    color: #fff;
}

#footer .lnk {
    float: left;
    width: 20%;
    min-width: 170px;
    padding: 0 30px;
    box-sizing: border-box;
    padding-right: 0;
    position: relative;
}

#footer .lnk:before {
    width: 1px;
    height: 160px;
    background: rgba(255, 255, 255, 0.2);
    position: absolute;
    left: 0;
    top: 0;
}

#footer .lnk>ul {
    display: inline-block;
    vertical-align: top;
    padding-right: 30px;
    line-height: 25px;
}

#footer .lnk>ul:last-child {
    padding-right: 0;
}

#footer h1.tlt {
    color: #fff;
    font-size: 17px;
    padding: 0 0 20px;
}

#footer .copyright {
    margin: 35px 0 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 15px 0 0;
    text-align: center;
}

#footer .copyright .escrow {
    font-size: 13px;
    padding: 5px 0;
}

#footer .copyright .copy {
    color: #fff;
    font-size: 13px;
}

.side_bnr_wrap {
    position: fixed;
    opacity: 0.9;
    bottom: 0;
    right: 0;
    z-index: 10;
    background: #222222;
    width: 55px;
    border-radius: 20px;
    bottom: 0px;
    right: 10px;
    transition: all .3s ease;
    opacity: 0;
    visibility: hidden;
}

.side_bnr_wrap.active {
    bottom: 10px;
    opacity: 1;
    visibility: visible;
}

.side_bnr_wrap a {
    display: block;
    text-align: center;
}

.side_bnr_wrap .bt {
    width: 55px;
    height: 55px;
    box-sizing: border-box;
}

.side_bnr_wrap .bt img {
    margin-top: 12px;
}

.side_bnr_wrap .bt_top,
.side_bnr_wrap .bt_btm {
    width: 55px;
    height: 55px;
    box-sizing: border-box;
    /* background: url(/web/upload/wp/167417/bt_side_arrow.png) no-repeat center / 23px; */
}

.side_bnr_wrap .bt_btm {
    transform: rotate(180deg);
}

#capp-shop-new-product-optionselect-layer {
    height: 548px !important;
}

.bx-wrapper {
    position: relative;
    margin: 0 auto;
    padding: 0;
}

.bx-wrapper .bx-viewport {
    left: 0px;
    background: #fff;
    -webkit-transform: translatez(0);
    -moz-transform: translatez(0);
    -ms-transform: translatez(0);
    -o-transform: translatez(0);
    transform: translatez(0);
}

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
    position: absolute;
    bottom: -30px;
    width: 100%;
    z-index: 30;
}

.bx-wrapper .bx-loading {
    min-height: 50px;
    /* background: url(/web/upload/wp/bxslider/bx_loader.gif) center center no-repeat #fff; */
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
}

.bx-wrapper .bx-pager {
    text-align: center;
    font-size: .85em;
    /* font-family: Arial; */
    font-weight: bold;
    color: #666;
    padding-top: 20px;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
    display: inline-block;

}

.bx-wrapper .bx-pager.bx-default-pager a {
    background: none;
    text-indent: -9999px;
    display: block;
    width: 8px;
    height: 8px;
    margin: 0 3px;
    outline: 0;
    -moz-border-radius: 9px;
    -webkit-border-radius: 9px;
    border-radius: 9px;
    border: 1px solid #555;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
    background: #555;
}

.bx-wrapper .bx-prev {}

.bx-wrapper .bx-next {}

.bx-wrapper .bx-prev:hover,
.bx-wrapper .bx-next:hover {
    opacity: 0.6
}

.bx-wrapper .bx-controls-direction a {
    position: absolute;
    top: 50%;
    outline: 0;
    text-indent: -9999px;
    z-index: 79;
}

.bx-wrapper .bx-controls-direction a.disabled {
    display: none;
}

.bx-wrapper .bx-controls-auto {
    text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
    display: block;
    text-indent: -9999px;
    width: 10px;
    height: 11px;
    outline: 0;
    /* background: url(/web/upload/wp/bxslider/controls.png) -86px -11px no-repeat; */
    margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
    background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
    display: block;
    text-indent: -9999px;
    width: 9px;
    height: 11px;
    outline: 0;
    /* background: url(/web/upload/wp/bxslider/controls.png) -86px -44px no-repeat; */
    margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
    background-position: -86px -33px;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
    text-align: left;
    width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
    right: 0;
    width: 35px;
}

.bx-wrapper .bx-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(80, 80, 80, 0.75);
    width: 100%;
}

.bx-wrapper .bx-caption span {
    color: #fff;
    /* font-family: Arial; */
    display: block;
    font-size: .85em;
    padding: 10px;
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.swiper-container {
    margin-left: 100px;
    margin-right: 100px;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
    float: left;
}

.swiper-container-vertical>.swiper-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow>.swiper-wrapper {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.swiper-container-free-mode>.swiper-wrapper {
    -webkit-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    margin: 0 auto;
}

.swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}

.swiper-slide-invisible-blank {
    visibility: hidden;
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
    height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-transition-property: height, -webkit-transform;
    transition-property: height, -webkit-transform;
    -o-transition-property: transform, height;
    transition-property: transform, height;
    transition-property: transform, height, -webkit-transform;
}

.swiper-container-3d {
    -webkit-perspective: 1200px;
    perspective: 1200px;
}

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
    background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-right {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-top {
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal>.swiper-wrapper {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical>.swiper-wrapper {
    -ms-touch-action: pan-x;
    touch-action: pan-x;
}

.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: 50%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    background-size: 27px 44px;
    background-position: center;
    background-repeat: no-repeat;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E"); */
    left: 10px;
    right: auto;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E"); */
    right: 10px;
    left: auto;
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
    /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); */
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
    /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); */
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
    /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); */
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
    /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); */
}

.swiper-button-lock {
    display: none;
}

.swiper-pagination {
    position: absolute;
    text-align: center;
    -webkit-transition: 300ms opacity;
    -o-transition: 300ms opacity;
    transition: 300ms opacity;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
    opacity: 0;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 10px;
    left: 0;
    width: 100%;
}

.swiper-pagination-bullets-dynamic {
    overflow: hidden;
    font-size: 0;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    -webkit-transform: scale(0.33);
    -ms-transform: scale(0.33);
    transform: scale(0.33);
    position: relative;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    -webkit-transform: scale(0.66);
    -ms-transform: scale(0.66);
    transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    -webkit-transform: scale(0.33);
    -ms-transform: scale(0.33);
    transform: scale(0.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    -webkit-transform: scale(0.66);
    -ms-transform: scale(0.66);
    transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    -webkit-transform: scale(0.33);
    -ms-transform: scale(0.33);
    transform: scale(0.33);
}

.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100%;
    background: #000;
    opacity: 0.2;
}

button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
}

.swiper-pagination-bullet-active {
    opacity: 1;
    background: #007aff;
}

.swiper-container-vertical>.swiper-pagination-bullets {
    right: 10px;
    top: 50%;
    -webkit-transform: translate3d(0px, -50%, 0);
    transform: translate3d(0px, -50%, 0);
}

.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 6px 0;
    display: block;
}

.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 8px;
}

.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    display: inline-block;
    -webkit-transition: 200ms top, 200ms -webkit-transform;
    transition: 200ms top, 200ms -webkit-transform;
    -o-transition: 200ms transform, 200ms top;
    transition: 200ms transform, 200ms top;
    transition: 200ms transform, 200ms top, 200ms -webkit-transform;
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 4px;
}

.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    white-space: nowrap;
}

.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    -webkit-transition: 200ms left, 200ms -webkit-transform;
    transition: 200ms left, 200ms -webkit-transform;
    -o-transition: 200ms transform, 200ms left;
    transition: 200ms transform, 200ms left;
    transition: 200ms transform, 200ms left, 200ms -webkit-transform;
}

.swiper-container-horizontal.swiper-container-rtl>.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    -webkit-transition: 200ms right, 200ms -webkit-transform;
    transition: 200ms right, 200ms -webkit-transform;
    -o-transition: 200ms transform, 200ms right;
    transition: 200ms transform, 200ms right;
    transition: 200ms transform, 200ms right, 200ms -webkit-transform;
}

.swiper-pagination-progressbar {
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: #007aff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
}

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    -webkit-transform-origin: right top;
    -ms-transform-origin: right top;
    transform-origin: right top;
}

.swiper-container-horizontal>.swiper-pagination-progressbar,
.swiper-container-vertical>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0;
}

.swiper-container-vertical>.swiper-pagination-progressbar,
.swiper-container-horizontal>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
    background: #ffffff;
}

.swiper-pagination-progressbar.swiper-pagination-white {
    background: rgba(255, 255, 255, 0.25);
}

.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
    background: #ffffff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
    background: #000000;
}

.swiper-pagination-progressbar.swiper-pagination-black {
    background: rgba(0, 0, 0, 0.25);
}

.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
    background: #000000;
}

.swiper-pagination-lock {
    display: none;
}

.swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    -ms-touch-action: none;
    background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal>.swiper-scrollbar {
    position: absolute;
    left: 1%;
    bottom: 3px;
    z-index: 50;
    height: 5px;
    width: 98%;
}

.swiper-container-vertical>.swiper-scrollbar {
    position: absolute;
    right: 3px;
    top: 1%;
    z-index: 50;
    width: 5px;
    height: 98%;
}

.swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    left: 0;
    top: 0;
}

.swiper-scrollbar-cursor-drag {
    cursor: move;
}

.swiper-scrollbar-lock {
    display: none;
}

.swiper-zoom-container {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
}

.swiper-zoom-container>img,
.swiper-zoom-container>svg,
.swiper-zoom-container>canvas {
    max-width: 100%;
    max-height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}

.swiper-slide-zoomed {
    cursor: move;
}

.swiper-lazy-preloader {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    -webkit-transform-origin: 50%;
    -ms-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
    animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); */
    background-position: 50%;
    background-size: 100%;
    background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
    /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); */
}

@-webkit-keyframes swiper-preloader-spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes swiper-preloader-spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.swiper-container .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
    -webkit-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
    pointer-events: none;
    -webkit-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
}

.swiper-container-cube {
    overflow: visible;
}

.swiper-container-cube .swiper-slide {
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1;
    visibility: hidden;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    width: 100%;
    height: 100%;
}

.swiper-container-cube .swiper-slide .swiper-slide {
    pointer-events: none;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next+.swiper-slide {
    pointer-events: auto;
    visibility: visible;
}

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right {
    z-index: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.swiper-container-cube .swiper-cube-shadow {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.6;
    -webkit-filter: blur(50px);
    filter: blur(50px);
    z-index: 0;
}

.swiper-container-flip {
    overflow: visible;
}

.swiper-container-flip .swiper-slide {
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1;
}

.swiper-container-flip .swiper-slide .swiper-slide {
    pointer-events: none;
}

.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
}

.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right {
    z-index: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.swiper-container-coverflow .swiper-wrapper {
    -ms-perspective: 1200px;
}

:root {
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
}

.animate__animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animate__animated.animate__infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.animate__animated.animate__repeat-1 {
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: var(--animate-repeat);
    animation-iteration-count: var(--animate-repeat);
}

.animate__animated.animate__repeat-2 {
    -webkit-animation-iteration-count: calc(1 * 2);
    animation-iteration-count: calc(1 * 2);
    -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
    animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animate__animated.animate__repeat-3 {
    -webkit-animation-iteration-count: calc(1 * 3);
    animation-iteration-count: calc(1 * 3);
    -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
    animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animate__animated.animate__delay-300m {
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
    -webkit-animation-delay: calc(var(--animate-delay) * 0.3);
    animation-delay: calc(var(--animate-delay) * 0.3);
}

.animate__animated.animate__delay-400m {
    -webkit-animation-delay: .4s;
    animation-delay: .4s;
    -webkit-animation-delay: calc(var(--animate-delay) * 0.4);
    animation-delay: calc(var(--animate-delay) * 0.4);
}

.animate__animated.animate__delay-500m {
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
    -webkit-animation-delay: calc(var(--animate-delay) * 0.5);
    animation-delay: calc(var(--animate-delay) * 0.5);
}

.animate__animated.animate__delay-600m {
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
    -webkit-animation-delay: calc(var(--animate-delay) * 0.6);
    animation-delay: calc(var(--animate-delay) * 0.6);
}

.animate__animated.animate__delay-700m {
    -webkit-animation-delay: .7s;
    animation-delay: .7s;
    -webkit-animation-delay: calc(var(--animate-delay) * 0.7);
    animation-delay: calc(var(--animate-delay) * 0.7);
}

.animate__animated.animate__delay-800m {
    -webkit-animation-delay: .8s;
    animation-delay: .8s;
    -webkit-animation-delay: calc(var(--animate-delay) * 0.8);
    animation-delay: calc(var(--animate-delay) * 0.8);
}

.animate__animated.animate__delay-900m {
    -webkit-animation-delay: .9s;
    animation-delay: .9s;
    -webkit-animation-delay: calc(var(--animate-delay) * 0.9);
    animation-delay: calc(var(--animate-delay) * 0.9);
}

.animate__animated.animate__delay-1s {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-delay: var(--animate-delay);
    animation-delay: var(--animate-delay);
}

.animate__animated.animate__delay-1-1s {
    -webkit-animation-delay: 1.1s;
    animation-delay: 1.1s;
    -webkit-animation-delay: var(--animate-delay);
    animation-delay: var(--animate-delay);
}

.animate__animated.animate__delay-1-2s {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
    -webkit-animation-delay: var(--animate-delay * 1.2);
    animation-delay: var(--animate-delay * 1.2);
}

.animate__animated.animate__delay-1-3s {
    -webkit-animation-delay: 1.3s;
    animation-delay: 1.3s;
    -webkit-animation-delay: var(--animate-delay * 1.3);
    animation-delay: var(--animate-delay * 1.3);
}

.animate__animated.animate__delay-1-5s {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-animation-delay: calc(var(--animate-delay) * 1.5);
    animation-delay: calc(var(--animate-delay) * 1.5);
}

.animate__animated.animate__delay-1-7s {
    -webkit-animation-delay: 1.7s;
    animation-delay: 1.7s;
    -webkit-animation-delay: var(--animate-delay * 1.7);
    animation-delay: var(--animate-delay * 1.7);
}

.animate__animated.animate__delay-2s {
    -webkit-animation-delay: calc(1s * 2);
    animation-delay: calc(1s * 2);
    -webkit-animation-delay: calc(var(--animate-delay) * 2);
    animation-delay: calc(var(--animate-delay) * 2);
}

.animate__animated.animate__delay-2-5s {
    -webkit-animation-delay: calc(1s * 2.5);
    animation-delay: calc(1s * 2.5);
    -webkit-animation-delay: calc(var(--animate-delay) * 2.5);
    animation-delay: calc(var(--animate-delay) * 2.5);
}

.animate__animated.animate__delay-3s {
    -webkit-animation-delay: calc(1s * 3);
    animation-delay: calc(1s * 3);
    -webkit-animation-delay: calc(var(--animate-delay) * 3);
    animation-delay: calc(var(--animate-delay) * 3);
}

.animate__animated.animate__delay-3-5s {
    -webkit-animation-delay: calc(1s * 3.5);
    animation-delay: calc(1s * 3.5);
    -webkit-animation-delay: calc(var(--animate-delay) * 3.5);
    animation-delay: calc(var(--animate-delay) * 3.5);
}

.animate__animated.animate__delay-4s {
    -webkit-animation-delay: calc(1s * 4);
    animation-delay: calc(1s * 4);
    -webkit-animation-delay: calc(var(--animate-delay) * 4);
    animation-delay: calc(var(--animate-delay) * 4);
}

.animate__animated.animate__delay-5s {
    -webkit-animation-delay: calc(1s * 5);
    animation-delay: calc(1s * 5);
    -webkit-animation-delay: calc(var(--animate-delay) * 5);
    animation-delay: calc(var(--animate-delay) * 5);
}

.animate__animated.animate__faster {
    -webkit-animation-duration: calc(1s / 2);
    animation-duration: calc(1s / 2);
    -webkit-animation-duration: calc(var(--animate-duration) / 2);
    animation-duration: calc(var(--animate-duration) / 2);
}

.animate__animated.animate__fast {
    -webkit-animation-duration: calc(1s * 0.8);
    animation-duration: calc(1s * 0.8);
    -webkit-animation-duration: calc(var(--animate-duration) * 0.8);
    animation-duration: calc(var(--animate-duration) * 0.8);
}

.animate__animated.animate__slow {
    -webkit-animation-duration: calc(1s * 2);
    animation-duration: calc(1s * 2);
    -webkit-animation-duration: calc(var(--animate-duration) * 2);
    animation-duration: calc(var(--animate-duration) * 2);
}

.animate__animated.animate__slower {
    -webkit-animation-duration: calc(1s * 3);
    animation-duration: calc(1s * 3);
    -webkit-animation-duration: calc(var(--animate-duration) * 3);
    animation-duration: calc(var(--animate-duration) * 3);
}

@media print,
(prefers-reduced-motion: reduce) {
    .animate__animated {
        -webkit-animation-duration: 1ms !important;
        animation-duration: 1ms !important;
        -webkit-transition-duration: 1ms !important;
        transition-duration: 1ms !important;
        -webkit-animation-iteration-count: 1 !important;
        animation-iteration-count: 1 !important;
    }

    .animate__animated[class*='Out'] {
        opacity: 0;
    }
}

@-webkit-keyframes bounce {

    from,
    20%,
    53%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
        transform: translate3d(0, -30px, 0) scaleY(1.1);
    }

    70% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
        transform: translate3d(0, -15px, 0) scaleY(1.05);
    }

    80% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: translate3d(0, 0, 0) scaleY(0.95);
        transform: translate3d(0, 0, 0) scaleY(0.95);
    }

    90% {
        -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
        transform: translate3d(0, -4px, 0) scaleY(1.02);
    }
}

@keyframes bounce {

    from,
    20%,
    53%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
        transform: translate3d(0, -30px, 0) scaleY(1.1);
    }

    70% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
        transform: translate3d(0, -15px, 0) scaleY(1.05);
    }

    80% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: translate3d(0, 0, 0) scaleY(0.95);
        transform: translate3d(0, 0, 0) scaleY(0.95);
    }

    90% {
        -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
        transform: translate3d(0, -4px, 0) scaleY(1.02);
    }
}

.wp-completely .animate__bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
}

@-webkit-keyframes flash {

    from,
    50%,
    to {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

@keyframes flash {

    from,
    50%,
    to {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

.wp-completely .animate__flash {
    -webkit-animation-name: flash;
    animation-name: flash;
}

@-webkit-keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.wp-completely .animate__pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

@-webkit-keyframes rubberBand {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes rubberBand {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.wp-completely .animate__rubberBand {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
}

@-webkit-keyframes shakeX {

    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
}

@keyframes shakeX {

    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
}

.wp-completely .animate__shakeX {
    -webkit-animation-name: shakeX;
    animation-name: shakeX;
}

@-webkit-keyframes shakeY {

    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
    }
}

@keyframes shakeY {

    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
    }
}

.wp-completely .animate__shakeY {
    -webkit-animation-name: shakeY;
    animation-name: shakeY;
}

@-webkit-keyframes headShake {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    6.5% {
        -webkit-transform: translateX(-6px) rotateY(-9deg);
        transform: translateX(-6px) rotateY(-9deg);
    }

    18.5% {
        -webkit-transform: translateX(5px) rotateY(7deg);
        transform: translateX(5px) rotateY(7deg);
    }

    31.5% {
        -webkit-transform: translateX(-3px) rotateY(-5deg);
        transform: translateX(-3px) rotateY(-5deg);
    }

    43.5% {
        -webkit-transform: translateX(2px) rotateY(3deg);
        transform: translateX(2px) rotateY(3deg);
    }

    50% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes headShake {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    6.5% {
        -webkit-transform: translateX(-6px) rotateY(-9deg);
        transform: translateX(-6px) rotateY(-9deg);
    }

    18.5% {
        -webkit-transform: translateX(5px) rotateY(7deg);
        transform: translateX(5px) rotateY(7deg);
    }

    31.5% {
        -webkit-transform: translateX(-3px) rotateY(-5deg);
        transform: translateX(-3px) rotateY(-5deg);
    }

    43.5% {
        -webkit-transform: translateX(2px) rotateY(3deg);
        transform: translateX(2px) rotateY(3deg);
    }

    50% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

.wp-completely .animate__headShake {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-name: headShake;
    animation-name: headShake;
}

@-webkit-keyframes swing {
    20% {
        -webkit-transform: rotate3d(0, 0, 1, 15deg);
        transform: rotate3d(0, 0, 1, 15deg);
    }

    40% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg);
    }

    60% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg);
    }

    80% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg);
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
    }
}

@keyframes swing {
    20% {
        -webkit-transform: rotate3d(0, 0, 1, 15deg);
        transform: rotate3d(0, 0, 1, 15deg);
    }

    40% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg);
    }

    60% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg);
    }

    80% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg);
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
    }
}

.wp-completely .animate__swing {
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing;
}

@-webkit-keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.wp-completely .animate__tada {
    -webkit-animation-name: tada;
    animation-name: tada;
}

@-webkit-keyframes wobble {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }

    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }

    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }

    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }

    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes wobble {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }

    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }

    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }

    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }

    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble;
}

@-webkit-keyframes jello {

    from,
    11.1%,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    22.2% {
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
        transform: skewX(-12.5deg) skewY(-12.5deg);
    }

    33.3% {
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
        transform: skewX(6.25deg) skewY(6.25deg);
    }

    44.4% {
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
        transform: skewX(-3.125deg) skewY(-3.125deg);
    }

    55.5% {
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
        transform: skewX(1.5625deg) skewY(1.5625deg);
    }

    66.6% {
        -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }

    77.7% {
        -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
        transform: skewX(0.390625deg) skewY(0.390625deg);
    }

    88.8% {
        -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
        transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    }
}

@keyframes jello {

    from,
    11.1%,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    22.2% {
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
        transform: skewX(-12.5deg) skewY(-12.5deg);
    }

    33.3% {
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
        transform: skewX(6.25deg) skewY(6.25deg);
    }

    44.4% {
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
        transform: skewX(-3.125deg) skewY(-3.125deg);
    }

    55.5% {
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
        transform: skewX(1.5625deg) skewY(1.5625deg);
    }

    66.6% {
        -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }

    77.7% {
        -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
        transform: skewX(0.390625deg) skewY(0.390625deg);
    }

    88.8% {
        -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
        transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    }
}

.wp-completely .animate__jello {
    -webkit-animation-name: jello;
    animation-name: jello;
    -webkit-transform-origin: center;
    transform-origin: center;
}

@-webkit-keyframes heartBeat {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    14% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }

    28% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    42% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }

    70% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes heartBeat {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    14% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }

    28% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    42% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }

    70% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.wp-completely .animate__heartBeat {
    -webkit-animation-name: heartBeat;
    animation-name: heartBeat;
    -webkit-animation-duration: calc(1s * 1.3);
    animation-duration: calc(1s * 1.3);
    -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
    animation-duration: calc(var(--animate-duration) * 1.3);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

@-webkit-keyframes backInDown {
    0% {
        -webkit-transform: translateY(-1200px) scale(0.7);
        transform: translateY(-1200px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateY(0px) scale(0.7);
        transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes backInDown {
    0% {
        -webkit-transform: translateY(-1200px) scale(0.7);
        transform: translateY(-1200px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateY(0px) scale(0.7);
        transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

.wp-completely .animate__backInDown {
    -webkit-animation-name: backInDown;
    animation-name: backInDown;
}

@-webkit-keyframes backInLeft {
    0% {
        -webkit-transform: translateX(-2000px) scale(0.7);
        transform: translateX(-2000px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateX(0px) scale(0.7);
        transform: translateX(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes backInLeft {
    0% {
        -webkit-transform: translateX(-2000px) scale(0.7);
        transform: translateX(-2000px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateX(0px) scale(0.7);
        transform: translateX(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

.wp-completely .animate__backInLeft {
    -webkit-animation-name: backInLeft;
    animation-name: backInLeft;
}

@-webkit-keyframes backInRight {
    0% {
        -webkit-transform: translateX(2000px) scale(0.7);
        transform: translateX(2000px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateX(0px) scale(0.7);
        transform: translateX(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes backInRight {
    0% {
        -webkit-transform: translateX(2000px) scale(0.7);
        transform: translateX(2000px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateX(0px) scale(0.7);
        transform: translateX(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

.wp-completely .animate__backInRight {
    -webkit-animation-name: backInRight;
    animation-name: backInRight;
}

@-webkit-keyframes backInUp {
    0% {
        -webkit-transform: translateY(1200px) scale(0.7);
        transform: translateY(1200px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateY(0px) scale(0.7);
        transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes backInUp {
    0% {
        -webkit-transform: translateY(1200px) scale(0.7);
        transform: translateY(1200px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateY(0px) scale(0.7);
        transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

.wp-completely .animate__backInUp {
    -webkit-animation-name: backInUp;
    animation-name: backInUp;
}

@-webkit-keyframes backOutDown {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    20% {
        -webkit-transform: translateY(0px) scale(0.7);
        transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: translateY(700px) scale(0.7);
        transform: translateY(700px) scale(0.7);
        opacity: 0.7;
    }
}

@keyframes backOutDown {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    20% {
        -webkit-transform: translateY(0px) scale(0.7);
        transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: translateY(700px) scale(0.7);
        transform: translateY(700px) scale(0.7);
        opacity: 0.7;
    }
}

.wp-completely .animate__backOutDown {
    -webkit-animation-name: backOutDown;
    animation-name: backOutDown;
}

@-webkit-keyframes backOutLeft {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    20% {
        -webkit-transform: translateX(0px) scale(0.7);
        transform: translateX(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: translateX(-2000px) scale(0.7);
        transform: translateX(-2000px) scale(0.7);
        opacity: 0.7;
    }
}

@keyframes backOutLeft {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    20% {
        -webkit-transform: translateX(0px) scale(0.7);
        transform: translateX(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: translateX(-2000px) scale(0.7);
        transform: translateX(-2000px) scale(0.7);
        opacity: 0.7;
    }
}

.wp-completely .animate__backOutLeft {
    -webkit-animation-name: backOutLeft;
    animation-name: backOutLeft;
}

@-webkit-keyframes backOutRight {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    20% {
        -webkit-transform: translateX(0px) scale(0.7);
        transform: translateX(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: translateX(2000px) scale(0.7);
        transform: translateX(2000px) scale(0.7);
        opacity: 0.7;
    }
}

@keyframes backOutRight {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    20% {
        -webkit-transform: translateX(0px) scale(0.7);
        transform: translateX(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: translateX(2000px) scale(0.7);
        transform: translateX(2000px) scale(0.7);
        opacity: 0.7;
    }
}

.wp-completely .animate__backOutRight {
    -webkit-animation-name: backOutRight;
    animation-name: backOutRight;
}

@-webkit-keyframes backOutUp {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    20% {
        -webkit-transform: translateY(0px) scale(0.7);
        transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: translateY(-700px) scale(0.7);
        transform: translateY(-700px) scale(0.7);
        opacity: 0.7;
    }
}

@keyframes backOutUp {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    20% {
        -webkit-transform: translateY(0px) scale(0.7);
        transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: translateY(-700px) scale(0.7);
        transform: translateY(-700px) scale(0.7);
        opacity: 0.7;
    }
}

.wp-completely .animate__backOutUp {
    -webkit-animation-name: backOutUp;
    animation-name: backOutUp;
}

@-webkit-keyframes bounceIn {

    from,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes bounceIn {

    from,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.wp-completely .animate__bounceIn {
    -webkit-animation-duration: calc(1s * 0.75);
    animation-duration: calc(1s * 0.75);
    -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
    animation-duration: calc(var(--animate-duration) * 0.75);
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
}

@-webkit-keyframes bounceInDown {

    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0) scaleY(3);
        transform: translate3d(0, -3000px, 0) scaleY(3);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0) scaleY(0.9);
        transform: translate3d(0, 25px, 0) scaleY(0.9);
    }

    75% {
        -webkit-transform: translate3d(0, -10px, 0) scaleY(0.95);
        transform: translate3d(0, -10px, 0) scaleY(0.95);
    }

    90% {
        -webkit-transform: translate3d(0, 5px, 0) scaleY(0.985);
        transform: translate3d(0, 5px, 0) scaleY(0.985);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes bounceInDown {

    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0) scaleY(3);
        transform: translate3d(0, -3000px, 0) scaleY(3);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0) scaleY(0.9);
        transform: translate3d(0, 25px, 0) scaleY(0.9);
    }

    75% {
        -webkit-transform: translate3d(0, -10px, 0) scaleY(0.95);
        transform: translate3d(0, -10px, 0) scaleY(0.95);
    }

    90% {
        -webkit-transform: translate3d(0, 5px, 0) scaleY(0.985);
        transform: translate3d(0, 5px, 0) scaleY(0.985);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__bounceInDown {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {

    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0) scaleX(3);
        transform: translate3d(-3000px, 0, 0) scaleX(3);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0) scaleX(1);
        transform: translate3d(25px, 0, 0) scaleX(1);
    }

    75% {
        -webkit-transform: translate3d(-10px, 0, 0) scaleX(0.98);
        transform: translate3d(-10px, 0, 0) scaleX(0.98);
    }

    90% {
        -webkit-transform: translate3d(5px, 0, 0) scaleX(0.995);
        transform: translate3d(5px, 0, 0) scaleX(0.995);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes bounceInLeft {

    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0) scaleX(3);
        transform: translate3d(-3000px, 0, 0) scaleX(3);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0) scaleX(1);
        transform: translate3d(25px, 0, 0) scaleX(1);
    }

    75% {
        -webkit-transform: translate3d(-10px, 0, 0) scaleX(0.98);
        transform: translate3d(-10px, 0, 0) scaleX(0.98);
    }

    90% {
        -webkit-transform: translate3d(5px, 0, 0) scaleX(0.995);
        transform: translate3d(5px, 0, 0) scaleX(0.995);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__bounceInLeft {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {

    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0) scaleX(3);
        transform: translate3d(3000px, 0, 0) scaleX(3);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0) scaleX(1);
        transform: translate3d(-25px, 0, 0) scaleX(1);
    }

    75% {
        -webkit-transform: translate3d(10px, 0, 0) scaleX(0.98);
        transform: translate3d(10px, 0, 0) scaleX(0.98);
    }

    90% {
        -webkit-transform: translate3d(-5px, 0, 0) scaleX(0.995);
        transform: translate3d(-5px, 0, 0) scaleX(0.995);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes bounceInRight {

    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0) scaleX(3);
        transform: translate3d(3000px, 0, 0) scaleX(3);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0) scaleX(1);
        transform: translate3d(-25px, 0, 0) scaleX(1);
    }

    75% {
        -webkit-transform: translate3d(10px, 0, 0) scaleX(0.98);
        transform: translate3d(10px, 0, 0) scaleX(0.98);
    }

    90% {
        -webkit-transform: translate3d(-5px, 0, 0) scaleX(0.995);
        transform: translate3d(-5px, 0, 0) scaleX(0.995);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight;
}

@-webkit-keyframes bounceInUp {

    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
        transform: translate3d(0, 3000px, 0) scaleY(5);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
        transform: translate3d(0, -20px, 0) scaleY(0.9);
    }

    75% {
        -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
        transform: translate3d(0, 10px, 0) scaleY(0.95);
    }

    90% {
        -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
        transform: translate3d(0, -5px, 0) scaleY(0.985);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes bounceInUp {

    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
        transform: translate3d(0, 3000px, 0) scaleY(5);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
        transform: translate3d(0, -20px, 0) scaleY(0.9);
    }

    75% {
        -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
        transform: translate3d(0, 10px, 0) scaleY(0.95);
    }

    90% {
        -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
        transform: translate3d(0, -5px, 0) scaleY(0.985);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__bounceInUp {
    -webkit-animation-name: bounceInUp;
    animation-name: bounceInUp;
}

@-webkit-keyframes bounceOut {
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    50%,
    55% {
        opacity: 1;
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
}

@keyframes bounceOut {
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    50%,
    55% {
        opacity: 1;
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
}

.wp-completely .animate__bounceOut {
    -webkit-animation-duration: calc(1s * 0.75);
    animation-duration: calc(1s * 0.75);
    -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
    animation-duration: calc(var(--animate-duration) * 0.75);
    -webkit-animation-name: bounceOut;
    animation-name: bounceOut;
}

@-webkit-keyframes bounceOutDown {
    20% {
        -webkit-transform: translate3d(0, 10px, 0) scaleY(0.985);
        transform: translate3d(0, 10px, 0) scaleY(0.985);
    }

    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
        transform: translate3d(0, -20px, 0) scaleY(0.9);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0) scaleY(3);
        transform: translate3d(0, 2000px, 0) scaleY(3);
    }
}

@keyframes bounceOutDown {
    20% {
        -webkit-transform: translate3d(0, 10px, 0) scaleY(0.985);
        transform: translate3d(0, 10px, 0) scaleY(0.985);
    }

    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
        transform: translate3d(0, -20px, 0) scaleY(0.9);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0) scaleY(3);
        transform: translate3d(0, 2000px, 0) scaleY(3);
    }
}

.wp-completely .animate__bounceOutDown {
    -webkit-animation-name: bounceOutDown;
    animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(20px, 0, 0) scaleX(0.9);
        transform: translate3d(20px, 0, 0) scaleX(0.9);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0) scaleX(2);
        transform: translate3d(-2000px, 0, 0) scaleX(2);
    }
}

@keyframes bounceOutLeft {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(20px, 0, 0) scaleX(0.9);
        transform: translate3d(20px, 0, 0) scaleX(0.9);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0) scaleX(2);
        transform: translate3d(-2000px, 0, 0) scaleX(2);
    }
}

.wp-completely .animate__bounceOutLeft {
    -webkit-animation-name: bounceOutLeft;
    animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(-20px, 0, 0) scaleX(0.9);
        transform: translate3d(-20px, 0, 0) scaleX(0.9);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0) scaleX(2);
        transform: translate3d(2000px, 0, 0) scaleX(2);
    }
}

@keyframes bounceOutRight {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(-20px, 0, 0) scaleX(0.9);
        transform: translate3d(-20px, 0, 0) scaleX(0.9);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0) scaleX(2);
        transform: translate3d(2000px, 0, 0) scaleX(2);
    }
}

.wp-completely .animate__bounceOutRight {
    -webkit-animation-name: bounceOutRight;
    animation-name: bounceOutRight;
}

@-webkit-keyframes bounceOutUp {
    20% {
        -webkit-transform: translate3d(0, -10px, 0) scaleY(0.985);
        transform: translate3d(0, -10px, 0) scaleY(0.985);
    }

    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, 20px, 0) scaleY(0.9);
        transform: translate3d(0, 20px, 0) scaleY(0.9);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0) scaleY(3);
        transform: translate3d(0, -2000px, 0) scaleY(3);
    }
}

@keyframes bounceOutUp {
    20% {
        -webkit-transform: translate3d(0, -10px, 0) scaleY(0.985);
        transform: translate3d(0, -10px, 0) scaleY(0.985);
    }

    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, 20px, 0) scaleY(0.9);
        transform: translate3d(0, 20px, 0) scaleY(0.9);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0) scaleY(3);
        transform: translate3d(0, -2000px, 0) scaleY(3);
    }
}

.wp-completely .animate__bounceOutUp {
    -webkit-animation-name: bounceOutUp;
    animation-name: bounceOutUp;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.wp-completely .animate__fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDownBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInDownBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__fadeInDownBig {
    -webkit-animation-name: fadeInDownBig;
    animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeftBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInLeftBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__fadeInLeftBig {
    -webkit-animation-name: fadeInLeftBig;
    animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInRightBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__fadeInRightBig {
    -webkit-animation-name: fadeInRightBig;
    animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 70%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 70%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes fadeInUp80 {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 80%, 0);
        transform: translate3d(0, 80%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUp80 {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 80%, 0);
        transform: translate3d(0, 80%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

.wp-completely .animate__fadeInUp80 {
    -webkit-animation-name: fadeInUp80;
    animation-name: fadeInUp80;
}

@-webkit-keyframes fadeInUpBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUpBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__fadeInUpBig {
    -webkit-animation-name: fadeInUpBig;
    animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeInTopLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, -100%, 0);
        transform: translate3d(-100%, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInTopLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, -100%, 0);
        transform: translate3d(-100%, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__fadeInTopLeft {
    -webkit-animation-name: fadeInTopLeft;
    animation-name: fadeInTopLeft;
}

@-webkit-keyframes fadeInTopRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, -100%, 0);
        transform: translate3d(100%, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInTopRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, -100%, 0);
        transform: translate3d(100%, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__fadeInTopRight {
    -webkit-animation-name: fadeInTopRight;
    animation-name: fadeInTopRight;
}

@-webkit-keyframes fadeInBottomLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 100%, 0);
        transform: translate3d(-100%, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInBottomLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 100%, 0);
        transform: translate3d(-100%, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__fadeInBottomLeft {
    -webkit-animation-name: fadeInBottomLeft;
    animation-name: fadeInBottomLeft;
}

@-webkit-keyframes fadeInBottomRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 100%, 0);
        transform: translate3d(100%, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInBottomRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 100%, 0);
        transform: translate3d(100%, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__fadeInBottomRight {
    -webkit-animation-name: fadeInBottomRight;
    animation-name: fadeInBottomRight;
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.wp-completely .animate__fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

@-webkit-keyframes fadeOutDown {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes fadeOutDown {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

.wp-completely .animate__fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutDownBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0);
    }
}

@keyframes fadeOutDownBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0);
    }
}

.wp-completely .animate__fadeOutDownBig {
    -webkit-animation-name: fadeOutDownBig;
    animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeft {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes fadeOutLeft {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

.wp-completely .animate__fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutLeftBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0);
    }
}

@keyframes fadeOutLeftBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0);
    }
}

.wp-completely .animate__fadeOutLeftBig {
    -webkit-animation-name: fadeOutLeftBig;
    animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRight {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes fadeOutRight {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

.wp-completely .animate__fadeOutRight {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutRightBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0);
    }
}

@keyframes fadeOutRightBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0);
    }
}

.wp-completely .animate__fadeOutRightBig {
    -webkit-animation-name: fadeOutRightBig;
    animation-name: fadeOutRightBig;
}

@-webkit-keyframes fadeOutUp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes fadeOutUp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

.wp-completely .animate__fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutUpBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0);
    }
}

@keyframes fadeOutUpBig {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0);
    }
}

.wp-completely .animate__fadeOutUpBig {
    -webkit-animation-name: fadeOutUpBig;
    animation-name: fadeOutUpBig;
}

@-webkit-keyframes fadeOutTopLeft {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, -100%, 0);
        transform: translate3d(-100%, -100%, 0);
    }
}

@keyframes fadeOutTopLeft {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, -100%, 0);
        transform: translate3d(-100%, -100%, 0);
    }
}

.wp-completely .animate__fadeOutTopLeft {
    -webkit-animation-name: fadeOutTopLeft;
    animation-name: fadeOutTopLeft;
}

@-webkit-keyframes fadeOutTopRight {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, -100%, 0);
        transform: translate3d(100%, -100%, 0);
    }
}

@keyframes fadeOutTopRight {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, -100%, 0);
        transform: translate3d(100%, -100%, 0);
    }
}

.wp-completely .animate__fadeOutTopRight {
    -webkit-animation-name: fadeOutTopRight;
    animation-name: fadeOutTopRight;
}

@-webkit-keyframes fadeOutBottomRight {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 100%, 0);
        transform: translate3d(100%, 100%, 0);
    }
}

@keyframes fadeOutBottomRight {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 100%, 0);
        transform: translate3d(100%, 100%, 0);
    }
}

.wp-completely .animate__fadeOutBottomRight {
    -webkit-animation-name: fadeOutBottomRight;
    animation-name: fadeOutBottomRight;
}

@-webkit-keyframes fadeOutBottomLeft {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 100%, 0);
        transform: translate3d(-100%, 100%, 0);
    }
}

@keyframes fadeOutBottomLeft {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 100%, 0);
        transform: translate3d(-100%, 100%, 0);
    }
}

.wp-completely .animate__fadeOutBottomLeft {
    -webkit-animation-name: fadeOutBottomLeft;
    animation-name: fadeOutBottomLeft;
}

@-webkit-keyframes flip {
    from {
        -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    40% {
        -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    50% {
        -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    80% {
        -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    to {
        -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
}

@keyframes flip {
    from {
        -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    40% {
        -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    50% {
        -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    80% {
        -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    to {
        -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
}

.wp-completely .animate__animated.animate__flip {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-animation-name: flip;
    animation-name: flip;
}

@-webkit-keyframes flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

@keyframes flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

.wp-completely .animate__flipInX {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInX;
    animation-name: flipInX;
}

@-webkit-keyframes flipInY {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1;
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

@keyframes flipInY {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1;
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

.wp-completely .animate__flipInY {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInY;
    animation-name: flipInY;
}

@-webkit-keyframes flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
}

@keyframes flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
}

.wp-completely .animate__flipOutX {
    -webkit-animation-duration: calc(1s * 0.75);
    animation-duration: calc(1s * 0.75);
    -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
    animation-duration: calc(var(--animate-duration) * 0.75);
    -webkit-animation-name: flipOutX;
    animation-name: flipOutX;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
}

@-webkit-keyframes flipOutY {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        opacity: 1;
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        opacity: 0;
    }
}

@keyframes flipOutY {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        opacity: 1;
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        opacity: 0;
    }
}

.wp-completely .animate__flipOutY {
    -webkit-animation-duration: calc(1s * 0.75);
    animation-duration: calc(1s * 0.75);
    -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
    animation-duration: calc(var(--animate-duration) * 0.75);
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipOutY;
    animation-name: flipOutY;
}

@-webkit-keyframes lightSpeedInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
        transform: translate3d(100%, 0, 0) skewX(-30deg);
        opacity: 0;
    }

    60% {
        -webkit-transform: skewX(20deg);
        transform: skewX(20deg);
        opacity: 1;
    }

    80% {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes lightSpeedInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
        transform: translate3d(100%, 0, 0) skewX(-30deg);
        opacity: 0;
    }

    60% {
        -webkit-transform: skewX(20deg);
        transform: skewX(20deg);
        opacity: 1;
    }

    80% {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__lightSpeedInRight {
    -webkit-animation-name: lightSpeedInRight;
    animation-name: lightSpeedInRight;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0) skewX(30deg);
        transform: translate3d(-100%, 0, 0) skewX(30deg);
        opacity: 0;
    }

    60% {
        -webkit-transform: skewX(-20deg);
        transform: skewX(-20deg);
        opacity: 1;
    }

    80% {
        -webkit-transform: skewX(5deg);
        transform: skewX(5deg);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes lightSpeedInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0) skewX(30deg);
        transform: translate3d(-100%, 0, 0) skewX(30deg);
        opacity: 0;
    }

    60% {
        -webkit-transform: skewX(-20deg);
        transform: skewX(-20deg);
        opacity: 1;
    }

    80% {
        -webkit-transform: skewX(5deg);
        transform: skewX(5deg);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__lightSpeedInLeft {
    -webkit-animation-name: lightSpeedInLeft;
    animation-name: lightSpeedInLeft;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOutRight {
    from {
        opacity: 1;
    }

    to {
        -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
        transform: translate3d(100%, 0, 0) skewX(30deg);
        opacity: 0;
    }
}

@keyframes lightSpeedOutRight {
    from {
        opacity: 1;
    }

    to {
        -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
        transform: translate3d(100%, 0, 0) skewX(30deg);
        opacity: 0;
    }
}

.wp-completely .animate__lightSpeedOutRight {
    -webkit-animation-name: lightSpeedOutRight;
    animation-name: lightSpeedOutRight;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
}

@-webkit-keyframes lightSpeedOutLeft {
    from {
        opacity: 1;
    }

    to {
        -webkit-transform: translate3d(-100%, 0, 0) skewX(-30deg);
        transform: translate3d(-100%, 0, 0) skewX(-30deg);
        opacity: 0;
    }
}

@keyframes lightSpeedOutLeft {
    from {
        opacity: 1;
    }

    to {
        -webkit-transform: translate3d(-100%, 0, 0) skewX(-30deg);
        transform: translate3d(-100%, 0, 0) skewX(-30deg);
        opacity: 0;
    }
}

.wp-completely .animate__lightSpeedOutLeft {
    -webkit-animation-name: lightSpeedOutLeft;
    animation-name: lightSpeedOutLeft;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
}

@-webkit-keyframes rotateIn {
    from {
        -webkit-transform: rotate3d(0, 0, 1, -200deg);
        transform: rotate3d(0, 0, 1, -200deg);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes rotateIn {
    from {
        -webkit-transform: rotate3d(0, 0, 1, -200deg);
        transform: rotate3d(0, 0, 1, -200deg);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.wp-completely .animate__rotateIn {
    -webkit-animation-name: rotateIn;
    animation-name: rotateIn;
    -webkit-transform-origin: center;
    transform-origin: center;
}

@-webkit-keyframes rotateInDownLeft {
    from {
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes rotateInDownLeft {
    from {
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.wp-completely .animate__rotateInDownLeft {
    -webkit-animation-name: rotateInDownLeft;
    animation-name: rotateInDownLeft;
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
}

@-webkit-keyframes rotateInDownRight {
    from {
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes rotateInDownRight {
    from {
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.wp-completely .animate__rotateInDownRight {
    -webkit-animation-name: rotateInDownRight;
    animation-name: rotateInDownRight;
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
}

@-webkit-keyframes rotateInUpLeft {
    from {
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes rotateInUpLeft {
    from {
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.wp-completely .animate__rotateInUpLeft {
    -webkit-animation-name: rotateInUpLeft;
    animation-name: rotateInUpLeft;
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
}

@-webkit-keyframes rotateInUpRight {
    from {
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes rotateInUpRight {
    from {
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.wp-completely .animate__rotateInUpRight {
    -webkit-animation-name: rotateInUpRight;
    animation-name: rotateInUpRight;
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
}

@-webkit-keyframes rotateOut {
    from {
        opacity: 1;
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 200deg);
        transform: rotate3d(0, 0, 1, 200deg);
        opacity: 0;
    }
}

@keyframes rotateOut {
    from {
        opacity: 1;
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 200deg);
        transform: rotate3d(0, 0, 1, 200deg);
        opacity: 0;
    }
}

.wp-completely .animate__rotateOut {
    -webkit-animation-name: rotateOut;
    animation-name: rotateOut;
    -webkit-transform-origin: center;
    transform-origin: center;
}

@-webkit-keyframes rotateOutDownLeft {
    from {
        opacity: 1;
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0;
    }
}

@keyframes rotateOutDownLeft {
    from {
        opacity: 1;
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0;
    }
}

.wp-completely .animate__rotateOutDownLeft {
    -webkit-animation-name: rotateOutDownLeft;
    animation-name: rotateOutDownLeft;
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
}

@-webkit-keyframes rotateOutDownRight {
    from {
        opacity: 1;
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0;
    }
}

@keyframes rotateOutDownRight {
    from {
        opacity: 1;
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0;
    }
}

.wp-completely .animate__rotateOutDownRight {
    -webkit-animation-name: rotateOutDownRight;
    animation-name: rotateOutDownRight;
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
}

@-webkit-keyframes rotateOutUpLeft {
    from {
        opacity: 1;
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0;
    }
}

@keyframes rotateOutUpLeft {
    from {
        opacity: 1;
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0;
    }
}

.wp-completely .animate__rotateOutUpLeft {
    -webkit-animation-name: rotateOutUpLeft;
    animation-name: rotateOutUpLeft;
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
}

@-webkit-keyframes rotateOutUpRight {
    from {
        opacity: 1;
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg);
        opacity: 0;
    }
}

@keyframes rotateOutUpRight {
    from {
        opacity: 1;
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg);
        opacity: 0;
    }
}

.wp-completely .animate__rotateOutUpRight {
    -webkit-animation-name: rotateOutUpRight;
    animation-name: rotateOutUpRight;
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
}

@-webkit-keyframes hinge {
    0% {
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    20%,
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 80deg);
        transform: rotate3d(0, 0, 1, 80deg);
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    40%,
    80% {
        -webkit-transform: rotate3d(0, 0, 1, 60deg);
        transform: rotate3d(0, 0, 1, 60deg);
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1;
    }

    to {
        -webkit-transform: translate3d(0, 700px, 0);
        transform: translate3d(0, 700px, 0);
        opacity: 0;
    }
}

@keyframes hinge {
    0% {
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    20%,
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 80deg);
        transform: rotate3d(0, 0, 1, 80deg);
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    40%,
    80% {
        -webkit-transform: rotate3d(0, 0, 1, 60deg);
        transform: rotate3d(0, 0, 1, 60deg);
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1;
    }

    to {
        -webkit-transform: translate3d(0, 700px, 0);
        transform: translate3d(0, 700px, 0);
        opacity: 0;
    }
}

.wp-completely .animate__hinge {
    -webkit-animation-duration: calc(1s * 2);
    animation-duration: calc(1s * 2);
    -webkit-animation-duration: calc(var(--animate-duration) * 2);
    animation-duration: calc(var(--animate-duration) * 2);
    -webkit-animation-name: hinge;
    animation-name: hinge;
    -webkit-transform-origin: top left;
    transform-origin: top left;
}

@-webkit-keyframes jackInTheBox {
    from {
        opacity: 0;
        -webkit-transform: scale(0.1) rotate(30deg);
        transform: scale(0.1) rotate(30deg);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
    }

    50% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    70% {
        -webkit-transform: rotate(3deg);
        transform: rotate(3deg);
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes jackInTheBox {
    from {
        opacity: 0;
        -webkit-transform: scale(0.1) rotate(30deg);
        transform: scale(0.1) rotate(30deg);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
    }

    50% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    70% {
        -webkit-transform: rotate(3deg);
        transform: rotate(3deg);
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.wp-completely .animate__jackInTheBox {
    -webkit-animation-name: jackInTheBox;
    animation-name: jackInTheBox;
}

@-webkit-keyframes rollIn {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
        transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes rollIn {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
        transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__rollIn {
    -webkit-animation-name: rollIn;
    animation-name: rollIn;
}

@-webkit-keyframes rollOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
        transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    }
}

@keyframes rollOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
        transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    }
}

.wp-completely .animate__rollOut {
    -webkit-animation-name: rollOut;
    animation-name: rollOut;
}

@-webkit-keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
}

.wp-completely .animate__zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
}

@-webkit-keyframes zoomInDown {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

@keyframes zoomInDown {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

.wp-completely .animate__zoomInDown {
    -webkit-animation-name: zoomInDown;
    animation-name: zoomInDown;
}

@-webkit-keyframes zoomInLeft {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

@keyframes zoomInLeft {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

.wp-completely .animate__zoomInLeft {
    -webkit-animation-name: zoomInLeft;
    animation-name: zoomInLeft;
}

@-webkit-keyframes zoomInRight {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

@keyframes zoomInRight {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

.wp-completely .animate__zoomInRight {
    -webkit-animation-name: zoomInRight;
    animation-name: zoomInRight;
}

@-webkit-keyframes zoomInUp {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

@keyframes zoomInUp {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

.wp-completely .animate__zoomInUp {
    -webkit-animation-name: zoomInUp;
    animation-name: zoomInUp;
}

@-webkit-keyframes zoomOut {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    to {
        opacity: 0;
    }
}

@keyframes zoomOut {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    to {
        opacity: 0;
    }
}

.wp-completely .animate__zoomOut {
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut;
}

@-webkit-keyframes zoomOutDown {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

@keyframes zoomOutDown {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

.wp-completely .animate__zoomOutDown {
    -webkit-animation-name: zoomOutDown;
    animation-name: zoomOutDown;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
}

@-webkit-keyframes zoomOutLeft {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    }

    to {
        opacity: 0;
        -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
        transform: scale(0.1) translate3d(-2000px, 0, 0);
    }
}

@keyframes zoomOutLeft {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    }

    to {
        opacity: 0;
        -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
        transform: scale(0.1) translate3d(-2000px, 0, 0);
    }
}

.wp-completely .animate__zoomOutLeft {
    -webkit-animation-name: zoomOutLeft;
    animation-name: zoomOutLeft;
    -webkit-transform-origin: left center;
    transform-origin: left center;
}

@-webkit-keyframes zoomOutRight {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    }

    to {
        opacity: 0;
        -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
        transform: scale(0.1) translate3d(2000px, 0, 0);
    }
}

@keyframes zoomOutRight {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    }

    to {
        opacity: 0;
        -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
        transform: scale(0.1) translate3d(2000px, 0, 0);
    }
}

.wp-completely .animate__zoomOutRight {
    -webkit-animation-name: zoomOutRight;
    animation-name: zoomOutRight;
    -webkit-transform-origin: right center;
    transform-origin: right center;
}

@-webkit-keyframes zoomOutUp {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

@keyframes zoomOutUp {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}

.wp-completely .animate__zoomOutUp {
    -webkit-animation-name: zoomOutUp;
    animation-name: zoomOutUp;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
}

@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wp-completely .animate__slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

.wp-completely .animate__slideOutDown {
    -webkit-animation-name: slideOutDown;
    animation-name: slideOutDown;
}

@-webkit-keyframes slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

.wp-completely .animate__slideOutLeft {
    -webkit-animation-name: slideOutLeft;
    animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

.wp-completely .animate__slideOutRight {
    -webkit-animation-name: slideOutRight;
    animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

.wp-completely .animate__slideOutUp {
    -webkit-animation-name: slideOutUp;
    animation-name: slideOutUp;
}

#tbanner {
    position: relative;
    overflow: hidden;
    z-index: 199;
    height: 35px;
    line-height: 35px;
}

#tbanner .swiper-slide.off {
    display: none !important;
}

#tbanner .swiper-slide a {
    display: block;
    text-align: center;
    font-size: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 20px;
    text-decoration: none;
}

#tbanner.off {
    display: none !important;
    height: 0 !important;
}

#wrapper {
    transition: all .3s;
}

#wrapper.loading {
    visibility: hidden;
    opacity: 0;
}

.mul13 {
    width: 30px;
    height: 30px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-top: -15px;
    margin-left: -15px;
    transition: opacity .3s linear;
}

.mul13.inactive {
    opacity: 0;
}

.m13s {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    position: absolute;
    opacity: .75;
}

.m13c1 {
    background-color: #68C3A3;
    -webkit-animation: m13s1anim 2s infinite linear;
    animation: m13s1anim 2s infinite linear;
}

.m13c2 {
    background-color: #EC644B;
    -webkit-animation: m13s2anim 2s infinite linear;
    animation: m13s2anim 2s infinite linear;
}

@-webkit-keyframes m13s1anim {

    0%,
    100% {
        -webkit-transform: translateX(-15px);
        transform: translateX(-15px);
    }

    25% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
        opacity: .25;
    }

    50% {
        -webkit-transform: translateX(15px);
        transform: translateX(15px);
    }

    75% {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
        opacity: .8;
    }
}

@keyframes m13s1anim {

    0%,
    100% {
        -webkit-transform: translateX(-15px);
        transform: translateX(-15px);
    }

    25% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
        opacity: .25;
    }

    50% {
        -webkit-transform: translateX(15px);
        transform: translateX(15px);
    }

    75% {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
        opacity: .8;
    }
}

@-webkit-keyframes m13s2anim {

    0%,
    100% {
        -webkit-transform: translateX(15px);
        transform: translateX(15px);
    }

    25% {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
        opacity: .8;
    }

    50% {
        -webkit-transform: translateX(-15px);
        transform: translateX(-15px);
    }

    75% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
        opacity: .25;
    }
}

@keyframes m13s2anim {

    0%,
    100% {
        -webkit-transform: translateX(15px);
        transform: translateX(15px);
    }

    25% {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
        opacity: .8;
    }

    50% {
        -webkit-transform: translateX(-15px);
        transform: translateX(-15px);
    }

    75% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
        opacity: .25;
    }
}

.xans-product-hashtag {
    display: table;
    table-layout: fixed;
    width: 346px;
    margin: 30px auto 0;
    /* font-family: GulimChe, Gulim; */
}

.xans-product-hashtag .heading {
    display: table-cell;
    width: 41px;
    vertical-align: top;
}

.xans-product-hashtag .heading>strong {
    display: inline-block;
    padding: 3px 5px;
    border: 1px solid #cbcbcb;
    color: #525252;
    font-size: 11px;
    font-weight: normal;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
}

.xans-product-hashtag .list {
    display: table-cell;
    width: auto;
    vertical-align: top;
    font-size: 0;
    line-height: 0;
}

.xans-product-hashtag .list li {
    display: inline-block;
    margin: 4px 10px 2px 0;
}

.xans-product-hashtag .list li a {
    display: inline-block;
    word-break: break-all;
    word-wrap: break-word;
    text-decoration: underline;
    color: #525252;
    font-size: 11px;
    line-height: 14px;
}

.xans-coupon-productdetailajax {
    clear: both;
    overflow: hidden;
    border: 1px solid #e8e8e8;
    border-radius: 0px;
}

.xans-coupon-productdetailajax>.title {
    position: relative;
    border-top: 0px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
}

.xans-coupon-productdetailajax>.title h3 {
    padding: 0 0 0 14px;
    font-size: 15px;
    line-height: 45px;
}

.xans-coupon-productdetailajax>.title .button {
    position: absolute;
    top: 4px;
    right: 14px;
    padding: 0 0 0 7px;
    background: #fff;
}

.xans-coupon-productdetailajax>.title .button [class^='btnNormal'] {
    border-radius: 5px;
    padding: 5px 15px;
    font-weight: bold;
    border: 1px solid #111;
}

.xans-coupon-productdetailajax .couponSkinArea {
    padding: 30px 0 0;
    text-align: center;
}

.xans-coupon-productdetailajax .couponSkinArea ul {
    zoom: 1;
    margin: 0 auto;
    padding: 0;
}

.xans-coupon-productdetailajax .couponSkinArea ul:after {
    content: "";
    display: block;
    clear: both;
}

.xans-coupon-productdetailajax .couponSkinArea ul li {
    display: inline-block;
    vertical-align: top;
    width: 216px;
    padding: 0;
    list-style: none;
}

.xans-coupon-productdetailajax .couponSkinArea ul.grid1 {
    width: 216px;
}

.xans-coupon-productdetailajax .couponSkinArea ul.grid2 {
    width: 472px;
}

.xans-coupon-productdetailajax .couponSkinArea ul.grid2 li {
    margin: 0 10px;
}

.xans-coupon-productdetailajax .couponSkinArea ul.grid3 {}

.xans-coupon-productdetailajax .couponSkinArea ul.grid3 li {
    margin: 0 10px;
}

.xans-coupon-productdetailajax .couponSkinArea .coupon {
    width: 216px;
    height: 127px;
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    background-repeat: no-repeat;
}

.xans-coupon-productdetailajax .couponSkinArea .coupon img {
    border: 0;
}

.xans-coupon-productdetailajax .couponSkinArea .coupon p {
    margin: 0;
    padding: 0;
}

.xans-coupon-productdetailajax .couponSkinArea .coupon .title {
    display: block;
    line-height: 15px;
    text-align: center;
}

.xans-coupon-productdetailajax .couponSkinArea .coupon .discount {
    line-height: 28px;
    padding-top: 5px;
    text-align: center;
    text-decoration: none;
    letter-spacing: -1px;
    text-decoration: none;
}

.xans-coupon-productdetailajax .couponSkinArea .coupon .discount span {
    font-size: 22px;
}

.xans-coupon-productdetailajax .couponSkinArea .coupon .period {
    line-height: 13px;
    text-align: center;
}

.xans-coupon-productdetailajax .couponSkinArea .coupon .button {
    margin: 5px 0 0;
    text-align: center;
}

.xans-coupon-productdetailajax .couponSkinArea .coupon .button a {
    display: block;
}

.xans-coupon-productdetailajax .couponSkinArea .imgCoupon .title,
.xans-coupon-productdetailajax .couponSkinArea .imgCoupon .discount,
.xans-coupon-productdetailajax .couponSkinArea .imgCoupon .period,
.xans-coupon-productdetailajax .couponSkinArea .imgCoupon .button {
    display: none;
}

.xans-coupon-productdetailajax .couponSkinArea .imgCoupon .detail {
    height: 100%;
}

.xans-coupon-productdetailajax .couponSkinArea .discount {
    text-decoration: none;
}

#dCouponDetail {
    overflow: hidden;
    position: relative;
    width: 398px;
    border: 1px solid #757575;
    background: #fff;
    box-shadow: 5px 5px 5px #d3d3d3;
}

#dCouponDetail h3 {
    height: 35px;
    padding: 0 35px 0 19px;
    color: #111;
    font-size: 14px;
    line-height: 35px;
    background: #fff;
}

#dCouponDetail h3+a {
    position: absolute;
    right: 20px;
    top: 10px;
}

#dCouponDetail ul {
    padding: 16px 10px 17px 20px;
    margin: 25px 19px;
    background: #f5f5f5;
}

#dCouponDetail ul li {
    margin: 7px 0 0;
    color: #2e2e2e;
    line-height: 18px;
}

#dCouponDetail ul li:first-child {
    margin-top: 0;
}

#dCouponDetail ul+a {
    display: block;
    padding: 12px 0;
    text-align: center;
    border-top: 1px solid #d7d5d5;
    background: #fbfafa;
}

/**
* Template Name: NiceAdmin
* Template URL: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/
* Updated: Apr 20 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/* Card */
.card {
    margin-bottom: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.card-header,
.card-footer {
    border-color: #ebeef4;
    background-color: #fff;
    color: #798eb3;
    padding: 15px;
}

.card-title {
    padding: 20px 0 15px 0;
    font-size: 18px;
    font-weight: 500;
    color: #012970;
    font-family: "Poppins", sans-serif;
}

.card-title span {
    color: #899bbd;
    font-size: 14px;
    font-weight: 400;
}

.card-body {
    padding: 0 20px 20px 20px;
}

.card-img-overlay {
    background-color: rgba(255, 255, 255, 0.6);
}

</style>