<template>
  <div class="searchPill">
    <section id="hero" class="hero d-flex align-items-center">
      <div class="container">
        <div class="row gy-4 d-flex" style="justify-content: center;">
          <div class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center" style="width: 80%;">
            <h2 data-aos="fade-up">病院に訪れなくても購入できる薬をご <br>案内いたします。</h2>
            <p data-aos="fade-up" data-aos-delay="100">該当する症状をクリックすると薬品名と使用法の確認が可能です。
              <br>詳しい使い方や服用方法は薬剤師にお問い合わせください。
            </p>
            <p data-aos="fade-up" data-aos-delay="100">韓国医薬品基準です。</p>


            <div class="frame">
              <button @click="getData('중이')" class="custom-btn btn-7">中耳炎</button>
              <button @click="getData('콧물')" class="custom-btn btn-7">鼻風邪</button>
              <button @click="getData('편도염')" class="custom-btn btn-7">扁桃炎</button>
              <button @click="getData('속쓰림')" class="custom-btn btn-8">下着</button>
              <button @click="getData('진통')" class="custom-btn btn-8">消炎剤</button>
              <!-- <button @click="getData('항생제')" class="custom-btn btn-7">抗生物質</button> -->
              <button @click="getData('발열')" class="custom-btn btn-8">発熱</button>
            </div>

            <div class="sort">
              <button @click="sortData('itemName', 'asc')">昇順</button>
              <button @click="sortData('itemName', 'desc')">降順</button>

             
            </div>

            <!-- <form @submit.prevent="search" class="form-search d-flex align-items-stretch mb-3" data-aos="fade-up"
              data-aos-delay="200">
              <input v-model="searchQuery" type="text" class="form-control" placeholder="작성해주세요.">
              <button type="submit" class="btn btn-primary">검색</button>
            </form> -->


            <!-- 검색 결과 표시 -->
            <div class="row gy-4" data-aos="fade-up" data-aos-delay="400">
              <!-- <div class="col" v-if="searchQuery === ''"> -->
              <div class="col" v-if="paginatedMedications.length > 0">
                <!-- 전체 목록 표시 -->
                <table>
                  <thead>
                    <tr>
                      <!-- <th>품목일련번호</th> -->
                      <th style="width: 200px;">品目名</th>
                      <th style="width: 150px;">会社名</th>
                      <th style="width: 282px;">効能</th>
                      <th>使い方</th>
                      <th style="width: 100px !important; ">イメージ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(medication, index) in paginatedMedications" :key="index">
                      <td class="first-column" v-html="splitText(medication.itemName)"></td>
                      <!-- <td class="first-column" @mouseover="showImage(item)" @mouseleave="hideImage(item)">
                        {{ item.ITEM_NAME }}
                        <img v-if="item.showImage" :src="item.ITEM_IMGE" alt="의약품 사진">
                      </td> -->

                      <td>{{ medication.entpName }}</td>
                      <td>
                        <span v-if="medication.efcyQesitm.length > 100 && !medication.showAll"
                          v-text="medication.efcyQesitm.substring(0, 100) + '...'"></span>
                        <span v-else v-text="medication.efcyQesitm"></span>
                        <button v-if="medication.efcyQesitm.length > 100" @click="toggleShowAll(medication)"
                          class="more-button">{{ medication.showAll ? '간략히 보기' : '더보기' }}</button>  
                      </td>
                      <td>
                        <span v-if="medication.useMethodQesitm.length > 100 && !medication.showAll"
                          v-text="medication.useMethodQesitm.substring(0, 100) + '...'"></span>
                        <span v-else v-text="medication.useMethodQesitm"></span>
                        <button v-if="medication.useMethodQesitm.length > 100" @click="toggleShowAll(medication)"
                          class="more-button">{{ medication.showAll ? '간략히 보기' : '더보기' }}</button>  
                      </td>
                      <td v-if="medication.itemImage">
                        <img :src="medication.itemImage" alt="의약품 사진" style="width: 90px; height: auto;">
                      </td>
                      <td v-else>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col" v-else>
                <!-- 검색 결과 표시 -->
                <div v-if="searchResults.length === 0"></div>
                <div v-else>
                  <table>
                    <thead>
                      <tr>
                        <!-- <th>품목일련번호</th> -->
                        <th class="itemName">품목명</th>
                        <th >업체명</th>
                        <th>효능</th>
                        <th>사용법</th>
                        <th>이미지</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in searchResults" :key="item.itemSeq">
                        <td class="first-column" style="width: 40px !important;">{{ item.itemName }}</td>
                        <!-- <td class="first-column" @mouseover="showImage(item)" @mouseleave="hideImage(item)">
                        {{ item.ITEM_NAME }}
                        <img v-if="item.showImage" :src="item.ITEM_IMGE" alt="의약품 사진">
                      </td> -->
                        <td>{{ item.entpName }}</td>
                        <td>{{ item.efcyQesitm }}</td>
                        <td>{{ item.useMethodQesitm }}</td>
                        <td v-if="item.itemImage">
                          <img :src="item.itemImage" alt="의약품 사진" style="width: 90px; height: auto;">
                        </td>
                        <td v-else>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <!-- <div class="col-lg-5 order-1 order-lg-2 hero-img" data-aos="zoom-out">
          <img src="assets/img/hero-img.svg" class="img-fluid mb-3 mb-lg-0" alt="">
        </div> -->
          </div>
        </div>
      </div>
    </section><!-- End Hero Section -->
    <section>
      <nav aria-label="Page navigation example" style="text-align: center;" v-if="totalPages > 1">
        <ul class="pagination" style="justify-content: center;">
          <li @click="prevPage" class="page-item" :class="{ 'disabled': currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li v-for="page in visiblePages" :key="page" class="page-item" :class="{ 'active': currentPage === page }">
            <a class="page-link" href="#" @click="goToPage(page)">{{ page }}</a>
          </li>
          <!-- <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li> -->
          <li class="page-item" @click="nextPage" :class="{ 'disabled': currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav><!-- End Pagination with icons -->
    </section>
    <p data-aos="fade-up" data-aos-delay="100">ソース | 식품의약품안전처_의약품개요정보</p>
  </div>
</template>
<script>
import 'boxicons/css/boxicons.min.css';
import axios from "axios";
// import {medications} from '@/scripts/medications.js';

export default {
  name: 'searchPill',
  data() {
    return {
      medications: [], //모든 약품 정보 저장하는 배열
      searchQuery: '', //검색어를 저장하는 변수
      searchResults: [], //검색 결과를 저장하는 배열
      currentPage: 1, //현재 페이지 번호
      totalPages: null, // 전체 페이지 수
      pageSize: 50,
      visiblePageRange: 5,
      // isImageVisible: false
      totalCount: 0,
      allMedications: [],
      selectedBtn: '',
      showAll: false,
      sortOrder: 'asc'
    }
  },
  created() {
    // this.fetchMedications();
    // this.fetchAllMedications();
  },
  watch: {

  },
  methods: {
    sortData(column, order) {
      // 컬럼과 정렬 순서를 받아서 데이터를 정렬하는 메서드
      this.medications.sort((a, b) => {
        let comparison = 0;
        if (a[column] > b[column]) {
          comparison = 1;
        } else if (a[column] < b[column]) {
          comparison = -1;
        }
        return order === 'desc' ? comparison * -1 : comparison;
      });
      // 정렬 순서 업데이트
      this.sortOrder = order;
    },
    toggleShowAll(medication) {
      medication.showAll = !medication.showAll;
    },
    splitText(text) {
      // 괄호가 있을 때 괄호 기준으로 문자열을 분리하고 줄바꿈 문자열을 추가합니다.
      if (text.includes('(')) {
        const parts = text.split('(');
        // 일치하는 문자열이면 무시하고 출력하지 않습니다.
        if (parts[1].includes('수출명:HEATWAVE,ARTHROHONLINIMENT,PANOPAS,Y&JWATER)')) {
          return '신신물파스에스';
        }
        if (parts[1].includes('수입명:CHIKUNGHUOSHIUEHKAO)')) {
          return '기공활혈고';
        }
        return parts[0] + '<br>' + '(' + parts[1];
      }
      // 괄호가 없으면 원래 문자열을 그대로 반환합니다.
      return text;
    },
    async getData(keyword, resetPage = true) {
      const API_KEY = "ebOQjFLC07A0KNiw6ti%2BZn4aGMst98sYW27NxaYdRUT3%2B1crHgeuPjFZw%2BA7KnzDPUhXCAR1loP19HwTTubqEQ%3D%3D";

      // API 요청 URL 생성
      // const url = `http://apis.data.go.kr/1471000/DrbEasyDrugInfoService/getDrbEasyDrugList?serviceKey=${API_KEY}&pageNo=${this.currentPage}&startPage=1&numOfRows=${this.pageSize}&efcyQesitm=${keyword}&type=json`;
      this.selectedBtn = keyword;
      const pageSize = 50;

      try {
        this.medications = []; // 데이터를 초기화합니다.
        let totalCount = 0; // 전체 데이터 개수를 저장할 변수를 선언합니다.

        // 첫 번째 페이지의 데이터를 가져와서 전체 데이터 개수를 구합니다.
        const firstPageUrl = `http://apis.data.go.kr/1471000/DrbEasyDrugInfoService/getDrbEasyDrugList?serviceKey=${API_KEY}&pageNo=1&startPage=1&numOfRows=${pageSize}&efcyQesitm=${keyword}&type=json`;
        const firstPageResponse = await axios.get(firstPageUrl);
        if (firstPageResponse.data && firstPageResponse.data.body && firstPageResponse.data.body.totalCount) {
          const totalItems = firstPageResponse.data.body.totalCount;
          const totalItemsPages = Math.ceil(totalItems / pageSize); // 전체 페이지 수를 계산합니다.

          console.log(totalItems);

          // 각 페이지별로 데이터를 가져와서 medications 배열에 추가합니다.
          for (let page = 1; page <= totalItemsPages; page++) {
            const url = `http://apis.data.go.kr/1471000/DrbEasyDrugInfoService/getDrbEasyDrugList?serviceKey=${API_KEY}&pageNo=${page}&startPage=1&numOfRows=${pageSize}&efcyQesitm=${keyword}&type=json`;
            const response = await axios.get(url);

            if (response.data && response.data.body && response.data.body.items) {
              const items = response.data.body.items.filter(item => item.itemImage !== null && item.itemImage !== "");
              this.medications.push(...items);
              totalCount += items.length;
            } else {
              console.error(`데이터가 없거나 오류가 발생했습니다. 페이지: ${page}`);
            }
          }

          // 전체 데이터 개수를 저장합니다.
          this.totalCount = totalCount;
          this.totalPages = Math.ceil(totalCount / pageSize);

          this.keyword = keyword;

          if (resetPage) {
            this.currentPage = 1; // 현재 페이지를 첫 번째 페이지로 설정합니다.
          }

          console.log(this.currentPage);
          console.log(this.medications.length);
          console.log(totalCount);
          console.log(this.medications);
          console.log(this.totalPages);
        } else {
          console.error('첫 번째 페이지의 데이터를 가져오는데 문제가 발생했습니다.');
        }
      } catch (error) {
        console.error('API 요청 중 오류 발생:', error);
      }
    },
    nextPage() {
      //다음 페이지로 이동
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        // this.fetchMedications();
        this.getData(this.keyword, false);
      }
    },
    prevPage() {
      //이전 페이지로 이동
      if (this.currentPage > 1) {
        this.currentPage--;
        // this.fetchMedications();
        this.getData(this.keyword, false);
      }
    },
    goToPage(page) {
      this.currentPage = page;
      this.getData(this.keyword, false);
    },
  },

  computed: {
    paginatedMedications() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.medications.slice(startIndex, endIndex);
    },


    visiblePages() {
      const start = Math.max(1, this.currentPage - this.visiblePageRange);
      const end = Math.min(this.totalPages, this.currentPage + this.visiblePageRange);
      return Array.from({ length: end - start + 1 }, (_, index) => index + start);
    }
  },
}
</script>
<style scoped>
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css');
@import url('@/assets/css/dihApp.css');

.sort {
  text-align: right;
}

.sort button {
  background-color: inherit;
  color: #000103;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin: 10px;
}

.sort button:hover {
  color: #f5c38a;
  /* 호버시 텍스트 색상 변경 */
}

.more-button {
  background-color: inherit;
  color: #d36e10;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin: 0;
}

.frame .selected-btn {
  background-color: transparent;
  /* 선택된 버튼의 배경색 */
  color: #f3ba84;
  /* 선택된 버튼의 텍스트 색상 */
}

.frame .selected-btn:before,
.frame .selected-btn:after {
  height: 0;
  background-color: transparent;
}

.btn-7 {
  background: #f3ba84;
  color: #fff;
  /* line-height: 42px; */
  padding: 0;
  border: none;
  z-index: 1;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.btn-7:hover {
  background: transparent;
  color: #f3ba84;
}

.btn-7:before,
.btn-7:after {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  height: 50%;
  right: 0;
  z-index: -1;
  background: #f3ba84;
  transition: all 0.3s ease;
}

.btn-7:before {
  top: 0;
}

.btn-7:after {
  bottom: 0;
}

.btn-7:hover:before,
.btn-7:hover:after {
  height: 0;
  background-color: #f3ba84;
}

.btn-8 {
  background: #f1d586;
  color: #fff;
  /* line-height: 42px; */
  padding: 0;
  border: none;
  z-index: 1;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.btn-8:hover {
  background: transparent;
  color: #f1d586;
}

.btn-8:before,
.btn-8:after {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  height: 50%;
  right: 0;
  z-index: -1;
  background: #f1d586;
  transition: all 0.3s ease;
}

.btn-8:before {
  top: 0;
}

.btn-8:after {
  bottom: 0;
}

.btn-8:hover:before,
.btn-8:hover:after {
  height: 0;
  background-color: #f1d586;
}

.frame {
  width: 100%;
  margin-bottom: 40px;
  text-align: left;
}

button {
  margin: 20px;
  outline: none;
}

.custom-btn {
  width: 130px;
  height: 40px;
  padding: 10px 25px;
  background: transparent;
  cursor: pointer;
  transition: all 0.1s ease;
  position: relative;
  display: inline-block;
}

table {
  border: 1px #a39485 solid;
  font-size: .9em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .25);
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
}

th {
  text-align: left;
}

thead {
  /* font-weight: bold; */
  color: #5e554c;
  background: #c9b29c;
}

td,
th {
  padding: 1em .5em;
  vertical-align: middle;
}

td {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  background: #fff;
}

a {
  color: #73685d;
}

@media all and (max-width: 768px) {

  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  th {
    text-align: right;
  }

  table {
    position: relative;
    padding-bottom: 0;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  }

  thead {
    float: left;
    white-space: nowrap;
  }

  tbody {
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    white-space: nowrap;
  }

  tr {
    display: inline-block;
    vertical-align: top;
  }

  th {
    border-bottom: 1px solid #a39485;
  }

  td {
    border-bottom: 1px solid #e5e5e5;
  }


}

.first-column {
  color: #ee9e4f;
  /* font-weight: bold; */
}

/**
* Template Name: Logis
* Template URL: https://bootstrapmade.com/logis-bootstrap-logistics-website-template/
* Updated: Mar 17 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Set main reusable colors and fonts using CSS variables
# Learn more about CSS variables at https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
--------------------------------------------------------------*/
/* Fonts */

/* Colors */
:root {
  --color-default: #0a0d13;
  --color-primary: #0d42ff;
  --color-secondary: #0e1d34;
}

/* Smooth scroll behavior */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  /* font-family: var(--font-default); */
  /* color: var(--color-default); */
}

a {
  /* color: var(--color-primary); */
  text-decoration: none;
}

a:hover {
  color: #406aff;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs .page-header {
  padding: 140px 0 80px 0;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.breadcrumbs .page-header:before {
  content: "";
  background-color: rgba(14, 29, 52, 0.8);
  position: absolute;
  inset: 0;
}

.breadcrumbs .page-header h2 {
  font-size: 56px;
  font-weight: 500;
  color: #fff;
  font-family: var(--font-secondary);
}

.breadcrumbs .page-header p {
  color: rgba(255, 255, 255, 0.8);
}

.breadcrumbs nav {
  background-color: #f3f6fc;
  padding: 20px 0;
}

.breadcrumbs nav ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-secondary);
}

.breadcrumbs nav ol a {
  color: var(--color-primary);
  transition: 0.3s;
}

.breadcrumbs nav ol a:hover {
  text-decoration: underline;
}

.breadcrumbs nav ol li+li {
  padding-left: 10px;
}

.breadcrumbs nav ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #4278cc;
  content: "/";
}

/*--------------------------------------------------------------
# Scroll top button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: var(--color-primary);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.scroll-top:hover {
  background: rgba(13, 66, 255, 0.8);
  color: #fff;
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
  transition: all 0.6s ease-out;
  width: 100%;
  height: 100vh;
}

#preloader:before,
#preloader:after {
  content: "";
  position: absolute;
  border: 4px solid var(--color-primary);
  border-radius: 50%;
  animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader:after {
  animation-delay: -0.5s;
}

@keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  transition: all 0.5s;
  z-index: 997;
  padding: 30px 0;
}

.header.sticked {
  background: rgba(14, 29, 52, 0.9);
  padding: 15px 0;
  box-shadow: 0px 2px 20px rgba(14, 29, 52, 0.1);
}

.header .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.header .logo h1 {
  font-size: 30px;
  margin: 0;
  font-weight: 700;
  color: #fff;
  font-family: var(--font-primary);
}

/*--------------------------------------------------------------
# Desktop Navigation
--------------------------------------------------------------*/
@media (min-width: 1280px) {
  .navbar {
    padding: 0;
  }

  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navbar li {
    position: relative;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-family: var(--font-primary);
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: #fff;
  }

  .navbar .get-a-quote,
  .navbar .get-a-quote:focus {
    background: var(--color-primary);
    padding: 8px 20px;
    margin-left: 30px;
    border-radius: 4px;
    color: #fff;
  }

  .navbar .get-a-quote:hover,
  .navbar .get-a-quote:focus:hover {
    color: #fff;
    background: #2756ff;
  }

  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
  }

  .navbar .dropdown ul li {
    min-width: 200px;
  }

  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 400;
    color: var(--color-secondary);
  }

  .navbar .dropdown ul a i {
    font-size: 12px;
  }

  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: var(--color-primary);
  }

  .navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }

  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

@media (min-width: 1280px) {

  .mobile-nav-show,
  .mobile-nav-hide {
    display: none;
  }
}

/*--------------------------------------------------------------
# Mobile Navigation
--------------------------------------------------------------*/
@media (max-width: 1279px) {
  .navbar {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    max-width: 400px;
    bottom: 0;
    transition: 0.3s;
    z-index: 9997;
  }

  .navbar ul {
    position: absolute;
    inset: 0;
    padding: 50px 0 10px 0;
    margin: 0;
    background: rgba(14, 29, 52, 0.9);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    font-family: var(--font-primary);
    font-size: 16px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.7);
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: #fff;
  }

  .navbar .get-a-quote,
  .navbar .get-a-quote:focus {
    background: var(--color-primary);
    padding: 8px 20px;
    border-radius: 4px;
    margin: 15px;
    color: #fff;
  }

  .navbar .get-a-quote:hover,
  .navbar .get-a-quote:focus:hover {
    color: #fff;
    background: rgba(13, 66, 255, 0.8);
  }

  .navbar .dropdown ul,
  .navbar .dropdown .dropdown ul {
    position: static;
    display: none;
    padding: 10px 0;
    margin: 10px 20px;
    transition: all 0.5s ease-in-out;
    border: 1px solid #19335c;
  }

  .navbar .dropdown>.dropdown-active,
  .navbar .dropdown .dropdown>.dropdown-active {
    display: block;
  }

  .mobile-nav-show {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    z-index: 9999;
    margin-right: 10px;
  }

  .mobile-nav-hide {
    color: #fff;
    font-size: 32px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 9999;
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .navbar {
    right: 0;
  }

  .mobile-nav-active .navbar:before {
    content: "";
    position: fixed;
    inset: 0;
    background: rgba(14, 29, 52, 0.8);
    z-index: 9996;
  }
}

/*--------------------------------------------------------------
# Featured Services Section
--------------------------------------------------------------*/
.featured-services .service-item {
  position: relative;
  height: 100%;
  margin-bottom: 20px;
}

.featured-services .service-item .icon {
  margin-right: 20px;
}

.featured-services .service-item .icon i {
  color: #001f8d;
  font-size: 48px;
  transition: 0.3s;
}

.featured-services .service-item:hover .icon {
  background: #fff;
}

.featured-services .service-item:hover .icon i {
  color: var(--color-primary);
}

.featured-services .service-item .title {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 20px;
  color: var(--color-secondary);
  transition: 0.3s;
}

.featured-services .service-item .description {
  font-size: 15px;
  color: #132848;
  margin-bottom: 10px;
}

.featured-services .service-item .readmore {
  display: flex;
  align-items: center;
  color: var(--color-secondary);
  transition: 0.3s;
  font-weight: 700;
  font-size: 14px;
}

.featured-services .service-item .readmore i {
  margin-left: 8px;
}

.featured-services .service-item:hover .title,
.featured-services .service-item:hover .readmore,
.featured-services .service-item:hover .icon i {
  color: var(--color-primary);
}

/*--------------------------------------------------------------
# About Us Section
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 32px;
  font-family: var(--font-secondary);
  color: #001f8d;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
}

.about .content ul i {
  flex-shrink: 0;
  font-size: 48px;
  color: var(--color-primary);
  margin-right: 20px;
  line-height: 0;
}

.about .content ul h5 {
  font-size: 18px;
  font-weight: 700;
  color: #19335c;
}

.about .content ul p {
  font-size: 15px;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(var(--color-primary) 50%, rgba(13, 66, 255, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.about .play-btn:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(13, 66, 255, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about .play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about .play-btn:hover:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

.about .play-btn:hover:after {
  border-left: 15px solid var(--color-primary);
  transform: scale(20);
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Services Section
--------------------------------------------------------------*/
.services .card {
  border: 1px solid rgba(14, 29, 52, 0.15);
  background: #fff;
  position: relative;
  border-radius: 0;
  height: 100%;
}

.services .card .card-img {
  overflow: hidden;
  margin-bottom: 15px;
  border-radius: 0;
}

.services .card .card-img img {
  transition: 0.3s ease-in-out;
}

.services .card h3 {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 5px;
  padding: 10px 30px;
  text-transform: uppercase;
}

.services .card a {
  color: #001973;
  transition: 0.3;
}

.services .card a:hover {
  color: var(--color-primary);
}

.services .card p {
  padding: 0 30px;
  margin-bottom: 30px;
  color: var(--color-secondary);
  font-size: 15px;
}

.services .card:hover .card-img img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Call To Action Section
--------------------------------------------------------------*/
.call-to-action {
  /* background: linear-gradient(rgba(14, 29, 52, 0.6), rgba(14, 29, 52, 0.8)), url("../img/cta-bg.jpg") center center; */
  background-size: cover;
  padding: 100px 0;
}

@media (min-width: 1365px) {
  .call-to-action {
    background-attachment: fixed;
  }
}

.call-to-action h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.call-to-action p {
  color: #fff;
}

.call-to-action .cta-btn {
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 5px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.call-to-action .cta-btn:hover {
  background: var(--color-primary);
  border: 2px solid var(--color-primary);
}

/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.features {
  padding-top: 80px;
}

.features .features-item+.features-item {
  margin-top: 100px;
}

@media (max-width: 640px) {
  .features .features-item+.features-item {
    margin-top: 40px;
  }
}

.features .features-item h3 {
  font-weight: 600;
  font-size: 26px;
  color: #001973;
}

.features .features-item ul {
  list-style: none;
  padding: 0;
}

.features .features-item ul li {
  padding-bottom: 10px;
}

.features .features-item ul li:last-child {
  padding-bottom: 0;
}

.features .features-item ul i {
  font-size: 20px;
  padding-right: 4px;
  color: var(--color-primary);
}

.features .features-item p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Pricing Section
--------------------------------------------------------------*/
.pricing .pricing-item {
  padding: 60px 40px;
  box-shadow: 0 3px 20px -2px rgba(108, 117, 125, 0.15);
  background: #fff;
  height: 100%;
  border-top: 4px solid #fff;
  border-radius: 5px;
}

.pricing h3 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 20px;
  color: var(--color-secondary);
}

.pricing h4 {
  font-size: 48px;
  color: var(--color-primary);
  font-weight: 400;
  font-family: var(--font-primary);
  margin-bottom: 25px;
}

.pricing h4 sup {
  font-size: 28px;
}

.pricing h4 span {
  color: rgba(108, 117, 125, 0.8);
  font-size: 18px;
}

.pricing ul {
  padding: 20px 0;
  list-style: none;
  color: #6c757d;
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.pricing ul i {
  color: #059652;
  font-size: 24px;
  padding-right: 3px;
}

.pricing ul .na {
  color: rgba(108, 117, 125, 0.5);
}

.pricing ul .na i {
  color: rgba(108, 117, 125, 0.5);
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .buy-btn {
  display: inline-block;
  padding: 12px 35px;
  border-radius: 4px;
  color: var(--color-primary);
  transition: none;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font-primary);
  transition: 0.3s;
  border: 1px solid var(--color-primary);
}

.pricing .buy-btn:hover {
  background: var(--color-primary);
  color: #fff;
}

.pricing .featured {
  border-top-color: var(--color-primary);
}

.pricing .featured .buy-btn {
  background: var(--color-primary);
  color: #fff;
}

@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
# Frequently Asked Questions Section
--------------------------------------------------------------*/
.faq {
  padding-top: 80px;
}

.faq .accordion-item {
  border: 0;
  margin-bottom: 10px;
}

.faq .accordion-collapse {
  border: 0;
}

.faq .accordion-button {
  padding: 15px 30px 20px 60px;
  font-weight: 600;
  border: 0;
  font-size: 18px;
  color: var(--color-default);
  text-align: left;
  background: rgba(14, 29, 52, 0.03);
  border-radius: 5px;
}

.faq .accordion-button:focus {
  box-shadow: none;
}

.faq .accordion-button:not(.collapsed) {
  color: var(--color-primary);
  border-bottom: 0;
  box-shadow: none;
}

.faq .question-icon {
  position: absolute;
  top: 14px;
  left: 25px;
  font-size: 20px;
  color: var(--color-primary);
}

.faq .accordion-button:after {
  position: absolute;
  right: 15px;
  top: 15px;
}

.faq .accordion-body {
  padding: 0 30px 25px 60px;
  border: 0;
  background: rgba(14, 29, 52, 0.03);
  border-radius: 5px;
}

/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.testimonials {
  padding: 80px 0;
  /* background: url("../img/testimonials-bg.jpg") no-repeat; */
  background-position: center center;
  background-size: cover;
  position: relative;
}

.testimonials::before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(14, 29, 52, 0.7);
}

.testimonials .section-header {
  margin-bottom: 40px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  text-align: center;
  color: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 100px;
  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.15);
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #fff;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  margin: 0 0 15px 0;
}

.testimonials .testimonial-item .stars {
  margin-bottom: 15px;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: rgba(255, 255, 255, 0.6);
  font-size: 26px;
  line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.4);
  opacity: 0.5;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #fff;
  opacity: 1;
}

@media (min-width: 992px) {
  .testimonials .testimonial-item p {
    width: 80%;
  }
}

/*--------------------------------------------------------------
# Stats Counter Section
--------------------------------------------------------------*/
.stats-counter .stats-item {
  padding: 30px;
  width: 100%;
}

.stats-counter .stats-item span {
  font-size: 48px;
  display: block;
  color: var(--color-secondary);
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.stats-counter .stats-item span:after {
  content: "";
  position: absolute;
  display: block;
  width: 25px;
  height: 3px;
  background: var(--color-primary);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.stats-counter .stats-item p {
  padding: 0;
  margin: 0;
  font-family: var(--font-primary);
  font-size: 15px;
  font-weight: 500;
  color: var(--color-secondary);
}

/*--------------------------------------------------------------
# Our Team Section
--------------------------------------------------------------*/
.team .member {
  text-align: center;
  margin-bottom: 20px;
  background: #fff;
  border: 1px solid rgba(14, 29, 52, 0.15);
}

.team .member img {
  margin: -1px -1px 30px -1px;
}

.team .member .member-content {
  padding: 0 20px 30px 20px;
}

.team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
}

.team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
  color: #6c757d;
}

.team .member p {
  padding-top: 10px;
  font-size: 14px;
  font-style: italic;
  color: #6c757d;
}

.team .member .social {
  margin-top: 15px;
}

.team .member .social a {
  color: rgba(14, 29, 52, 0.5);
  transition: 0.3s;
}

.team .member .social a:hover {
  color: var(--color-primary);
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/*--------------------------------------------------------------
# Horizontal Pricing Section
--------------------------------------------------------------*/
.horizontal-pricing .pricing-item {
  box-shadow: 0 3px 20px -2px rgba(108, 117, 125, 0.15);
  padding-bottom: 30px;
  background: #fff;
  height: 100%;
  position: relative;
}

.horizontal-pricing h3 {
  font-weight: 700;
  margin-bottom: 0;
  font-size: 24px;
  color: var(--color-secondary);
}

.horizontal-pricing h4 {
  font-size: 48px;
  color: var(--color-primary);
  font-weight: 400;
  font-family: var(--font-primary);
  margin-bottom: 25px;
  text-align: center;
}

.horizontal-pricing h4 sup {
  font-size: 28px;
}

.horizontal-pricing h4 span {
  color: rgba(108, 117, 125, 0.8);
  font-size: 18px;
}

.horizontal-pricing ul {
  padding: 20px 0;
  list-style: none;
  color: #6c757d;
  text-align: left;
  line-height: 20px;
}

.horizontal-pricing ul li {
  padding-top: 15px;
  display: flex;
  align-items: center;
}

.horizontal-pricing ul i {
  color: #059652;
  font-size: 24px;
  padding-right: 3px;
}

.horizontal-pricing ul .na {
  color: rgba(108, 117, 125, 0.5);
}

.horizontal-pricing ul .na i {
  color: rgba(108, 117, 125, 0.5);
}

.horizontal-pricing ul .na span {
  text-decoration: line-through;
}

.horizontal-pricing .buy-btn {
  display: inline-block;
  padding: 12px 40px;
  border-radius: 4px;
  color: #fff;
  background-color: var(--color-primary);
  border: 2px solid var(--color-primary);
  transition: none;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
  font-family: var(--font-primary);
}

.horizontal-pricing .buy-btn:hover {
  background: #406aff;
  border-color: #406aff;
}

.horizontal-pricing .featured {
  background: var(--color-primary);
}

.horizontal-pricing .featured h3,
.horizontal-pricing .featured h4,
.horizontal-pricing .featured h4 span,
.horizontal-pricing .featured ul,
.horizontal-pricing .featured ul .na,
.horizontal-pricing .featured ul i,
.horizontal-pricing .featured ul .na i {
  color: #fff;
}

.horizontal-pricing .featured .buy-btn {
  background: var(--color-primary);
  color: #fff;
  border-color: #fff;
}

.horizontal-pricing .featured .buy-btn:hover {
  background: #fff;
  color: var(--color-primary);
}

/*--------------------------------------------------------------
# Get a Quote Section
--------------------------------------------------------------*/
.get-a-quote .quote-bg {
  min-height: 500px;
  background-size: cover;
  background-position: center;
}

.get-a-quote .php-email-form {
  background: #f3f6fc;
  padding: 40px;
  height: 100%;
}

@media (max-width: 575px) {
  .get-a-quote .php-email-form {
    padding: 20px;
  }
}

.get-a-quote .php-email-form h3 {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.get-a-quote .php-email-form h4 {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 20px 0 0 0;
}

.get-a-quote .php-email-form p {
  font-size: 14px;
  margin-bottom: 20px;
}

.get-a-quote .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #df1529;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.get-a-quote .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #059652;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.get-a-quote .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.get-a-quote .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #059652;
  border-top-color: #fff;
  animation: animate-loading 1s linear infinite;
}

.get-a-quote .php-email-form input,
.get-a-quote .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 0;
}

.get-a-quote .php-email-form input:focus,
.get-a-quote .php-email-form textarea:focus {
  border-color: var(--color-primary);
}

.get-a-quote .php-email-form input {
  padding: 12px 15px;
}

.get-a-quote .php-email-form textarea {
  padding: 12px 15px;
}

.get-a-quote .php-email-form button[type=submit] {
  background: var(--color-primary);
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.get-a-quote .php-email-form button[type=submit]:hover {
  background: rgba(13, 66, 255, 0.8);
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-item+.info-item {
  margin-top: 40px;
}

.contact .info-item i {
  font-size: 20px;
  background: var(--color-primary);
  color: #fff;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
}

.contact .info-item h4 {
  padding: 0;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  color: var(--color-secondary);
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: rgba(14, 29, 52, 0.8);
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #df1529;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #059652;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #059652;
  border-top-color: #fff;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: var(--color-primary);
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 5px;
}

.contact .php-email-form button[type=submit]:hover {
  background: rgba(13, 66, 255, 0.8);
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Service Details Section
--------------------------------------------------------------*/
.service-details .services-list {
  padding: 10px 30px;
  border: 1px solid #d3dff3;
  margin-bottom: 20px;
}

.service-details .services-list a {
  display: block;
  line-height: 1;
  padding: 8px 0 8px 15px;
  border-left: 3px solid #c2cbdf;
  margin: 20px 0;
  color: var(--color-secondary);
  transition: 0.3s;
}

.service-details .services-list a.active {
  font-weight: 700;
  border-color: var(--color-primary);
}

.service-details .services-list a:hover {
  border-color: var(--color-primary);
}

.service-details .services-img {
  margin-bottom: 20px;
}

.service-details h3 {
  font-size: 28px;
  font-weight: 700;
  color: var(--color-secondary);
}

.service-details h4 {
  font-size: 20px;
  font-weight: 700;
  color: var(--color-secondary);
}

.service-details p {
  font-size: 15px;
}

.service-details ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.service-details ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.service-details ul i {
  font-size: 20px;
  margin-right: 8px;
  color: var(--color-primary);
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  min-height: 80vh;
  background-color: none;
  /* background-image: url("../img/hero-bg.png"); */
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 120px 0 60px 0;
}

.hero h2 {
  margin-bottom: 20px;
  padding: 0;
  font-size: 48px;
  font-weight: 700;
}

@media (max-width: 575px) {
  .hero h2 {
    font-size: 30px;
  }
}

.hero p {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 40px;
}

.hero form {
  padding: 10px;
  border-radius: 10px;
}

.hero form .form-control {
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  margin-right: 10px;
  border: none !important;
  background: #fcf1dc !important;
}

.hero form .form-control:hover,
.hero form .form-control:focus {
  outline: none;
  box-shadow: none;
}

.hero form .btn-primary {
  background-color: var(--color-primary);
  padding: 15px 30px;
  border-color: #F5DEB3;
  color: black;
}

.hero form .btn-primary:hover {
  background-color: #F5DEB3;
}

.hero .stats-item {
  padding: 30px;
  width: 100%;
}

.hero .stats-item span {
  font-size: 32px;
  display: block;
  font-weight: 700;
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
}

.hero .stats-item span:after {
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 3px;
  background: var(--color-primary);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.hero .stats-item p {
  padding: 0;
  margin: 0;
  font-family: var(--font-primary);
  font-size: 15px;
  font-weight: 600;
}
</style>