export const question = [
    {qnaNum : 1, qnaProduct : '', category : '交換/キャンセル/返品', qnaTitle : '交換してください', qnaWriter : 'ホン·ギルドン', qnaDate : '2024-04-25', qnaContent : '新しいものと交換してください'},
    {qnaNum : 2, qnaProduct : '', category : '拝送', qnaTitle : '配送が来ないんです', qnaWriter : 'ホン·ギルドン', qnaDate : '2024-04-26', qnaContent : '早く送ってください'},
    {qnaNum : 3, qnaProduct : '', category : 'その他', qnaTitle : 'その他のお問い合わせ', qnaWriter : 'ホン·ギルドン', qnaDate : '2024-04-26', qnaContent : 'その他のお問い合わせ'},
    {qnaNum : 4, qnaProduct : '', category : '商品', qnaTitle : '商品のお問い合わせ', qnaWriter : 'ホン·ギルドン', qnaDate : '2024-04-26', qnaContent : '商品のお問い合わせ'},
    // {qnaNum : 5, qnaProduct : '', category : '상품문의', qnaTitle : '상품문의2', qnaWriter : '홍길동', qnaDate : '2024-04-26', qnaContent : '상품 문의입니다'},
    // {qnaNum : 6, qnaProduct : '', category : '상품문의', qnaTitle : '상품문의3', qnaWriter : '홍길동', qnaDate : '2024-04-26', qnaContent : '상품 문의입니다'},
]

// export function saveQuestion(question) {
//     question.qnaNum = questions.length + 1; // 다음 번호를 현재 배열의 길이 + 1로 설정
//     questions.push(question);
// }