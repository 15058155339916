<template>
    <div class="questionDetail">
        <section id="hero" class="d-flex align-items-center justify-content-center">
            <div class="container" data-aos="fade-up">

                <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
                    <div class="col-xl-6 col-lg-8">
                        <h1>お問い合わせ</h1>
                        <h2>製品およびその他のお問い合わせ掲示板です。</h2>
                    </div>
                </div>

            </div>
        </section>
        <section id="faq" class="faq section-bg-noti">
      <div class="container" data-aos="fade-up">

        <div class="section-title-noti">

          <!-- <h2>자주 묻는 질문들</h2>
      <p>스마트 체온계와 닥터인홈의 궁금증을 해결해드립니다.</p> -->
        </div>

        <div class="notice-table">
          <div class="row">
            <div class="card">
              <div class="card-body">
                <!-- <h5 class="card-title">Table with hoverable rows</h5> -->

                <!-- Table with hoverable rows -->
                <table class="table">
                    <thead>
                        <tr>
                            <th colspan="2">商品情報</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ question.qnaProduct }}</td>
                            <td style="width: 170px;">{{ question.category }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table">
                  <thead>
                    <tr>
                      <!-- <th scope="col">번호</th> -->
                      <th scope="col" colspan="4" style="border-bottom: none; font-size: 17px; color: #5777ba;">{{ question.qnaTitle }}</th>
                    </tr>
                    <ul class="etcArea" style="border-bottom: solid 1px; border-color: gainsboro;">
                      <li>
                        <span class="txt">{{ question.qnaWriter }}</span>
                      </li>
                      <li>
                        <span class="txt">{{ question.qnaDate }}</span>
                      </li>
                    </ul>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="4">{{ question.qnaContent }}</td>
                    </tr>
                  </tbody>
                </table>
                <!-- End Table with hoverable rows -->

              </div>
            </div>
          </div>
        </div>
        <div class="qnaList" style="text-align: center; margin: 20px;">
            <router-link to="/question" class="qnaList-btn">リスト</router-link>
        </div>
      </div>
      
    </section>
    
    </div>
</template>
<script>
import { question } from '@/scripts/question.js';

export default {
    name: 'questionDetail',
    data() {
        return {
            question: {}
        };
    },
    created() {
        this.loadQuestion();
    },
    methods: {
        async loadQuestion() {
            // 라우트 파라미터에서 공지사항 번호를 가져온다
            const qnaNum = this.$route.params.qnaNum;
            console.log(this.$route.params.qnaNum);
            // notice 데이터를 가져와서 해당 번호의 공지사항을 찾음
            this.question = question.find(item => item.qnaNum === parseInt(qnaNum));

            // 로그를 찍어서 데이터가 올바르게 가져와졌는지 확인
            console.log(this.question);
        }
    }
}
</script>
<style scoped>
.etcArea li {
  display: inline-block;
  padding: 10px !important;
  padding-top: 0 !important;
  font-size: 15px;
}

.qnaList-btn {
    text-decoration: none;
    color: #5777ba;
}

.qnaList-btn:hover {
  color: #2c74df; /* 호버시 텍스트 색상 변경 */
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .notice-table {
  padding: 0 100px;
}

.faq .notice-table ul {
  padding: 0;
  list-style: none;
}

.faq .notice-table li+li {
  margin-top: 15px;
}

.faq .notice-table li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .notice-table a {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}


.faq .notice-table p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

@media (max-width: 1200px) {
  .faq .accordion-list {
    padding: 0;
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section-noti {
    padding: 60px 0;
    overflow: hidden;
}

.section-bg-noti {
    background-color: #fff;
    padding: 60px 0;
}

.section-title-noti {
    /* text-align: center; */
    padding-bottom: 30px;
}

.section-title-noti h2 {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #5777ba;
}

.section-title-noti p {
    margin-bottom: 0;
}



/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .notice-table {
    padding: 0 100px;
}

.faq .notice-table ul {
    padding: 0;
    list-style: none;
}

.faq .notice-table li+li {
    margin-top: 15px;
}

.faq .notice-table li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    position: relative;
}

.faq .notice-table a {
    display: block;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 0 30px;
    outline: none;
    cursor: pointer;
}


.faq .notice-table p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
}

@media (max-width: 1200px) {
    .faq .accordion-list {
        padding: 0;
    }
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    min-height: 30vh;
    /* background: url("@/assets/images/intro-3.png") top center; */
    background-color: #f2f5fa;
    background-size: cover;
    position: relative;
}

#hero:before {
    content: "";
    /* background: rgba(0, 0, 0, 0.1); */
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#hero .container {
    position: relative;
    padding-top: 10px;
    text-align: center;
}

#hero h1 {
    margin: 0;
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
    color: #5777ba;
}

#hero h1 span {
    color: #5777ba;
}

#hero h2 {
    color: rgba(86, 108, 110, 0.9);
    margin: 10px 0 0 0;
    font-size: 24px;
}

#hero .icon-box {
    padding: 30px 20px;
    transition: ease-in-out 0.3s;
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 100%;
    text-align: center;
}

#hero .icon-box i {
    font-size: 32px;
    line-height: 1;
    color: #5777ba;
}

#hero .icon-box h3 {
    font-weight: 700;
    margin: 10px 0 0 0;
    padding: 0;
    line-height: 1;
    font-size: 20px;
    line-height: 26px;
}

#hero .icon-box h3 a {
    color: #fff;
    transition: ease-in-out 0.3s;
}

#hero .icon-box h3 a:hover {
    color: #5777ba;
}

#hero .icon-box:hover {
    border-color: #5777ba;
}

@media (min-width: 1024px) {
    #hero {
        background-attachment: fixed;
    }
}

@media (max-width: 768px) {
    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 20px;
        line-height: 24px;
    }
}
</style>