<template>
  <div class="notice">
    <section id="hero" class="d-flex align-items-center justify-content-center">
      <div class="container" data-aos="fade-up">

        <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
          <div class="col-xl-6 col-lg-8">
            <h1>お知らせ</h1>
            <h2>イベントとお知らせです。</h2>
          </div>
        </div>

      </div>
    </section><!-- End Hero -->
    <section id="faq" class="faq section-bg-noti">
      <div class="container" data-aos="fade-up">

        <div class="section-title-noti">

          <!-- <h2>자주 묻는 질문들</h2>
      <p>스마트 체온계와 닥터인홈의 궁금증을 해결해드립니다.</p> -->
        </div>

        <div class="notice-table">
          <div class="row">
            <div class="card">
              <div class="card-body">
                <!-- <h5 class="card-title">Table with hoverable rows</h5> -->

                <!-- Table with hoverable rows -->
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">番号</th>
                      <th scope="col">タイトル</th>
                      <th scope="col">作者</th>
                      <th scope="col">日付</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <tr>
                    <th scope="row">1</th>
                    <td>Brandon Jacob</td>
                    <td>Designer</td>
                    <td>28</td>
                    <td>2016-05-25</td>
                  </tr> -->
                    <tr v-for="notice in paginatedNotice.slice().reverse()" :key="notice.noticeNum">
                      <td scope="row">{{ notice.noticeNum }}</td>
                      <td><a href="/notice/noticeDetail/${noticeNum}"
                          @click.prevent="redirectToDetail(notice.noticeNum)">{{notice.noticeTitle}}</a></td>
                      <td>{{ notice.noticeWriter}}</td>
                      <!-- <td>{{notice.noticeRdcnt}}</td> -->
                      <td>{{ notice.noticeDate }}</td>
                    </tr>
                  </tbody>
                </table>
                <!-- End Table with hoverable rows -->

              </div>
            </div>
          </div>
        </div>

      </div>
    </section><!-- End Frequently Asked Questions Section -->
    <section>
      <nav aria-label="Page navigation example" style="text-align: center;" v-if="totalPages > 1">
        <ul class="pagination" style="justify-content: center;">
          <li @click="prevPage" class="page-item" :class="{ 'disabled': currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li v-for="page in visiblePages" :key="page" class="page-item" :class="{ 'active': currentPage === page }">
            <a class="page-link" href="#" @click="goToPage(page)">{{ page }}</a>
          </li>
          <!-- <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li> -->
          <li class="page-item" @click="nextPage" :class="{ 'disabled': currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav><!-- End Pagination with icons -->
    </section>
  </div>
</template>
<script>
import { notice } from '@/scripts/notice.js';

export default {
  name: 'notice',
  data() {
    return {
      notice: notice,
      itemsPerPage: 5, // 한 페이지에 표시할 항목 수
      currentPage: 1 // 현재 페이지 번호
    };
  },
  computed: {
    // 현재 페이지에 해당하는 데이터만 반환하는 computed property
    paginatedNotice() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            const reversedNotice = this.notice.slice().reverse(); // 역순으로 데이터를 가져옴
            const paginatedReversedNotice = reversedNotice.slice(startIndex, endIndex);
            return paginatedReversedNotice.reverse(); // 페이지에 표시될 데이터를 다시 역순으로 되돌림
        },
    // 전체 페이지 수를 계산하는 computed property
    totalPages() {
      return Math.ceil(this.notice.length / this.itemsPerPage);
    },
    // 페이지네이션에 표시될 페이지 번호들을 계산하는 computed property
    visiblePages() {
      const maxVisiblePages = 5; // 표시할 최대 페이지 수
      let startPage = Math.max(1, this.currentPage - Math.floor(maxVisiblePages / 2));
      const endPage = Math.min(this.totalPages, startPage + maxVisiblePages - 1);

      // startPage가 1보다 작으면 endPage를 조정하여 페이지 수가 maxVisiblePages만큼 유지되도록 함
      const offset = Math.max(0, 1 - startPage);
      startPage += offset;

      // startPage부터 endPage까지의 배열 생성
      return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
    }
  },
  methods: {
    redirectToDetail(noticeNum) {
      const detailUrl = `/notice/noticeDetail/${noticeNum}`;
      console.log(noticeNum);
      notice.noticeRdcnt++;
      console.log(notice.noticeRdcnt);
      window.location.href = detailUrl;
    },
    // 이전 페이지로 이동하는 메소드
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    // 다음 페이지로 이동하는 메소드
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    // 특정 페이지로 이동하는 메소드
    goToPage(page) {
      this.currentPage = page;

    },
  }
}
</script>
<style scoped>
body {
  color: #444444;
}

a {
  color: #ffc451;
  text-decoration: none;
}

a:hover {
  color: #ffd584;
  text-decoration: none;
}


/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .notice-table {
  padding: 0 100px;
}

.faq .notice-table ul {
  padding: 0;
  list-style: none;
}

.faq .notice-table li+li {
  margin-top: 15px;
}

.faq .notice-table li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .notice-table a {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}


.faq .notice-table p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

@media (max-width: 1200px) {
  .faq .accordion-list {
    padding: 0;
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section-noti {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg-noti {
  background-color: #fff;
}

.section-title-noti {
  text-align: center;
  padding-bottom: 30px;
}

.section-title-noti h2 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #5777ba;
}

.section-title-noti p {
  margin-bottom: 0;
}


/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  min-height: 50vh;
  /* background: url("@/assets/images/intro-3.png") top center; */
  background-color: #faefd8;
  background-size: cover;
  position: relative;
}

#hero:before {
  content: "";
  /* background: rgba(0, 0, 0, 0.1); */
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  position: relative;
  padding-top: 10px;
  text-align: center;
}

#hero h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  color: #ffc451;
}

#hero h1 span {
  color: #ffc451;
}

#hero h2 {
  color: rgba(255, 255, 255, 0.9);
  margin: 10px 0 0 0;
  font-size: 24px;
}

#hero .icon-box {
  padding: 30px 20px;
  transition: ease-in-out 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 100%;
  text-align: center;
}

#hero .icon-box i {
  font-size: 32px;
  line-height: 1;
  color: #ffc451;
}

#hero .icon-box h3 {
  font-weight: 700;
  margin: 10px 0 0 0;
  padding: 0;
  line-height: 1;
  font-size: 20px;
  line-height: 26px;
}

#hero .icon-box h3 a {
  color: #fff;
  transition: ease-in-out 0.3s;
}

#hero .icon-box h3 a:hover {
  color: #ffc451;
}

#hero .icon-box:hover {
  border-color: #ffc451;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 20px;
    line-height: 24px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  padding-bottom: 40px;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #ffde9e;
  margin: 4px 10px;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #151515;
}
</style>