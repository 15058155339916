<template>
  <div class="product">

    <section id="hero" class="d-flex align-items-center justify-content-center">
      <div class="container" data-aos="fade-up">

        <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
          <div class="col-xl-6 col-lg-8">
            <h1>体温計</h1>
          </div>
        </div>

      </div>
    </section>

    <div class="container">
      <div class="wrap_default">
        <div class="wrap_default_left">
          <!-- 레이아웃 가운데영역 시작 { -->
          <div id="wrap_contents">
            <div class="xans-element- xans-product xans-product-menupackage ">
              <ul class="menuCategory">
                <li style="display:" class="xans-element- xans-product xans-product-displaycategory  dm2 xans-record-">
                  <a href="/product">
                    <p class="fga6">体温計</p>
                  </a>
                </li>
              </ul>
            </div>


            <div style="margin-top:3.75rem;"></div>

            <!-- 일반상품 -->
            <div class="xans-element- xans-product xans-product-normalpackage ">
              <div class="xans-element- xans-product xans-product-normalmenu ">
                <div class="function">
                  <p class="prdCount"></p>
                  <p class="fga4" style="float:left;">完全に <strong>1</strong>つの製品があります。</p>
                  <ul id="type" class="xans-element- xans-product xans-product-orderby">
                    <li class="xans-record-"><a href="?cate_no=376&sort_method=5#Product_ListMenu">新商品</a></li>
                    <li class="xans-record-"><a href="?cate_no=376&sort_method=1#Product_ListMenu">商品名</a></li>
                    <li class="xans-record-"><a href="?cate_no=376&sort_method=3#Product_ListMenu">低価格</a></li>
                    <li class="xans-record-"><a href="?cate_no=376&sort_method=4#Product_ListMenu">高価格</a></li>
                    <li class="xans-record-"><a href="?cate_no=376&sort_method=7#Product_ListMenu">人気順</a></li>
                  </ul>
                  <!-- <span class="compare displaynone"><a href="#none" onclick="EC_ListAction.setProductCompare();"><img src="http://img.echosting.cafe24.com/skin/base_ko_KR/product/btn_compare.gif" alt="상품비교"/></a></span> -->
                </div>

              </div>
              <div class="xans-element- xans-product xans-product-listnormal ec-base-product">
                <ul class="prdList grid4">
                  <li id="anchorBoxId_2025" class="xans-record-">
                    <!-- <span class="chk"><input type="checkbox" class="ProductCompareClass xECPCNO_2025 displaynone"/></span> -->
                    <div class="thumbnail">
                      <a href="/Product/Detail" name="anchorBoxName_2025"><img src="@/assets/itemImg/productItem-1.jpg"
                          alt="스마트 체온계" style="width: 300px; height: 200px;" /></a>
 
                    </div>
                    <div class="description">
                      <strong class="name"><a href="/" class="">
                          <p class="fga3"><span style="font-size:0.875rem;color:#333333;">スマート体温計</span></p>
                        </a></strong>
                      <ul class="xans-element- xans-product xans-product-listitem spec" style="padding: 0;">

                        <li class=" xans-record-">
                          <p class="fga4"><span
                              style="font-size:0.875rem;color:#0066ff;font-weight:bold;">10,000円</span></p>
                        </li>

                      </ul>
                    </div>
                  </li>

                  <li id="anchorBoxId_2025" class="xans-record-">
                    <!-- <span class="chk"><input type="checkbox" class="ProductCompareClass xECPCNO_2025 displaynone"/></span> -->
                    <div class="thumbnail">
                      <a href="/Product/Detail2" name="anchorBoxName_2025"><img src="@/assets/itemImg/productItem-2.png"
                          alt="스마트 체온계" style="width: 300px; height: 200px;" /></a>
 
                    </div>
                    <div class="description">
                      <strong class="name"><a href="/Product/Detail2" class="">
                          <p class="fga3"><span style="font-size:0.875rem;color:#333333;">扁桃炎カメラ</span></p>
                        </a></strong>
                      <ul class="xans-element- xans-product xans-product-listitem spec" style="padding: 0;">

                        <li class=" xans-record-">
                          <p class="fga4"><span
                              style="font-size:0.875rem;color:#0066ff;font-weight:bold;">5,000円</span></p>
                        </li>

                      </ul>
                    </div>
                  </li>

                  <li id="anchorBoxId_2025" class="xans-record-">
                    <!-- <span class="chk"><input type="checkbox" class="ProductCompareClass xECPCNO_2025 displaynone"/></span> -->
                    <div class="thumbnail">
                      <a href="/Product/Detail3" name="anchorBoxName_2025"><img src="@/assets/itemImg/productItem-3.png"
                          alt="스마트 체온계" style="width: 300px; height: 200px;"/></a>
 
                    </div>
                    <div class="description">
                      <strong class="name"><a href="/Product/Detail3" class="">
                          <p class="fga3"><span style="font-size:0.875rem;color:#333333;">メディカル·キット</span></p>
                        </a></strong>
                      <ul class="xans-element- xans-product xans-product-listitem spec" style="padding: 0;">

                        <li class=" xans-record-">
                          <p class="fga4"><span
                              style="font-size:0.875rem;color:#0066ff;font-weight:bold;">13,000円</span></p>
                        </li>

                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    <!-- } 레이아웃 가운데영역 끝 -->
  </div>



</template>
<script>
import "@/assets/css/productDetail.css";

export default {
  name: 'Product'
}
</script>
<style scoped>

@media (max-width: 768px) {
  .xans-product-normalmenu .function {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    width: 100%;
  }
}


@media (max-width: 1266px) {
  #wrap_contents {
    width: 100%;
    max-width: none;
    padding: 0 15px; /* 좌우 여백 추가 (선택 사항) */
    box-sizing: border-box; /* 패딩을 요소의 너비에 포함시킴 */
  }
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  min-height: 30vh;
  /* background-color: #faefd8; */
  background-size: cover;
  position: relative;
}

#hero:before {
  content: "";
  /* background: rgba(0, 0, 0, 0.1); */
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  position: relative;
  padding-top: 10px;
  text-align: center;
}

#hero h1 {
  margin: 0;
  font-size: 40px;
  font-weight: 700;
  line-height: 64px;
  /* color: #ffc451; */
}

#hero h1 span {
  color: #ffc451;
}

#hero h2 {
  color: rgba(255, 255, 255, 0.9);
  margin: 10px 0 0 0;
  font-size: 24px;
}

#hero .icon-box {
  padding: 30px 20px;
  transition: ease-in-out 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 100%;
  text-align: center;
}

#hero .icon-box i {
  font-size: 32px;
  line-height: 1;
  color: #ffc451;
}

#hero .icon-box h3 {
  font-weight: 700;
  margin: 10px 0 0 0;
  padding: 0;
  line-height: 1;
  font-size: 20px;
  line-height: 26px;
}

#hero .icon-box h3 a {
  color: #fff;
  transition: ease-in-out 0.3s;
}

#hero .icon-box h3 a:hover {
  color: #ffc451;
}

#hero .icon-box:hover {
  border-color: #ffc451;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 20px;
    line-height: 24px;
  }
}


.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #6528e0;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}

.bd-mode-toggle {
  z-index: 1500;
}

.bd-mode-toggle .dropdown-menu .active .bi {
  display: block !important;
}

.item-price {
  font-size: 1.25rem
}

.wrap_default {
  clear: both;
  position: relative;
  margin: 0 auto;
  width: 100%; /* 부모 요소(container)의 너비를 100%로 설정 */
  max-width: 100%; /* 최대 너비를 100%로 설정하여 넘치지 않도록 함 */
}

.wrap_default_left {
  margin: 0 auto;
  width: 100%;
}

#wrap_contents {
  /* margin: 0 auto; */
  padding: 1.25rem 0;
  width: 100%;
  min-height: 37.5rem;
  height: 37.5rem;
  overflow-x: hidden;
  text-align: left;
  zoom: 1
}

.wrap_title {
  height: 3.375rem;
}


.xans-product-menupackage {
  margin: 0 0 1.6875rem;
}



.xans-product-menupackage .title {
  min-height: 1.875rem;
  margin: 0.625rem 0 1.875rem;
  text-align: center;
}

.xans-product-menupackage .title .banner {
  margin: 0 0 1.875rem;
}

.xans-product-menupackage .title h2 {
  color: #333;
  font-size: 1.5625rem;
  font-weight: 500;
}

.xans-product-menupackage .title h2,
.xans-product-menupackage .title .likeButton {
  display: inline-block;
  vertical-align: middle;
}

.xans-product-additional div.board p.nodata {
  font-weight: normal !important;
  color: #999 !important;
}

.xans-product-menupackage .title h2 {
  width: 100%;
  background: none !important;
  text-align: center;
}

.xans-product-menupackage .menuCategory {
  border: 0 !important;
}

.xans-product-listnormal ul.column4 li.item .box {
  width: 15rem !important;
}

.xans-product-normalmenu .function {
  /* overflow: hidden; */
  text-align: right;
  line-height: 2.375rem;
  border-bottom: 0.0625rem solid #d7d5d5;
}

.xans-product-normalmenu .prdCount {
  float: left;
  padding: 0 0 0 0.5rem;
  color: #008bcc;
  text-decoration: none;
}

.xans-product-normalmenu ul#type {
  display: inline;
  margin: 0 0.25rem 0 0;
}

.xans-product-normalmenu ul#type li {
  display: inline;
  padding: 0 0.375rem 0 0.625rem;
}

.xans-product-normalmenu ul#type li:first-child {
  background: none;
}

.xans-product-normalmenu ul#type li a {
  color: #2e2e2e;
  text-decoration: none;
}

.xans-product-normalmenu ul#type li a:hover {
  color: #008bcc;
  text-decoration: none;
}

p.fga6 {
  font-weight: 600;
}

p.fga7 {
  font-weight: 700;
  text-align: center;
}

p.fga3 {
  font-weight: 300;
}


.menuCategory {
  position: relative;
  z-index: 0;
  padding: 10px 0 0;
  border: none;
  font-size: 0;
  line-height: 0;
  letter-spacing: -0.25rem;
  text-align: center;
}

.menuCategory:after {
  content: "";
  display: block;
  clear: both;
}

.menuCategory a {
  display: block;
  color: #333 !important;
  font-size: 0.75rem;
  letter-spacing: 0;
  text-align: left;
  text-decoration: none;
}

.menuCategory a:hover {
  display: block;
  text-decoration: none;
  color: #000 !important;
}

.menuCategory>li>a {
  display: block;
  width: 11.625rem;
  height: 2.1875rem;
  padding: 0 0 0 0.625rem;
  border: 0.0625rem solid #e5e5e5;
  background: #fafafa;
  line-height: 2.1875rem;
  text-align: center;
}

.menuCategory>li.selected a {
  border: 0.0625rem solid #111;
  border: 0.0625rem solid #003399;
  background: #003399;
  color: #fff !important;
}


.menuCategory>li>a:hover,
.menuCategory>li.on>a {
  border: 0.0625rem solid #003399;
  background: #003399;
  color: #fff !important;
}

.menuCategory>li>ul {
  position: absolute;
  z-index: 2;
  display: none;
  left: 0;
  top: 0.1875rem;
  width: 13rem;
  padding: 0.3125rem 0 0.625rem;
  background: #fff;
  border: 0.0625rem solid #ddd;
  border-top: 0;
}

.ec-base-product .prdList {
  display: table;
  width: 100%;
  min-width: 47.25rem;
  margin: -1.25rem 0 0;
  font-size: 0;
  line-height: 0;
}

.ec-base-product .prdList>li {
  display: inline-block;
  margin: 1.25rem 0;
  color: #757575;
  vertical-align: top;
  width: 100%;
}

.ec-base-product .prdList .chk {
  display: block;
  margin: 0 0 0.625rem;
  text-align: center;
}

.ec-base-product .prdList .thumbnail {
  position: relative;
  margin: 0 0.4375rem 0.625rem;
  text-align: center;
}

.ec-base-product .prdList .thumbnail a img {
  max-width: 100%;
  box-sizing: border-box;
}

.ec-base-product ul.grid4>li {
  width: 25%;
}

.ec-base-product .prdList .thumbnail .prdIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.ec-base-product .prdList .description {
  margin: 0 auto;
  padding: 0 0.4375rem;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: center;
}

.ec-base-product .prdList .description .name {
  display: block;
  text-align: center;
}

.ec-base-product .prdList .description .name a {
  color: #2e2e2e;
  text-decoration: none;
}

li {
  list-style: none;
}
</style>