
<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="d-none">
      <symbol id="check2" viewBox="0 0 16 16">
        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
      </symbol>
      <symbol id="circle-half" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"/>
      </symbol>
      <symbol id="moon-stars-fill" viewBox="0 0 16 16">
        <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"/>
        <path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"/>
      </symbol>
      <symbol id="sun-fill" viewBox="0 0 16 16">
        <path d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
      </symbol>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" class="d-none">
  <symbol id="aperture" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
    <circle cx="12" cy="12" r="10"/>
    <path d="M14.31 8l5.74 9.94M9.69 8h11.48M7.38 12l5.74-9.94M9.69 16L3.95 6.06M14.31 16H2.83m13.79-4l-5.74 9.94"/>
  </symbol>
  <symbol id="cart" viewBox="0 0 16 16">
    <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
  </symbol>
  <symbol id="chevron-right" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
  </symbol>
</svg>

  <header style="background-color: #FEFEF3; height: 100px;" class="header-nav" :class="{ 'scrolled': isScrolled }">
  <nav class="navbar navbar-expand-md sticky-top" style="height: 100px;">
  <div class="container">
    <a class="navbar-brand d-md-none" href="/">
      <img src="@/assets/engLogoo.png" alt="" width="150" height="50">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" aria-controls="offcanvas" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvas" aria-labelledby="offcanvasLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasLabel">ドクターインホーム</h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <ul class="navbar-nav flex-grow-1 justify-content-between">
          <li class="nav-item"><a class="nav-link" href="/" style="max-width: 100%;">
            <!-- <svg class="bi" width="24" height="24"><use xlink:href="#aperture"/></svg> -->
            <img src="@/assets/engLogoo.png" alt="" width="150" height="50">
          </a></li>
          <li class="dropdown">
            <a class="nav-link" href="/about">はじめに<i class="bi bi-chevron-down"></i></a>
            <ul>
              <li>
                    <a href="/about#services" class="dd-dropdown">中耳炎</a>
                  </li>
                  <li>
                    <a href="/about#portfolio" class="dd-dropdown">扁桃炎</a>
                  </li>
                  <li>
                    <a href="/about#about" class="dd-dropdown">About</a>
                  </li>
            </ul>
          </li>
          <li class="nav-item"><a class="nav-link" href="/Innovation">革新</a></li> <!-- 혁신 -->
          <li class="nav-item"><a class="nav-link" href="/Exhibition">展示会</a></li>
          <li class="nav-item"><a class="nav-link" href="/App">APP</a></li>
          <li class="nav-item"><a class="nav-link" href="/notice">お知らせ</a></li>
          <li class="dropdown"><a href="##" class="nav-link">
                  <span>健康</span>
                  <i class="bi bi-chevron-down"></i></a>
                <ul>
                  <li>
                    <!-- 소아 중이염 예방 & 관리 -->
                    <a href="/health#OtitisMedia" class="dd-dropdown">乳幼児中耳炎</a>
                  </li>
                  <li>
                    <!-- 항생제를 적절히 사용하는 방법 -->
                    <a href="/health#Antibiotics" class="dd-dropdown">抗生物質の適切な使用方法</a>
                  </li>
                  <li>
                    <!-- 중이염을 완화 또는 예방하는 생활관리 -->
                    <a href="/health#Lifestyle" class="dd-dropdown">予防するための生活管理</a>
                  </li>
                  <li>
                    <!-- 중이염에 좋은 영양제 -->
                    <a href="/health#Supplements" class="dd-dropdown">中耳炎に良いサプリメント</a>
                  </li>
                </ul>
              </li>
          <!-- <li class="nav-item"><a class="nav-link" href="/searchPill">推奨薬を検索</a></li> -->
          <!-- <li class="nav-item"><a class="nav-link" href="/qna">고객센터</a></li> -->
          <li class="dropdown"><a href="##" class="nav-link"><span>顧客センター</span> <i class="bi bi-chevron-down"></i></a>
            <ul>
              <li><a href="/qna" class="dd-dropdown">Q&A</a></li>
              <!-- <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i class="bi bi-chevron-right"></i></a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li> -->
              <li><a href="/question" class="dd-dropdown">お問い合わせ</a></li>
              <li>
                    <a href="/about#contact" class="dd-dropdown">来る道</a>
              </li>
              <!-- <li><a href="#">Drop Down 3</a></li>
              <li><a href="#">Drop Down 4</a></li> -->
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
</header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      isScrolled: false, // 스크롤 상태 초기값
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated; // 스토어에서 로그인 상태를 가져옵니다.
    },
    userEmail() {
      return this.$store.state.userEmail; // Vuex에서 사용자 이메일을 가져옵니다.
    }
  },
  methods: {
    navigateToEnglish() {
      // 영어 페이지로 리디렉션
      window.location.href = 'https://otiton-medical.com/'; // 영어 페이지 경로로 변경하세요
    },
    navigateToKorean() {
      // 한국어 페이지로 리디렉션
      window.location.href = 'https://doctorinhaus.com/'; // 한국어 페이지 경로로 변경하세요
    },
    preventDefault(event) {
      event.preventDefault();
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0; // 스크롤이 내려가면 true
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll); // 스크롤 이벤트 등록
  },
  beforeUnmount() {
  window.removeEventListener('scroll', this.handleScroll);
}

}
</script>
<style scoped>
.justify-content-between {
  align-items: center;
}

.header-nav {
  background-color: transparent !important; /*투명 배경*/
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease; /* 배경색 전환 효과 */
}

.header-nav.scrolled {
  background-color: rgb(255, 255, 255, 0.9) !important; /* 스크롤 시 완전 불투명 색상 */
  /* backdrop-filter: blur(5px); 블러 효과 추가 */
}

.header-nav:hover {
  background-color: white !important; /* 마우스를 올리면 하얀색 배경 */
}

nav a {
  color: #151515 !important;
}


.nav-link {
  cursor: pointer;
}

.checkbox {
  position: relative;
  display: inline-block;
}

.checkbox label {
  width: 90px;
  height: 42px;
  background: #ccc;
  position: relative;
  display: inline-block;
  border-radius: 46px;
  transition: .4s;
}

.checkbox label:after {
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  left: 0;
  top: -5px;
  z-index: 2;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, .2);
  transition: .4s;
}

.checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.checkbox input:hover+label:after {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .2), 0 3px 8px 0 rgba(0, 0, 0, .15);
}

.checkbox input:checked+label:after {
  left: 40px;
}

.checkbox label {
  background: #bbb;
  height: 18px;
  width: 58px;
  margin-top: 13px;
}

.checkbox label:after {
  background: #fff;
  width: 20px;
  height: 20px;
  top: -1px;
}

/* .checkbox input:checked + label {
  background: #77adc2;
}

.checkbox input:checked + label:after {
  background: #005a96;
  left: 40px;
} */


.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 18px;
  /* font-weight: 600; */
  color: #27272A;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar .dd-dropdown a i,
.navbar .dd-dropdown a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;

}

.navbar .dd-dropdown a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #ffc451;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
  list-style-type: none;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #151515;
  font-weight: 400;
  text-decoration: none;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  background-color: #ffc451;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
