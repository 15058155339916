<template>
  <div class="noticeDetail">

    <section id="hero" class="d-flex align-items-center justify-content-center">
      <div class="container" data-aos="fade-up">

        <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
          <div class="col-xl-6 col-lg-8">
            <h1>お知らせ</h1>
            <h2>イベントとお知らせです。</h2>
          </div>
        </div>

      </div>
    </section>
    <section id="faq" class="faq section-bg-noti">
      <div class="container" data-aos="fade-up">

        <div class="section-title-noti">

          <!-- <h2>자주 묻는 질문들</h2>
      <p>스마트 체온계와 닥터인홈의 궁금증을 해결해드립니다.</p> -->
        </div>

        <div class="notice-table">
          <div class="row">
            <div class="card">
              <div class="card-body">
                <!-- <h5 class="card-title">Table with hoverable rows</h5> -->

                <!-- Table with hoverable rows -->
                <table class="table">
                  <thead>
                    <tr>
                      <!-- <th scope="col">번호</th> -->
                      <th scope="col" colspan="4" style="border-bottom: none; font-size: 17px; color: #ffc451;">{{
                        notice.noticeTitle }}</th>
                    </tr>
                    <ul class="etcArea" style="border-bottom: solid 1px; border-color: gainsboro;">
                      <li>
                        <span class="txt">{{ notice.noticeWriter }}</span>
                      </li>
                      <li>
                        <span class="txt">{{ notice.noticeDate }}</span>
                      </li>
                    </ul>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="4" v-html="noticeContent"></td>
                    </tr>
                  </tbody>
                </table>
                <!-- End Table with hoverable rows -->

              </div>
            </div>
          </div>
          <div class="qnaList" style="text-align: center; margin: 20px;">
            <router-link to="/notice" class="qnaList-btn">リスト</router-link>
          </div>
        </div>

      </div>
    </section>

  </div>
</template>
<script>
import { notice } from '@/scripts/notice.js';

export default {
  name: 'noticeDetail',
  data() {
    return {
      notice: {}
    };
  },
  created() {
    this.loadNotice();
  },
  methods: {
    async loadNotice() {
      // 라우트 파라미터에서 공지사항 번호를 가져온다
      const noticeNum = this.$route.params.noticeNum;

      // notice 데이터를 가져와서 해당 번호의 공지사항을 찾음
      this.notice = notice.find(item => item.noticeNum === parseInt(noticeNum));

      // 로그를 찍어서 데이터가 올바르게 가져와졌는지 확인
      console.log(this.notice);
    },
    increaseReadCount(noticeNum) {
      // 여기에 공지사항의 조회수를 증가시키는 로직을 작성합니다.
      // 예시로는 간단히 콘솔에 공지사항 번호를 출력하는 것으로 대체합니다.
      notice.noticeRdcnt++;
      console.log(notice.noticeRdcnt);
      console.log("Increase read count for notice number:", noticeNum);
    }
  },
  computed: {
    noticeContent() {
      return this.notice.noticeContent;
    }
  }
}
</script>
<style>
/* content 스타일 */
.centered-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: auto;
}
</style>
<style scoped>
body {
  color: #444444;
}

a {
  color: #ffc451;
  text-decoration: none;
}

a:hover {
  color: #ffd584;
  text-decoration: none;
}

.etcArea li {
  display: inline-block;
  padding: 10px !important;
  padding-top: 0 !important;
  font-size: 15px;
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .notice-table {
  padding: 0 100px;
}

.faq .notice-table ul {
  padding: 0;
  list-style: none;
}

.faq .notice-table li+li {
  margin-top: 15px;
}

.faq .notice-table li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .notice-table a {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}


.faq .notice-table p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

@media (max-width: 1200px) {
  .faq .accordion-list {
    padding: 0;
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section-noti {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg-noti {
  background-color: #fff;
}

.section-title-noti {
  text-align: center;
  padding-bottom: 30px;
}

.section-title-noti h2 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #5777ba;
}

.section-title-noti p {
  margin-bottom: 0;
}


/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  min-height: 30vh;
  /* background: url("@/assets/images/intro-3.png") top center; */
  background-color: #faefd8;
  background-size: cover;
  position: relative;
}

#hero:before {
  content: "";
  /* background: rgba(0, 0, 0, 0.1); */
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  position: relative;
  padding-top: 10px;
  text-align: center;
}

#hero h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  color: #ffc451;
}

#hero h1 span {
  color: #ffc451;
}

#hero h2 {
  color: rgba(255, 255, 255, 0.9);
  margin: 10px 0 0 0;
  font-size: 24px;
}

#hero .icon-box {
  padding: 30px 20px;
  transition: ease-in-out 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 100%;
  text-align: center;
}

#hero .icon-box i {
  font-size: 32px;
  line-height: 1;
  color: #ffc451;
}

#hero .icon-box h3 {
  font-weight: 700;
  margin: 10px 0 0 0;
  padding: 0;
  line-height: 1;
  font-size: 20px;
  line-height: 26px;
}

#hero .icon-box h3 a {
  color: #fff;
  transition: ease-in-out 0.3s;
}

#hero .icon-box h3 a:hover {
  color: #ffc451;
}

#hero .icon-box:hover {
  border-color: #ffc451;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 20px;
    line-height: 24px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  padding-bottom: 40px;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #ffde9e;
  margin: 4px 10px;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #151515;
}
</style>