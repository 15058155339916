<template>
    <div class="health">
        <!-- ======= Hero Section ======= -->
        <section id="hero" class="d-flex align-items-center justify-content-center">
            <div class="container" data-aos="fade-up">

                <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
                    <div class="col-xl-6 col-lg-8">
                        <h1>Without Antibiotics<span>.</span></h1>
                        <!-- <h2>Take care of your children effectively.</h2> -->
                    </div>
                </div>

            </div>
        </section><!-- End Hero -->

        <main id="main">
            <div v-if="currentSection === 'OtitisMedia'">
                <section id="services" class="services">
                    <section class="blog-section">
                        <div class="container" data-aos="fade-up" style="display: flex; justify-content: center;">
                            <div class="image col-lg-6">
                                <div class="services-container" data-aos="fade-right" style="text-align: center;">
                                    <div class="serveices-intro image-container">
                                        <img src="@/assets/images/health-1.png" alt="" style="margin-bottom: 40px;" class="responsive-image">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="blog-post">
                                        <h1>小児中耳炎の予防と管理</h1>
                                        <p class="image-container">
                                           <img src="@/assets/images/health-2.png" alt="" class="responsive-image">
                                        </p>
                                        <h2>予防方法</h2>
                                        <p>細菌感染などによって鼓膜の内側に水が溜まる病気である中耳炎は、3歳以下の小児の約60%が一度はかかると報告されています。その理由は、小児の耳管の構造が成人と異なり、短くて太く、水平に位置しているため、中耳炎が発生しやすいからです。特に一度中耳炎にかかると、そのうちの半数以上が三回以上再発すると言われています。また、適切に治療しないと、乳幼児の聴力低下の主要な原因となるため、正しい管理が必要です。治療と同じくらい重要な予防と管理の方法について見ていきましょう。</p>
                                        <p>小児中耳炎は細菌感染によって多く発生するため、予防のために肺炎球菌やインフルエンザなどのワクチン接種が役立ちます。これらのワクチンは中耳炎の発生率を約43%減少させると報告されています。</p>
                                        <p>乳幼児の場合、風邪の後遺症で中耳炎が発生することが多いため、風邪にも注意が必要です。外出後に帰宅した際に手を洗うことで、中耳炎を引き起こす上気道感染を予防することができます。</p>
                                        <p>副鼻腔炎や鼻炎がある場合、事前に治療しておくことが望ましいです。鼻に炎症があると中耳炎にかかりやすくなるため、普段から鼻の健康に気を配る必要があります。</p>
                                        <p>タバコの煙も中耳炎を引き起こす重要な原因の一つです。子供たちが間接喫煙にさらされないように、周囲の環境管理に注意を払う必要があります。</p>
                                        <h2>管理方法</h2>
                                        <p>子供が中耳炎にかかると、母親たちが最も心配するキーワードは主に「抗生物質」「手術」「聴力低下」です。中耳炎は抗生物質で治療しなければならないという偏見に対して、実際に抗生物質を使う必要がある場合は、「耳の痛みがひどい」「38.5度以上の高熱がある」「生後6ヶ月以下の赤ちゃん」の3つを優先しています。これは急性中耳炎の症状であり、急性中耳炎は細菌感染によるものであるため、抗生物質を使って短期間で治療する必要があります。耳の痛みや高熱がなく、「耳に水が溜まっています」と言われる滲出性中耳炎はウイルス性の感染であるため、抗生物質を使わないのが原則です。また、中耳炎の6～70%はこのような滲出性中耳炎です。</p>
                                        <p>滲出性中耳炎は、細菌による急性中耳炎を患った後に膿が完全に排出されないことで発生するか、または細菌感染なしに耳管の機能が低下して真空状態になり、滲出液が分泌されることで発生します。</p>
                                        <p>ほとんどの場合、2ヶ月以内に自然治癒します。しかし、鼓膜の内側に滲出液が溜まると、聴力低下が必然的に発生します。言語習得期にある小児の場合、聴力の低下は短期間であっても脳の言語中枢の発達に支障をきたす可能性があるため、積極的に管理する必要があります。</p>
                                        <p>ドクターインホームは、継続的で簡単な管理に役立ちます。高熱がなくても撮影できる内視鏡写真でお子様の鼓膜を観察し、中耳炎の進行段階を確認できます。また、消炎鎮痛剤を服用している期間中でもいつでも撮影して改善の速度を確認することができます。</p>
                                        <h3>抗生物質の乱用を減らすための管理を、ドクターインホームで始めましょう。</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
            <div v-if="currentSection === 'Antibiotics'">
                <section id="portfolio" class="portfolio">
                    <section class="blog-section">
                        <div class="container" data-aos="fade-up" style="display: flex; justify-content: center;">
                            <div class="image col-lg-6">
                                <div class="services-container" data-aos="fade-right" style="text-align: center;">
                                    <div class="serveices-intro image-container">
                                        <img src="@/assets/images/health-3.png" alt="" style="margin-bottom: 40px;" class="responsive-image">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="blog-post">
                                        <h1>抗生物質耐性に注意してください。</h1>
                                        <p class="image-container">
                                           <img src="@/assets/images/health-2.png" alt="" class="responsive-image">
                                        </p>
                                        <h2>抗生物質とその使用方法について学びましょう。</h2>
                                        <p>抗生物質は、体に細菌が侵入して感染を引き起こした場合に、それを治療するために使用されます。しかし、抗生物質を使用すればすべての病気が早く治るという誤った知識によって、乱用される事例がしばしば見られます。抗生物質の過剰使用および乱用は、抗生物質を使用しても生き残り増殖する抗生物質耐性菌を生み出します。これがまさに皆が恐れている抗生物質耐性です。抗生物質を投与しても効果が少なく、敗血症や肺炎など生命を脅かす感染症にかかると、死に至ることもあります。抗生物質の乱用や無計画な中止は、抗生物質耐性菌の拡散に大きな影響を与えます。</p>
                                        <p>抗生物質耐性を防ぐために、正しい服用方法を学びましょう。</p>
                                        <h2>抗生物質を正しく服用するための三つの方法</h2>
                                        <p>抗生物質は風邪やインフルエンザのような「ウイルス」疾患には効果がありません。抗生物質は細菌にのみ反応する薬剤であるため、発病の原因がウイルスである場合、抗生物質治療は効果がありません。風邪を治療している間に肺炎、副鼻腔炎、扁桃炎などの細菌感染が発生した場合は、抗生物質の服用が必要になることがあります。しかし、二次的な細菌感染を予防するための抗生物質の服用は必要ありません。</p>
                                        <p>処方された薬は最後まで服用する必要があります。症状が緩和されたからといって途中で服用を中止すると、感染が完全に治らず、細菌が耐性を持つようになる可能性があります。</p>
                                        <p>一定の時間間隔で服用する必要があります。抗生物質の濃度を体内で一定に保つことで、細菌の繁殖を効果的に抑えることができます。血中の抗生物質濃度を維持するために、濃度が低くなる時に抗生物質を追加投与する方法で処方されます。服用を忘れてしまった場合は、二倍ではなく一回分の服用量だけを服用してください。</p>
                                        <h3>正しい抗生物質の服用方法を覚えて、抗生物質耐性に注意してください。</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
            <div v-if="currentSection === 'Lifestyle'">
                <section id="portfolio" class="portfolio">
                    <section class="blog-section">
                        <div class="container" data-aos="fade-up" style="display: flex; justify-content: center;">
                            <div class="image col-lg-6">
                                <div class="services-container" data-aos="fade-right" style="text-align: center;">
                                    <div class="serveices-intro image-container">
                                        <img src="@/assets/images/health-7.png" alt="" style="margin-bottom: 40px;" class="responsive-image">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="blog-post">
                                        <h1>中耳炎を予防するための生活管理</h1>
                                        <p class="image-container">
                                          <img src="@/assets/images/health-2.png" alt="" class="responsive-image">
                                        </p>
                                        <p>中耳炎を予防するもう一つの方法は生活管理です。細菌やウイルス感染だけでなく、免疫力の低下も中耳炎の発症を引き起こす可能性があるため、免疫力を高めるための栄養補助食品の摂取も推奨されます。また、適切な予防策を守り、手洗いなどの日常的な衛生管理が重要です。すでに中耳炎にかかっている子供には、治療だけでなく、このような生活管理も必須です。</p>
                                        <p>どのような生活管理が中耳炎の予防と管理に役立つのかを見てみましょう。</p>
                                        <h2>中耳炎を予防するための生活管理の二つの方法</h2>
                                        <p>幼い子供の場合、哺乳瓶は12ヶ月までの使用にとどめるべきです。1歳以降も哺乳瓶を使い続けると、耳管にかかる圧力が増加し、中耳炎にかかりやすくなります。また、粉ミルクは必ず抱いて飲ませるようにしましょう。子供が寝ながら飲む姿勢は耳管を水平にしてしまうため、粉ミルクや異物が中耳に入りやすくなります。これは炎症を引き起こす原因となるため、45度ほど傾けて飲ませるのが良いです。</p>
                                        <p>キシリトールが虫歯予防以外にも中耳炎を予防できるという研究結果も発表されています。カナダのトロント大学の研究チームが研究論文を分析した結果、キシリトールを含むガムやキャンディー、シロップを定期的に摂取した子供は、そうでない子供に比べて中耳炎にかかるリスクが25％低いことがわかりました。また、キシリトールを含むガムやキャンディーはシロップよりも中耳炎予防効果が優れていることが判明しましたが、すでに呼吸器疾患を患っている子供には効果がないと分析されました。キシリトールが中耳炎予防に効果的であることは確かですが、健康な子供にのみ予防効果が現れるため、日頃から免疫力をしっかりと保つことが重要です。</p>
                                        <h2>子供が中耳炎にかかったときの生活管理</h2>
                                        <p>子供が中耳炎にかかっている状態で哺乳瓶やストローを使用すると、中耳炎が悪化する可能性があります。</p>
                                        <p>子供の鼻が詰まったり鼻水が出たりしている場合、温かい濡れタオルを鼻に当てたり、小鼻の横にある迎香穴を押すマッサージをしてあげると、不快な症状が緩和され、中耳炎の改善に役立ちます。</p>
                                        <p>鼻を強くかむ動作は耳に圧力をかけるため、中耳炎には良くありません。また、両方の鼻を押さえて強くかむ動作は、鼻水だけでなく鼻腔内の細菌やウイルスが耳に入り込み、中耳炎の原因となったり悪化させたりすることがあります。鼻をかむときは、一方ずつかむようにして耳にかかる圧力を減らすようにしましょう。</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
            <div v-if="currentSection === 'Supplements'">
                            <section id="portfolio" class="portfolio">
                                <section class="blog-section">
                                    <div class="container" data-aos="fade-up" style="display: flex; justify-content: center;">
                                        <div class="image col-lg-6">
                                            <div class="services-container" data-aos="fade-right" style="text-align: center;">
                                                <div class="serveices-intro image-container">
                                                    <img src="@/assets/images/health-12.png" alt="" style="margin-bottom: 40px;" class="responsive-image">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="blog-post">
                                                    <h1>中耳炎や風邪に効果的なサプリメント</h1>
                                                    <p class="image-container">
                                                      <img src="@/assets/images/health-2.png" alt="" class="responsive-image">
                                                    </p>
                                                    <h2>免疫力の向上に役立つサプリメント</h2>
                                                    <p class="image-container" style="text-align: center;">
                                                      <img src="@/assets/images/health-13.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                    </p>
                                                    <p>オメガ3脂肪酸は炎症を抑える効果があり、特にEPAは炎症性物質であるPGE2を減少させることで中耳炎の発生を予防することができます。</p>
                                                    <p>亜鉛は必須微量栄養素であり、免疫機能や感染に対する抵抗力に重要な役割を果たしています。特に亜鉛は体内に蓄積できないため、定期的に摂取する必要があります。臨床試験の結果、亜鉛補充剤は健康な小児よりも重度の栄養失調など免疫力が低下している小児において中耳炎の発生を減少させる効果が見られました。したがって、亜鉛補充剤を摂取することで免疫機能が強化され、中耳炎の発生を予防することができます。</p>
                                                    <p>乳酸菌は腸内の有益菌を増加させ、炎症物質のレベルを改善し、感染を予防することができます。腸が食べ物による刺激を持続的に受けると、免疫力が低下し、アレルギー反応や慢性中耳炎が発生する可能性があります。また、個人のアレルギー反応を引き起こす食べ物の摂取を中止することで、慢性中耳炎の症状が85〜93％消失し、再発率も70〜80％以上低下する効果が確認されています。乳酸菌を摂取することで腸への負担を軽減し、中耳炎を予防することができます。</p>
                                                    <p>マグネシウムは細胞を保護し、神経を緩和させることで耳鳴りやめまいに効果があります。特にストレスの緩和に効果的です。ストレスは体の免疫力を低下させる主な原因であるため、日頃からマグネシウムを摂取することが重要です。</p>
                                                    <p>ビタミンB群は耳鳴りだけでなく、耳鳴りの合併症も予防することができます。B9（葉酸）は蝸牛の抗酸化ストレスを防ぎ、損傷から保護することで難聴のリスクを低減します。ビタミンB1、B2、そしてB6は耳の中の神経を安定させ、耳鳴りによるめまいや耳鳴りを防ぎます。また、耳鳴りの患者にはビタミンB12の欠乏がよく見られるため、十分に補充することが重要です。実際、慢性耳鳴り患者の約47％がビタミンB12欠乏であったという研究結果があります。普段、菜食中心の食事をしている場合、体内のビタミンB12が不足しがちになるため、サプリメントで補充することが必要です。</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </section>
                        </div>
        </main>
    </div>
</template>
<script>
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

export default {
  name: 'health',
  data() {
        return {
            currentSection: 'about' // Default section is 'about'
        };
    },
    methods: {
        showSection(section) {
            this.currentSection = section;
        },
        checkHash() {
            const hash = this.$route.hash.replace('#', '');
            if (hash) {
                this.showSection(hash);
            }
        },
        navigateToSection(section) {
            this.$router.push({ hash: `#${section}` });
        }
    },
    mounted() {
        // Swiper 초기화
        new Swiper('.swiper-container', {
            // Swiper 옵션 설정
            slidesPerView: 1,
            watchOverflow: true,
            spaceBetween: 0,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        this.checkHash(); // 초기 로드 시 해시 체크
        this.$router.afterEach(() => {
            this.checkHash(); // 라우트 변경 시 해시 체크
        });
    },
}
</script>
<style scoped>
@import url('@/assets/css/about.css');

/**
  * Template Name: Gp
  * Template URL: https://bootstrapmade.com/gp-free-multipurpose-html-bootstrap-template/
  * Updated: Mar 17 2024 with Bootstrap v5.3.3
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  */

  .image-container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsive-image {
  max-width: 100%;
  height: auto;
}

.nav-btn.hidden {
    display: none;
    /* hidden 클래스를 가진 요소를 숨깁니다 */
}

.blog-section {
    padding: 40px 0;
    background: #f9f9f9;
}

.blog-post {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.blog-post h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
}

.blog-post h2 {
    font-size: 2em;
    margin-top: 30px;
    color: #555;
}

.blog-post p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #666;
    margin-bottom: 20px;
}

hr {
    border-top: none;
}

.nav-btn {
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    gap: 2rem;
    font-family: 'Noto Sans KR', sans-serif;
}

.nav-btn button {
    font-weight: 600;
    /* 폰트 굵기 설정 */
    font-size: 15px;
    padding: 0.75rem 1.5rem;
    /* 버튼의 안쪽 여백 */
    border: none;
    /* 기본 테두리 제거 */
    background-color: #17a2b8;
    /* 배경색 설정 */
    color: #fff;
    /* 글자 색상 */
    border-radius: 5px;
    /* 모서리를 둥글게 */
    cursor: pointer;
    /* 커서를 포인터로 변경 */
    transition: background-color 0.3s ease;
    /* 배경색 전환 효과 */
}

.nav-btn button:hover {
    background-color: #138496;
    /* 호버 시 배경색 변경 */
}

.nav-btn button:focus {
    outline: none;
    /* 포커스 시 외곽선 제거 */
    box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5);
    /* 포커스 시 그림자 추가 */
}


.services-control-prev,
.services-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-align: center;
    background: none;
    border: 0;
    @include transition($carousel-control-transition);


    &:hover,
    &:focus {
        text-decoration: none;
        outline: 0;
    }
}

.services-control-prev {
    left: 0;
    background-image: ;
}

.services-control-next {
    right: 0;
    background-image: ;
}

@media (max-width: 991px) {
    .services .image {
        max-width: 80%;
        background-size: cover;
        background-position: center;
    }

    .services .image img {
        max-width: 100%;
        background-size: cover;
        background-position: center;
    }
}

/*--------------------------------------------------------------
  # General
  --------------------------------------------------------------*/
body {
    color: #444444;
}

a {
    color: #ffc451;
    text-decoration: none;
}

a:hover {
    color: #ffd584;
    text-decoration: none;
}



/*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #ffc451;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
}

.back-to-top i {
    font-size: 28px;
    color: #151515;
    line-height: 0;
}

.back-to-top:hover {
    background: #151515;
}

.back-to-top:hover i {
    color: #ffc451;
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
}

/*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #151515;
}

#preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 0px);
    left: calc(50% - 30px);
    border: 6px solid #ffc451;
    border-top-color: #151515;
    border-bottom-color: #151515;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}

/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/

#header {
    transition: all 0.5s;
    z-index: 997;
    padding: 9px 0;
    background: rgba(254, 254, 243, 0.8);
}

#header.header-scrolled,
#header.header-inner-pages {
    background: rgba(254, 254, 243, 0.8);
}

#header .logo {
    font-size: 32px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
}

#header .logo a {
    color: #fff;
}

#header .logo a span {
    color: #ffc451;
}

#header .logo img {
    max-height: 40px;
}

/*--------------------------------------------------------------
  #  Get Startet Button
  --------------------------------------------------------------*/
.get-started-btn {
    color: #fff;
    border-radius: 4px;
    padding: 7px 25px 8px 25px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    display: inline-block;
    border: 2px solid #ffc451;
}

.get-started-btn:hover {
    background: #ffbb38;
    color: #343a40;
}

@media (max-width: 992px) {
    .get-started-btn {
        padding: 7px 20px 8px 20px;
        margin-right: 15px;
    }
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation 
  */
.navbar {
    padding: 0;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    /* align-items: center; */

}

.navbar li {
    /* position: relative; */
}

.navbar a,
.navbar a:focus {
    /* display: flex; */
    align-items: center;
    /* justify-content: space-between; */
    padding: 10px 0 10px 30px;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    white-space: nowrap;
    transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
    color: #ffc451;
}

.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
}

.navbar .dropdown ul li {
    min-width: 200px;
}

.navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 14px;
    text-transform: none;
    color: #151515;
    font-weight: 400;
}

.navbar .dropdown ul a i {
    font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
    background-color: #ffc451;
}

.navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}

@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }

    .navbar .dropdown .dropdown:hover>ul {
        left: -100%;
    }
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }

    .navbar ul {
        display: none;
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    transition: 0.3s;
    z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #151515;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
    color: #151515;
    background-color: #ffc451;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
    margin: 15px;
}

.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
    min-width: 200px;
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
    color: #151515;
}

.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
    background-color: #ffc451;
}

.navbar-mobile .dropdown>.dropdown-active {
    display: block;
}

/*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
#hero {
    width: 100%;
    min-height: 80vh;
    background: url("@/assets/images/intro-31.png") top center;
    background-size: auto 100%;
    position: relative;
}

#hero:before {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#hero .container {
    position: relative;
    padding-top: 74px;
    text-align: center;
}

#hero h1 {
    margin: 0;
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
    color: #fff;
}

#hero h1 span {
    color: #ffc451;
}

#hero h2 {
    color: rgba(255, 255, 255, 0.9);
    margin: 10px 0 0 0;
    font-size: 24px;
}

#hero .icon-box {
    padding: 30px 20px;
    transition: ease-in-out 0.3s;
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 100%;
    text-align: center;
}

#hero .icon-box i {
    font-size: 32px;
    line-height: 1;
    color: #ffc451;
}

#hero .icon-box h3 {
    font-weight: 700;
    margin: 10px 0 0 0;
    padding: 0;
    line-height: 1;
    font-size: 20px;
    line-height: 26px;
}

#hero .icon-box h3 a {
    color: #fff;
    transition: ease-in-out 0.3s;
}

#hero .icon-box h3 a:hover {
    color: #ffc451;
}

#hero .icon-box:hover {
    border-color: #ffc451;
}

@media (min-width: 1024px) {
    #hero {
        background-attachment: fixed;
    }
}

@media (max-width: 768px) {
    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 20px;
        line-height: 24px;
    }
}

/*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
}

.section-title {
    padding-bottom: 40px;
}

.section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
}

.section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #ffde9e;
    margin: 4px 10px;
}

.section-title p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Noto Sans KR', sans-serif;
    color: #151515;
}



/*--------------------------------------------------------------
  # Features
  --------------------------------------------------------------*/
.features {
    padding-top: 20px;
}

.features .icon-box {
    padding-left: 15px;
}

.features .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 5px 0 10px 60px;
}

.features .icon-box i {
    font-size: 48px;
    float: left;
    color: #ffc451;
}

.features .icon-box p {
    font-size: 15px;
    color: #848484;
    margin-left: 60px;
}

.features .image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
}

/*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
.services .icon-box {
    text-align: center;
    border: 1px solid #ebebeb;
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
    background: #fff;
}

.services .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: #ffc451;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: 0.3s;
}

.services .icon-box .icon i {
    color: #151515;
    font-size: 28px;
    transition: ease-in-out 0.3s;
}

.services .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
}

.services .icon-box h4 a {
    color: #151515;
    transition: ease-in-out 0.3s;
}

.services .icon-box h4 a:hover {
    color: #ffc451;
}

.services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.services .icon-box:hover {
    border-color: #fff;
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-10px);
}

/*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
.portfolio .portfolio-item {
    margin-bottom: 30px;
}

.portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto 20px auto;
    list-style: none;
    text-align: center;
}

.portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 15px 10px 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
    color: #151515;
    background: #ffc451;
}

.portfolio #portfolio-flters li:last-child {
    margin-right: 0;
}

.portfolio .portfolio-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: rgba(21, 21, 21, 0.6);
}

.portfolio .portfolio-wrap::before {
    content: "";
    background: rgba(21, 21, 21, 0.6);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
}

.portfolio .portfolio-wrap img {
    transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 20px;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-style: italic;
}

.portfolio .portfolio-wrap .portfolio-links {
    text-align: center;
    z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
    color: #fff;
    margin: 0 5px 0 0;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
    color: #ffc451;
}

.portfolio .portfolio-wrap:hover::before {
    opacity: 1;
}

.portfolio .portfolio-wrap:hover img {
    transform: scale(1.2);
}

.portfolio .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
}

/*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
.portfolio-details {
    padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
    width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #ffc451;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ffc451;
}

.portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(21, 21, 21, 0.08);
}

.portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
}

.portfolio-details .portfolio-description {
    padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
    padding: 0;
}

/*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
.counts .content {
    padding: 30px 0;
}

.counts .content h3 {
    font-weight: 700;
    font-size: 34px;
    color: #151515;
}

.counts .content p {
    margin-bottom: 0;
}

.counts .content .count-box {
    padding: 20px 0;
    width: 100%;
}

.counts .content .count-box i {
    display: block;
    font-size: 36px;
    color: #ffc451;
    float: left;
}

.counts .content .count-box span {
    font-size: 36px;
    line-height: 30px;
    display: block;
    font-weight: 700;
    color: #151515;
    margin-left: 50px;
}

.counts .content .count-box p {
    padding: 15px 0 0 0;
    margin: 0 0 0 50px;
    font-size: 14px;
    color: #3b3b3b;
}

.counts .content .count-box a {
    font-weight: 600;
    display: block;
    margin-top: 20px;
    color: #3b3b3b;
    font-size: 15px;
    transition: ease-in-out 0.3s;
}

.counts .content .count-box a:hover {
    color: #626262;
}

.counts .image {
    background: url("@/assets/images/about-1.png") center center no-repeat;
    background-size: cover;
    min-height: 2000px;
}

@media (max-width: 991px) {
    .counts .image {
        text-align: center;
    }

    .counts .image img {
        max-width: 80%;
    }
}

@media (max-width: 667px) {
    .counts .image img {
        max-width: 100%;
    }
}

/*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
.testimonials {
    padding: 80px 0;
    background: url("@/assets/images/intro-3.png") no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
}

.testimonials::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
}

.testimonials .section-header {
    margin-bottom: 40px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
    overflow: hidden;
}

.testimonials .testimonial-item {
    text-align: center;
    color: #fff;
}

.testimonials .testimonial-item .testimonial-img {
    width: 100px;
    border-radius: 50%;
    border: 6px solid rgba(255, 255, 255, 0.15);
    margin: 0 auto;
}

.testimonials .testimonial-item h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #fff;
}

.testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #ddd;
    margin: 0 0 15px 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
    color: rgba(255, 255, 255, 0.6);
    font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
}

.testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
    color: #eee;
}

.testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.4);
    opacity: 0.5;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ffc451;
    opacity: 1;
}

@media (min-width: 1024px) {
    .testimonials {
        background-attachment: fixed;
    }
}

@media (min-width: 992px) {
    .testimonials .testimonial-item p {
        width: 80%;
    }
}


/*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
.breadcrumbs {
    padding: 15px 0;
    background: whitesmoke;
    min-height: 40px;
    margin-top: 74px;
}

.breadcrumbs h2 {
    font-size: 28px;
    font-weight: 400;
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
}

.breadcrumbs ol li+li {
    padding-left: 10px;
}

.breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #2f2f2f;
    content: "/";
}

@media (max-width: 992px) {
    .breadcrumbs {
        margin-top: 68px;
    }

    .breadcrumbs .d-flex {
        display: block !important;
    }

    .breadcrumbs ol {
        display: block;
    }

    .breadcrumbs ol li {
        display: inline-block;
    }
}

#footer {
    background: black;
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
}

#footer .footer-top {
    background: #151515;
    border-bottom: 1px solid #222222;
    padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
    margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
    font-size: 28px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
}

#footer .footer-top .footer-info h3 span {
    color: #ffc451;
}

#footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: 'Noto Sans KR', sans-serif;
    color: #fff;
}

#footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #292929;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
    background: #ffc451;
    color: #151515;
    text-decoration: none;
}

#footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #ffc451;
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
    color: #ffc451;
}

#footer .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px;
}

#footer .footer-top .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
}

#footer .footer-top .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #ffc451;
    color: #151515;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
}

#footer .footer-top .footer-newsletter form input[type=submit]:hover {
    background: #ffcd6b;
}

#footer .copyright {
    text-align: center;
    padding-top: 30px;
}

#footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
}
</style>